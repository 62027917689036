import { TranslateService } from '@ngx-translate/core';
import { Component, ViewEncapsulation, Input, SimpleChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { EntitiesService } from '../../../../modules/entities/services/entities.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StatusCodeService } from '../../../services/status-code/status-code.service';
import { UserPermissionsService } from '../../../services/user-permissons/user-permissions.service';
import { auditTracking } from '../../../../utils/audit-tracking';

@Component({
    selector: 'app-payroll-basic-info',
    templateUrl: './payroll-basic-info.component.html',
    styleUrl: './payroll-basic-info.component.scss',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PayrollBasicInfoComponent {
  @Input() selectedEntity: any;
  frequencySettings: IDropdownSettings = {};
  entityExecRules: any[] = [];
  entityPayDayFrequencyList: any[] = [];
  entitiesPayrollBasicForm: FormGroup;
  entityPayrollinfo: any[] = [];
  entitySavePayrollinfo: any[] = [];
  entityPayrollFrequencyList: any[] = [];
  entityDaysFormonth: any[] = [];
  entityPayDay1Range: any[] = [];
  entityPayDay2Range: any[] = [];
  payrollFrequencynameChanged: any;
  @Input() disableEdit: boolean = false;
  changedMonth: any;
  paydayFrequency: any;
  payDay1name: any;
  payDay2name: any;
  entityPayrollBasicInfo: any;
  payrollData: any;
  updateData: any;
  entityMonth: any[] = [{ name: "Jan", month: "JANUARY" }, { name: "Feb", month: "FEBRUARY" }, { name: "Mar", month: "MARCH" }, { name: "Apr", month: "APRIL" }, { name: "May", month: "MAY" }, { name: "Jun", month: "JUNE" }, { name: "Jul", month: "JULY" }, { name: "Aug", month: "AUGUST" }, { name: "Sep", month: "SEPTEMBER" }, { name: "Oct", month: "OCTOBER" }, { name: "Nov", month: "NOVEMBER" }, { name: "Dec", month: "DECEMBER" },]
  entityPayrollFrequencyListfirst: any;
  entityPayDayFrequencyListfirst: any;
  event_type: any;

  savePayrollinfo!: [{ entityId: any; payrollFrequencyId: any; payDay1FrequencyId: any; payDay2FrequencyId: any; payDay1Value: any; payDay2Value: any; payDayExecutionRuleId: any; taxYearDate: any; taxYearMonth: any; }];
  @Input() switchingTab: string = '';
  @Output() formSaved = new EventEmitter();
  @Output() formValid = new EventEmitter();
  @Input() saveForm: boolean = false;

  constructor(private entitiesService: EntitiesService, private formBuilder: FormBuilder, private userPermissionsService: UserPermissionsService,
    private toastr: ToastrService, private translate: TranslateService, private statusCodeService: StatusCodeService, private cdr: ChangeDetectorRef) {
    this.entitiesPayrollBasicForm = this.formBuilder.group({
      entityId: [],
      payrollFrequencyId: [null, Validators.required],
      payDay1FrequencyId: [null, Validators.required],
      payDay2FrequencyId: [null],
      payDay1Value: [null, Validators.required],
      payDay2Value: [null],
      payDayExecutionRuleId: [null, Validators.required],
      taxYearDate: [null, Validators.required],
      taxYearMonth: [null, Validators.required]
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['disableEdit']?.firstChange && this.disableEdit) {
      this.entitiesPayrollBasicForm.reset();
      this.updatePayrollBasicInfoForm();
    }

    if ((!changes['switchingTab']?.firstChange && this.switchingTab) || this.saveForm) {
      this.postSavePayrollinfo();
    }
  }

  ngOnInit() {
    this.payDay1name = 'payDay1name';
    this.payDay2name = 'payDay2name';
    this.payrollData = '';
    this.getPayrollinfo();
    this.checkFormChanges();
  }

  getActions(action: string) { return this.userPermissionsService.isActions('Entities', action); }

  getPayrollinfo() {
    this.entitiesService.payrollinfo(this.selectedEntity?.entityId).subscribe({
      next: (res: any) => {
        this.entityPayrollBasicInfo = res.data;
        // audit tracking
        const desc = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.viewPayroll');
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('auditTrail.auditTypeView'),
          desc)
        if (res.data == null && res.status == 'OK') {
          this.getFetchExecRules();
          this.getPayDayFrequencyList();
          this.payrollFrequencyList();
          this.daysFormonth("JANUARY", 'onLoad');
        }
        else
          if (this.entityPayrollBasicInfo) {
            this.updatePayrollBasicInfoForm();
          }
      }, error: error => {
      }
    })
  }

  getFetchExecRules() {
    this.entitiesService.fetchExecRules().subscribe({
      next: (res: any) => { this.entityExecRules = res.data ? res.data : []; },
      error: error => { }
    })
  }

  getPayDayFrequencyList() {
    this.entitiesService.payDayFrequencyList().subscribe({
      next: (res: any) => {
        this.entityPayDayFrequencyList = res.data ? res.data : [];
        this.entityPayDayFrequencyListfirst = this.entityPayDayFrequencyList[0]?.paydayFrequencyId;
      },
      error: error => { }
    })
  }

  payrollFrequencyList() {
    this.entitiesService.payrollFrequencyList().subscribe({
      next: (res: any) => {
        this.entityPayrollFrequencyList = res.data ? res.data : [];
        this.entityPayrollFrequencyListfirst = this.entityPayrollFrequencyList[0]?.payrollFrequencyId;
        if (this.entitiesPayrollBasicForm?.value.payrollFrequencyId === this.entityPayrollFrequencyList[3]?.payrollFrequencyId) {
          this.payrollFrequencynameChanged = 'Semi-Monthly';
        }
      },
      error: error => { }
    })
  }

  daysFormonth(changedMonth: any, status: String) {
    this.entitiesService.daysFormonth(changedMonth).subscribe({
      next: (res: any) => {
        this.entityDaysFormonth = res.data ? res.data : [];
        if (status === 'onChange')
          this.entitiesPayrollBasicForm.patchValue({ taxYearDate: null });
      },
      error: error => { }
    })
  }

  updatePayrollBasicInfoForm() {
    this.entitiesPayrollBasicForm.patchValue({
      entityId: this.selectedEntity.entityId,
      payrollFrequencyId: this.entityPayrollBasicInfo?.payrollFrequencyId,
      payDay1FrequencyId: this.entityPayrollBasicInfo?.payDay1FrequencyId,
      payDay2FrequencyId: this.entityPayrollBasicInfo?.payDay2FrequencyId,
      payDay1Value: this.entityPayrollBasicInfo?.payDay1Value,
      payDay2Value: this.entityPayrollBasicInfo?.payDay2Value,
      payDayExecutionRuleId: this.entityPayrollBasicInfo?.payDayExecutionRuleId,
      taxYearDate: this.entityPayrollBasicInfo?.taxYearDate,
      taxYearMonth: this.entityPayrollBasicInfo?.taxYearMonth
    });
    this.payrollData = this.entitiesPayrollBasicForm.value;
    this.getFetchExecRules();
    this.getPayDayFrequencyList();
    this.payrollFrequencyList();
    if (this.entityPayrollBasicInfo?.payrollFrequencyId === 'Semi-Monthly')
      this.payrollFrequencynameChanged = 'Semi-Monthly';
    else this.payrollFrequencynameChanged = '';
    if (this.entityPayrollBasicInfo?.payrollFrequencyId != null || this.entityPayrollBasicInfo?.payDay1FrequencyId != null)
      this.payDayRange(this.entityPayrollBasicInfo?.payrollFrequencyId, this.entityPayrollBasicInfo?.payDay1FrequencyId, this.entityPayrollBasicInfo?.payDay1Value, "payDay1name", 'OnLoad');
    if ((this.entityPayrollBasicInfo?.payDay2FrequencyId) && (this.entityPayrollBasicInfo?.payrollFrequencyId != null || this.entityPayrollBasicInfo?.payDay2FrequencyId != null))
      this.payDayRange(this.entityPayrollBasicInfo?.payrollFrequencyId, this.entityPayrollBasicInfo?.payDay2FrequencyId, this.entityPayrollBasicInfo?.payDay2Value, "payDay2name", 'OnLoad');
    if (this.entityPayrollBasicInfo?.taxYearMonth != null)
      this.daysFormonth(this.entityPayrollBasicInfo?.taxYearMonth, 'onLoad');
  }

  payrollFrequencyIdChanged($event: any) {
    this.entitiesPayrollBasicForm.patchValue({ payDay1FrequencyId: null });
    this.entitiesPayrollBasicForm.patchValue({ payDay1Value: null });
    if (this.entityPayrollBasicInfo?.payDay2FrequencyId) {
      this.entitiesPayrollBasicForm.patchValue({ payDay2FrequencyId: null });
      this.entitiesPayrollBasicForm.patchValue({ payDay2Value: null });
    }
    this.payrollFrequencynameChanged = $event.name;
    if (this.payrollFrequencynameChanged === 'Semi-Monthly') {
      if (this.entitiesPayrollBasicForm.get('payDay2FrequencyId')) {
        this.entitiesPayrollBasicForm.get('payDay2FrequencyId')?.setValidators([Validators.required]);
        this.entitiesPayrollBasicForm.get('payDay2Value')?.setValidators([Validators.required]);
        this.cdr.detectChanges();
      }
    } else {
      this.entitiesPayrollBasicForm.get('payDay2FrequencyId')?.setValidators([]);
      this.entitiesPayrollBasicForm.get('payDay2Value')?.setValidators([]);
      this.entitiesPayrollBasicForm.get('payDay2FrequencyId')?.updateValueAndValidity();
      this.entitiesPayrollBasicForm.get('payDay2Value')?.updateValueAndValidity();
    }
  }

  submitPayrollBasicInfoForm() {
    if (this.disableEdit) {
      this.disableEdit = false;
    } else {
      this.postSavePayrollinfo();
    }
  }

  postSavePayrollinfo() {
    if (this.entitiesPayrollBasicForm.valid && this.entitiesPayrollBasicForm.dirty) {
      const savePayrollinfo = {
        entityId: this.selectedEntity?.entityId,
        payrollFrequencyId: this.entitiesPayrollBasicForm.value.payrollFrequencyId,
        payDay1FrequencyId: this.entitiesPayrollBasicForm.value.payDay1FrequencyId,
        payDay1Value: this.entitiesPayrollBasicForm.value.payDay1Value,
        payDay2FrequencyId: this.entitiesPayrollBasicForm.value.payDay2FrequencyId,
        payDay2Value: this.entitiesPayrollBasicForm.value.payDay2Value,
        payDayExecutionRuleId: this.entitiesPayrollBasicForm.value.payDayExecutionRuleId,
        taxYearDate: this.entitiesPayrollBasicForm.value.taxYearDate,
        taxYearMonth: this.entitiesPayrollBasicForm.value.taxYearMonth
      };
      this.updateData = savePayrollinfo;
      // this.payrollFrequencyIdChanged(Event);
      this.savePayrollBaiscInfo(savePayrollinfo);
    } else {
      this.entitiesPayrollBasicForm.markAllAsTouched();
      this.formSaved.emit(false);
    }
  }

  savePayrollBaiscInfo(savePayrollBasicinfo: any) {
    this.entitiesService.savePayrollinfo(savePayrollBasicinfo).subscribe({
      next: (res: any) => {
        this.entitySavePayrollinfo = res.data ? res.data : [];
        // audit tracking
        const desc = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.editedPayroll');
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('common.edit'),
          desc)
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.statusCodeService.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else {
          this.formSaved.emit(true);
          this.statusCodeService.displayToastrMessage(res?.statusCode, 'success', 'common');
        }
      },
      error: error => { this.formSaved.emit(false); }
    });
  }

  payDayRange(payrollFrequency: any, paydayFrequency: any, payDayValue: any, payDayname: String, status: String) {
    let body;
    body = { payrollFrequency: payrollFrequency, paydayFrequency: paydayFrequency };
    if (payrollFrequency != null) {
      this.entitiesService.payDayRange(body).subscribe({
        next: (res: any) => {
          if (payDayname === "payDay1name") {
            if (status === 'onChange')
              this.entitiesPayrollBasicForm.patchValue({ payDay1Value: null });
            this.entityPayDay1Range = res.data ? res.data : [];
          } else {
            if (this.entityPayDay2Range) {
              if (status === 'onChange')
                this.entitiesPayrollBasicForm.patchValue({ payDay2Value: null });
              this.entityPayDay2Range = res.data ? res.data : [];
            }
          }
        },
        error: error => {
          if (payDayname === "payDay1name") {
            this.entityPayDay1Range = [];
          } else {
            if (this.entityPayDay2Range) { this.entityPayDay2Range = []; }
          }
        }
      })
    }
    else {
      this.toastr.error(this.translate.instant('common.error.frequencyRequired'));
    }
  }

  cancelPayrollBasicInfoDetail() {
    if (this.selectedEntity) {
      if (this.disableEdit)
        this.disableEdit = false;
      else {
        this.disableEdit = true;
        this.updatePayrollBasicInfoForm();
      }
    }
  }

  checkFormChanges() {
    this.entitiesPayrollBasicForm.valueChanges.subscribe(value => {
      this.formValid.emit(this.entitiesPayrollBasicForm.valid && this.entitiesPayrollBasicForm.dirty);
    });
  }
}