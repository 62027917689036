<div class="modal pic-upload" [ngClass]="{'inside-modal' : entityLogo}">
  <div class="modal-dialog">
    <div class="modal-content pt-3">

      <div class="modal-header mb-3">
        <div class="d-flex align-items-center">
          <div class="modal-title" *ngIf="default" translate>userPreference.uploadProfilePic</div>
          <ng-container *ngIf="!default">
            <div class="modal-title" *ngIf="entityLogo" translate>entities.general.uploadEntityLogo</div>
            <div class="modal-title" *ngIf="!entityLogo" translate>clients.uploadClientLogo</div>
          </ng-container>

          <label class="replace-image btn-link" *ngIf="imageChangedEvent || userPic">
            <input type="file" (change)="fileChangeEvent($event)" accept="image/*" />
            <img style="height: 12px" src="./assets/images/refresh.png" alt="refresh" />
            <span translate>common.replace</span>
          </label>
          <!-- delete -->
          <div class="replace-image btn-link" *ngIf="userPic"  (click)="emitImage.emit({isDeleted:true})">
            <span style="font-size:12px;" class="icon-bin"></span>
            <span translate>common.delete</span>
          </div>
        </div>
        <i class="icon-close cp" style="font-size: 12px;" (click)="emitImage.emit()"></i>
      </div>

      <div class="modal-body">
        <div class="drop-zone h100 flex-center">

          <label translate *ngIf="!userPic">
            <input type="file" (change)="fileChangeEvent($event)" accept="image/*" id="fileTag" title="" />
            <ng-container *ngIf="!imageChangedEvent">
              <img height="12px" style="margin-top: -2px;" src="./assets/images/camera.png" alt="camera" />
              &nbsp; <div *ngIf="default" translate>userPreference.uploadPhoto</div>
              <ng-container *ngIf="!default">
                <div *ngIf="entityLogo" translate>entities.general.uploadEntityLogo</div>
                <div *ngIf="!entityLogo" translate>clients.uploadClientLogo</div>
              </ng-container>
            </ng-container>
          </label>

          <div *ngIf="userPic" class="saved-profile-pic">
            <img [src]="userPic" alt="userPic"/>
          </div>

          <image-cropper *ngIf="imageChangedEvent" [imageChangedEvent]="imageChangedEvent" format="jpeg"
            [maintainAspectRatio]="false" (imageCropped)="imageCropped($event)"></image-cropper>
        </div>
      </div>

      <div class="modal-footer position-relative">
        <p class="note" translate>common.fileFormatInfo</p>
        <button *ngIf="imageChangedEvent" class="btn-link border-0 mx-auto" (click)="croppedImage && crop()" translate>
          <img style="height: 12px;margin-top: -3px;" src="./assets/images/cut.png" alt="crop" />common.crop</button>
      </div>
    </div>
  </div>
</div>

<app-hex-loader *ngIf="hexLoader"></app-hex-loader>
