<div class="modal">
    <div class="modal-dialog mt-0">
        <div class="modal-content sm pt-3">
            <div class="modal-header mb-2">
                <div class="modal-title">{{ title }}</div>
                <i class="icon-close cp" style="font-size: 12px" (click)="emitEvent.emit(false)"></i>
            </div>
            <div class="modal-body">
                <p *ngFor="let item of list">
                    {{ item.l2ServiceName }}
                </p>
            </div>
        </div>
    </div>
</div>