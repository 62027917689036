import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrl: './confirmation-popup.component.scss'
})
export class ConfirmationPopupComponent {
  @Output() emitEvent = new EventEmitter();
  @Input() description: string = '';
  @Input() innerModal: boolean = false;
  @Input() confButton: string = '';
  @Input() cancelButton: string = '';
  @Input() inline: boolean = false;
}
