<div class="pay-elements-table pay-elements h100">
    <div class="d-flex justify-content-between">
        <div class="header">
            <div class="title me-2" translate>entities.payElements
                <div class="position-relative d-inline-block">
                    <button [ngClass]="{'disabled': disableEdit}"
                        *ngIf="getActions('Manage Pay Elements') || ((selectedEntityClient || clone || cloneSaved) && getActions('Create Entity'))"
                        class="btn-link" (click)="createPayElement = true;enabledAddPayElement.emit(true)" translate>
                        <i class="icon-create"></i>entities.createNewPayElement</button>

                    <!-- create new pay element -->
                    <div class="popup-wrapper" *ngIf="createPayElement">
                        <app-create-pay-element [selectedEntity]="selectedEntity"
                            (closeCreatePayElement)="createPayElement = null;enabledAddPayElement.emit(false)"
                            (refreshPayElements)="resetPayElements(); createPayElement = null;enabledAddPayElement.emit(false)"></app-create-pay-element>
                    </div>
                </div>
            </div>
            <div class="info fnt-reg"><span translate>common.total</span> - {{totalPayElements}}</div>
        </div>

        <app-alphabet-filter-header *ngIf="body.searchTerm" [pageHeader]="'entities.payElements' | translate"
            [searchText]="body.searchTerm"> </app-alphabet-filter-header>

        <div class="card-actions">
            <app-wild-card-search [searchKeyword]="body.searchTerm" (emitSearchText)="receivedSearch($event)"
                [isLoader]="isLoader"> </app-wild-card-search>
            <app-export *ngIf="getActionsTasks('View Pay Elements')" [totalItems]="totalPayElements"
                (exportEvent)="exportPayElements()"></app-export>
        </div>
    </div>

    <div class="table-wrapper mt-2 h100">
        <table aria-describedby="pay-elements" [ngClass]="{'loading':isLoader}">
            <thead>
                <tr>
                    <th style="width:15px"
                        *ngIf="getActions('Manage Pay Elements') || ((selectedEntityClient || clone || cloneSaved) && getActions('Create Entity'))">
                        <app-checkbox *ngIf="payElements.length > 0" [table]="true" [checked]="isSelectAll"
                            (onClick)="selectAll($event)" [disabled]="disableEdit"></app-checkbox>
                    </th>
                    <th [ngClass]="{'cp' : (!disableEdit || !getActions('Manage Pay Elements'))}"
                        (click)="(!disableEdit || !getActions('Manage Pay Elements')) && sortPayElements('payElementCode')"
                        style="width:142px">
                        <span class="textoverflow" [title]="'entities.payElementCode' | translate"
                            translate>entities.payElementCode</span>
                        <app-sort [field]="'payElementCode'" [sortField]="body.sortBy"
                            [sortOrder]="body.sortOrder"></app-sort>
                        <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
                    </th>

                    <th [ngClass]="{'cp' : (!disableEdit || !getActions('Manage Pay Elements'))}"
                        (click)="(!disableEdit || !getActions('Manage Pay Elements')) && sortPayElements('localName')">
                        <span class="textoverflow" [title]="'entities.localLanguageName' | translate"
                            translate>entities.localLanguageName</span>
                        <app-sort [field]="'localName'" [sortField]="body.sortBy"
                            [sortOrder]="body.sortOrder"></app-sort>
                        <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
                    </th>

                    <th style="width: 13.7vw;" [ngClass]="{'cp' : (!disableEdit || !getActions('Manage Pay Elements'))}"
                        (click)="(!disableEdit || !getActions('Manage Pay Elements')) && sortPayElements('englishName')">
                        <span class="textoverflow" [title]="'entities.englishLanguageName' | translate"
                            translate>entities.englishLanguageName</span>
                        <app-sort [field]="'englishName'" [sortField]="body.sortBy"
                            [sortOrder]="body.sortOrder"></app-sort>
                        <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
                    </th>

                    <th [ngClass]="{'cp' : (!disableEdit || !getActions('Manage Pay Elements'))}"
                        (click)="(!disableEdit || !getActions('Manage Pay Elements')) && sortPayElements('wageGroupName')"
                        style="width:125px">
                        <span class="textoverflow" [title]="'entities.wageGroup' | translate"
                            translate>entities.wageGroup</span>
                        <app-sort [field]="'wageGroupName'" [sortField]="body.sortBy"
                            [sortOrder]="body.sortOrder"></app-sort>
                        <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
                    </th>

                    <th [ngClass]="{'cp' : (!disableEdit || !getActions('Manage Pay Elements'))}"
                        (click)="(!disableEdit || !getActions('Manage Pay Elements')) && sortPayElements('payElementType')"
                        style="width:125px">
                        <span class="textoverflow" [title]="'entities.elementType' | translate"
                            translate>entities.elementType</span>
                        <app-sort [field]="'payElementType'" [sortField]="body.sortBy"
                            [sortOrder]="body.sortOrder"></app-sort>
                        <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
                    </th>

                    <th class="text-center" style="width:138px">
                        <span class="textoverflow" [title]="'entities.importEnabled' | translate"
                            translate>entities.importEnabled</span>
                        <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
                    </th>
                    <th class="options ps-0" style="width: 60px;">&nbsp;
                        <ngx-skeleton-loader [count]="5" *ngIf="isLoader"></ngx-skeleton-loader>
                    </th>
                </tr>
            </thead>

            <tbody *ngIf="!isLoader" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                (scrolled)="onScroll()" [scrollWindow]="false">
                @if(payElements.length > 0 )
                {
                <tr *ngFor="let payElement of payElements ; let i  = index"
                    [ngClass]="{'edit-row': editableRowIndex ===i ,'disabled no-opacity' : disableEdit}">
                    <ng-container>

                        <td style="width:15px"
                            *ngIf="getActions('Manage Pay Elements') || ((selectedEntityClient || clone || cloneSaved) && getActions('Create Entity'))">
                            <app-checkbox [table]="true" [disabled]="disableEdit" *ngIf="editableRowIndex !== i"
                                [checked]="findpayElementsDataId(payElement.entityPayElementId) > -1"
                                (onClick)="selectItemToDelete($event, payElement.entityPayElementId)"></app-checkbox>
                        </td>

                        <td style="width:142px;" [title]="payElement.payElementCode"
                            [ngClass]="{'disabled': !payElement.clientImportEnabled && editableRowIndex !==i}">

                            <div class="input-container" *ngIf="editableRowIndex ===i;else payElementValue"> <input
                                    name="payElementCode" data-trigger="hover"
                                    [ngbTooltip]="payElementCodeField('payElementCode',i) ? ngbToolTipMessage: ngbToolTipPayelementcode"
                                    [ngClass]="{'border-error': payElementCodeField('payElementCode',i) || payElementCodeDuplicate}"
                                    required maxlength="256" [(ngModel)]="payElement.payElementCode"
                                    (input)="onInputValueChanged('payElementCode',$event);" />
                            </div>
                            <ng-template #payElementValue>
                                <div class="textoverflow">{{payElement.payElementCode}} </div>
                            </ng-template>
                        </td>

                        <td [title]="payElement.localName"
                            [ngClass]="{'disabled': !payElement.clientImportEnabled && editableRowIndex !==i}">

                            <div class="input-container" *ngIf="editableRowIndex ===i;else localNameValue"><input
                                    name="localName" data-trigger="hover"
                                    [ngbTooltip]="localNameField('localName',i) ? ngbToolTipMessage: ngbToolTipLocalName"
                                    [ngClass]="{'border-error': localNameField('localName',i) || localNameDuplicate}"
                                    required maxlength="256" [(ngModel)]="payElement.localName"
                                    (input)="onInputValueChanged('localName',$event);" />
                            </div>
                            <ng-template #localNameValue>
                                <div class="textoverflow">{{payElement.localName}}</div>
                            </ng-template>
                        </td>

                        <td style="width: 13.7vw;" [title]="payElement.englishName"
                            [ngClass]="{'disabled': !payElement.clientImportEnabled && editableRowIndex !==i}">

                            <div class="input-container" *ngIf="editableRowIndex ===i;else englishNameValue"><input
                                    name="englishName" data-trigger="hover"
                                    [ngbTooltip]="englishNameField('englishName',i) ? ngbToolTipMessage: ngbToolTipEnglishName"
                                    [ngClass]="{'border-error': englishNameField('englishName',i) || englishNameDuplicate}"
                                    required maxlength="256" [(ngModel)]="payElement.englishName"
                                    (input)="onInputValueChanged('englishName',$event);" />
                            </div>
                            <ng-template #englishNameValue>
                                <div class="textoverflow">{{payElement.englishName}}</div>
                            </ng-template>
                        </td>

                        <td style="width:125px" [title]="payElement.wageGroupName"
                            [ngClass]="{'disabled': !payElement.clientImportEnabled && editableRowIndex !==i}">

                            <ng-select class="select" *ngIf="editableRowIndex ===i;else wageGroupNameValue"
                                [clearable]="false" [(ngModel)]="payElement.wageGroupName" [items]="wageGroupItems"
                                (change)="isNgSelectChanged()" bindLabel="wageGroupName"
                                bindValue="wageGroupName"></ng-select>
                            <ng-template #wageGroupNameValue>
                                <div>{{payElement.wageGroupName}}</div>
                            </ng-template>
                        </td>

                        <td style="width:125px" [title]="payElement.payElementType"
                            [ngClass]="{'disabled': !payElement.clientImportEnabled && editableRowIndex !==i}">

                            <ng-select class="select" *ngIf="editableRowIndex ===i;else payElementTypeValue"
                                [clearable]="false" [(ngModel)]="payElement.payElementType" [items]="elementTypeItems"
                                (change)="isNgSelectChanged()" bindLabel="elementTypeCode"
                                bindValue="elementTypeCode"></ng-select>
                            <ng-template #payElementTypeValue>
                                <div>{{payElement.payElementType}}</div>
                            </ng-template>
                        </td>

                        <td class="position-relative text-center" style="width:138px">
                            <div class="td-toggle" *ngIf="editableRowIndex ===i">
                                <app-toggle [checked]="payElement.clientImportEnabled"
                                    (emitToggle)="statusToggleForImport($event,i)"></app-toggle>
                            </div>
                            <ng-container *ngIf="editableRowIndex !== i">
                                <i *ngIf="payElement.clientImportEnabled" style="font-size: 8px;" class="icon-tick"></i>
                                <i *ngIf="!payElement.clientImportEnabled" class="icon-close close-red"></i>
                            </ng-container>
                        </td>

                        <td class="options ps-0" style="width: 60px;" *ngIf="editableRowIndex !== i;">
                            <div class="dropdown"
                                *ngIf="getActions('Manage Pay Elements') || ((selectedEntityClient || clone || cloneSaved) && getActions('Create Entity'))">
                                <span class="dropdown-toggle" data-bs-toggle="dropdown"><i class="icon-options"></i>
                                </span>
                                <div class="dropdown-menu options-menu" style="width: 112px;">
                                    <a (click)="editRow(i)" class="dropdown-item cp" translate>
                                        <i style="font-size: 9px;" class="icon-pencil"></i>common.edit</a>
                                    <a (click)="inlineDelete(payElement)" class="dropdown-item cp" translate> <i
                                            class="icon-bin"></i>common.delete</a>
                                </div>
                            </div>
                        </td>

                        <td style="width: 60px" class="ps-0" *ngIf="editableRowIndex === i;">
                            <div class="d-flex align-items-center">
                                <img style="height: 13px;" class="cp" [ngClass]="{'disabled':!isRowChanged }"
                                    src="./assets/images/tick-active.png" (click)="saveUpdatedPayElement(i)"
                                    alt="tick" />
                                <i style="padding-left: 10px;" class="icon-circle-close cp" (click)="cancelEdit()"></i>
                            </div>
                        </td>

                    </ng-container>
                </tr>
                } @else if(payElements.length === 0) {
                <tr class="h100">
                    <td colspan="3" class="border-0">
                        <div class="no-data" translate>common.nodata</div>
                    </td>
                </tr>
                }
            </tbody>
        </table>
    </div>
</div>

<!-- confirmation-popup-delete -->
<app-confirmation-popup *ngIf="isConfirmPopupForDelete || isConfirmPopupForEdit" [innerModal]="true"
    (emitEvent)="handleConfirmation($event)"
    [description]="isConfirmPopupForDelete ? 'common.confirmDelete' : 'common.cancelMsg' "></app-confirmation-popup>

<div class="restrict-overlay" style="z-index: 2;left: -10px;top: -45px;"
    *ngIf="createPayElement || editableRowIndex > -1 "></div>

<div class="d-flex align-items-center pay-elements-footer"
    style="position: absolute;bottom: 8px;right: 0; left: 242px;">
    <button class="btn-link" [ngClass]="{'disabled': disableEdit}" (click)="importFiles=true" translate
        *ngIf="getActions('Manage Pay Elements')">
        <img style="height: 10px;margin-bottom: 3px;margin-right: 5px;" src="./assets/images/download.png"
            alt="disable" />entities.importPayElements
    </button>
    <button class="btn-link" [ngClass]="{'disabled': disableEdit}" *ngIf="getActions('Manage Pay Elements')"
        (click)="sampleFileDownload()" translate>entities.sampleFileDownload
    </button>
    <button class="btn-link" *ngIf="selectedIds.length" translate (click)="isConfirmPopupForDelete = true;">
        <i class="icon-bin"></i>
        <span>{{ selectedIds.length > 1 ? ('common.deleteAll' | translate) : ('common.delete' | translate) }}
        </span>
    </button>

    <div class="ms-auto d-flex">
        <div class="multi-select-wrapper">
            <span class="select-name textoverflow" style="width: 64%;" translate>entities.allWageGroups</span>
            <ng-multiselect-dropdown focusOnClick class="position-top" [settings]="wageGroupSettings"
                [ngbTooltip]="'entities.allWageGroups' | translate" [data]="wageGroupItems" [placeholder]="' '"
                [(ngModel)]="selectedWageGroup"></ng-multiselect-dropdown>
        </div>
        <div class="multi-select-wrapper">
            <span class="select-name textoverflow" style="width: 64%;" translate>entities.payElements</span>
            <ng-multiselect-dropdown focusOnClick class="position-top" [settings]="elementTypeSettings"
                [ngbTooltip]="'entities.payElements' | translate" [data]="elementTypeItems" [placeholder]="' '"
                [(ngModel)]="selectedPayElement"></ng-multiselect-dropdown>
        </div>

        <button class="btn-outline btn-reset" (click)="resetDropdownFilter()" [title]="'common.resetFilter'| translate"
            translate><img src="./assets/images/reset.png" alt="reset" />common.reset
        </button>
    </div>
</div>

<div class="file-upload modal" *ngIf="importFiles">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header mb-2">
                <div class="modal-title mb-0" translate>common.upload</div>
                <i class="icon-close cp" (click)="importFiles=false"></i>
            </div>
            <app-file-upload (closeFileUpload)="importFiles=false" [component]="'pay elements'"
                (emitFile)="receivedFiles($event)" [allowedFormats]="'xlsx'" [maxFileSizeMB]="10"
                [emptyFileCheck]="true"></app-file-upload>
        </div>
    </div>
</div>

<app-hex-loader *ngIf="hexLoader"></app-hex-loader>