import { StatusCodeService } from './../../../services/status-code/status-code.service';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { EntitiesService } from '../../../../modules/entities/services/entities.service';
import { UserPermissionsService } from '../../../services/user-permissons/user-permissions.service';
import { auditTracking } from '../../../../utils/audit-tracking';

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrl: './approvals.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ApprovalsComponent implements OnInit {

  selectedIndex: number = 0;
  showL2Approvals: boolean = false;
  levelIndex: number = -1;
  serviceLineApprovals: any[] = [];
  entityServiceLineList: any[] = [];
  rolesList: any[] = [];
  uniqueL2Approvals: any = [];
  hexLoader: boolean = false;
  L1RoleOne: any[] = [];
  L1RoleTwo: any[] = [];
  L2RoleOne: any[] = [];
  L2RoleTwo: any[] = [];
  L2RoleThree: any[] = [];
  selectedServiceLine: any;
  entityL2Services: any;
  approvalCount: number = 0;
  isSaveApprovals: boolean = false;
  entityServiceLineApprovals: any[] = [];
  saveApprovalBody: any = {};
  approvalLevelList: any[] = [];
  approvalLevelId: string = '';
  value: boolean = false;

  @Input() selectedEntity: any;
  @Input() disableEdit: boolean = false;
  @Input() switchingTab: string = '';
  @Output() valueChange = new EventEmitter();
  @Input() saveForm: boolean = false;
  @Output() formSaved = new EventEmitter();

  settings: IDropdownSettings = {
    singleSelection: true, idField: 'roleId', textField: 'roleName', maxHeight: 100,
    noDataAvailablePlaceholderText: this.translate.instant('common.nodata'), noFilteredDataAvailablePlaceholderText: this.translate.instant('common.nodata'), closeDropDownOnSelection: true, allowSearchFilter: true, searchPlaceholderText: this.translate.instant('common.search')
  };

  constructor(private translate: TranslateService, private entitiesService: EntitiesService, private statusCodeService: StatusCodeService, private userPermissionsService: UserPermissionsService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['disableEdit']?.firstChange && this.disableEdit) {
      this.resetApprovals();
    }

    if ((!changes['switchingTab']?.firstChange && this.switchingTab) || this.saveForm) {
      this.saveApprovals();
    }
  }

  ngOnInit(): void {
    this.getEntityServiceLines();
    this.getApprovalRoles();
    this.getApprovalLevelList();
    this.saveApprovalBody = {
      entityId: this.selectedEntity?.entityId,
      serviceLineApprovals: [{
        approvalLevels: [],
        l2ServiceApprovals: []
      }]
    };
  }

  resetApprovals() {
    this.showL2Approvals = false;
    this.L1RoleOne = [];
    this.L1RoleTwo = [];
    this.valueChange.emit(false);
    this.entityServiceLineList = [];
    this.getEntityServiceLines();
  }

  getActions(action: string) { return this.userPermissionsService.isActions('Entities', action); }

  getApprovalRoles() {
    this.entitiesService.getApprovalRoles().subscribe({
      next: res => {
        this.rolesList = res?.data ? res?.data : [];
      }
    })
  }

  getEntityServiceLines() {
    this.hexLoader = true;
    this.entitiesService.getEntityServiceLines(this.selectedEntity?.entityId).subscribe({
      next: res => {
        let allEntityServiceLines = res?.data ? res.data : [];
        allEntityServiceLines.forEach((serviceLine: any) => {
          if (serviceLine?.isServiceEnabled === 1 && serviceLine?.serviceLineId !== this.entityServiceLineList[0]?.serviceLineId) {
            this.entityServiceLineList.push(serviceLine);
          }
        });
        this.selectedServiceLine = this.entityServiceLineList[0];
        // audit track
        const desc = '[' + this.selectedEntity?.entityName + '] ' + this.translate.instant('auditTrail.entities.viewApprovalDetails');
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('auditTrail.auditTypeView'),
          desc)

        this.entityServiceLineList.forEach((item, index) => {
          this.L2RoleOne[index] = [];
          this.L2RoleTwo[index] = [];
          this.L2RoleThree[index] = [];
          this.serviceLineApprovals[index] = {
            entityServiceLineId: null,
            approvalLevels: [],
            l2ServiceApprovals: []
          };
          setTimeout(() => {
            this.getServiceLineApprovals(item.serviceLineId, index);
          }, 100);
        });
        this.hexLoader = false;
      }, error: error => {
        this.hexLoader = false;
      }
    })
  }

  getServiceLineApprovals(serviceLineId: string, index: number) {
    let body = {
      "entityId": this.selectedEntity?.entityId,
      "serviceLineId": serviceLineId
    }
    this.entitiesService.getServiceLineApprovals(body).subscribe({
      next: res => {
        if (res.data != null) {
          this.entityServiceLineApprovals.push(res.data);
          if (this.selectedIndex === index) {
            res.data.l2ServicesApprovalCount ? this.approvalCount = res.data.l2ServicesApprovalCount : 0;
          }
          this.setServiceLineRoles(res.data?.approvalLevels, index);
          this.mapServiceLineApprovals(res, index);
        }
      }
    })
  }

  setServiceLineRoles(approvalLevels: any, index: number) {
    approvalLevels.forEach((item: any) => {
      if (item.approvalLevelDesc === 'Level 1') {
        this.L1RoleOne[index] = [{ roleName: item.roleName, roleId: item.roleId }];
      }
      else if (item.approvalLevelDesc === 'Level 2') {
        this.L1RoleTwo[index] = [{ roleName: item.roleName, roleId: item.roleId }];
      }
    });
  }

  mapServiceLineApprovals(serviceLine: any, index: number) {
    if (index === 0 && this.selectedServiceLine?.entityServiceLineId) {
      this.serviceLineApprovals[index].entityServiceLineId = this.selectedServiceLine.entityServiceLineId;
    }

    serviceLine?.data?.approvalLevels.forEach((el: any, i: number) => {
      if (this.serviceLineApprovals[index]?.approvalLevels) {
        this.serviceLineApprovals[index].approvalLevels[i] = { approvalLevelId: el.approvalLevelId, roleId: el.roleId }
      }
    });
  }

  showL2ApprovalsList(item: any) {
    this.showL2Approvals = true;
    this.selectedServiceLine = item;
    this.entityServiceLineList = [this.selectedServiceLine];
    this.checkEntityL2ServiceCall();
    this.getServiceLineApprovals(this.selectedServiceLine?.serviceLineId, this.selectedIndex);
    this.value = true;
  }

  checkEntityL2ServiceCall() {
    this.uniqueL2Approvals = [];
    this.entityL2Services = [];
    if (this.approvalCount > 0) {
      this.getUniqueL2Approvals(this.selectedServiceLine?.serviceLineId, this.selectedIndex);
    }
    else {
      this.getL2Services();
    }
  }

  getL2Services() {
    this.hexLoader = true;
    let body = {
      "entityServiceLineId": this.selectedServiceLine?.entityServiceLineId,
      "entityId": this.selectedEntity.entityId,
      "serviceLineId": this.selectedServiceLine?.serviceLineId,
      "isServiceEnabled": this.selectedServiceLine?.isServiceEnabled
    }
    this.entitiesService.getL2Services(body).subscribe({
      next: res => {
        this.hexLoader = false;
        this.entityL2Services = res.data ? res.data : [];
        this.mapEntityL2Services();
      }
    })
  }

  mapEntityL2Services() {
    let array = this.serviceLineApprovals[this.selectedIndex].l2ServiceApprovals;
    this.entityL2Services.forEach((item: any, i: number) => { array[i] = { approvalLevels: [], serviceId: item.serviceId } });
  }

  getUniqueL2Approvals(serviceLineId: string, index: any) {
    this.hexLoader = true;
    let body = {
      "entityServiceLineId": this.selectedServiceLine?.entityServiceLineId,
      "entityId": this.selectedEntity.entityId,
      "serviceLineId": serviceLineId,
      "isServiceEnabled": this.selectedServiceLine?.isServiceEnabled
    }
    this.entitiesService.getUniqueL2Approval(body).subscribe({
      next: res => {
        if (res.data != null) {
          this.hexLoader = false;
          this.uniqueL2Approvals = res?.data;
          this.setL2ApprovalsRoles(index);
          this.mapL2Approvals(index);
        }
      }
    })
  }

  setL2ApprovalsRoles(i: any) {
    this.uniqueL2Approvals?.forEach((element: any, index: number) => {
      this.L2RoleOne[i][index] = [];
      this.L2RoleTwo[i][index] = [];
      this.L2RoleThree[i][index] = [];

      if (element?.approvalLevels?.length > 0) {
        element.approvalLevels.forEach((el: any) => {
          if (el.approvalLevelDesc === 'Level 1') {
            this.L2RoleOne[i][index] = [{
              roleName: el.roleName, roleId: el.roleId
            }];
          }
          else if (el.approvalLevelDesc === 'Level 2') {
            this.L2RoleTwo[i][index] = [{ roleName: el.roleName, roleId: el.roleId }];
          }
          else {
            this.L2RoleThree[i][index] = [{ roleName: el.roleName, roleId: el.roleId }];
          }
        });
      }
    });
  }

  mapL2Approvals(approvalIndex: number) {
    let l2approvals = this.serviceLineApprovals[approvalIndex].l2ServiceApprovals;
    this.uniqueL2Approvals?.forEach((element: any, index: number) => {
      l2approvals[index] = { serviceId: element.l2ServiceId, approvalLevels: [] };
      element?.approvalLevels?.forEach((el: any, i: number) => {
        if (el.approvalLevelId && el.roleId) {
          l2approvals[index].approvalLevels[i] = { approvalLevelId: el.approvalLevelId, roleId: el.roleId }
        }
      })
    });
  }

  selectRow(item: any, index: number) {
    if (this.selectedIndex !== index) {
      if (this.selectedIndex === 1 && index === 0 && this.value) {
        this.showL2Approvals = true;
      }
      else { this.showL2Approvals = false }
      this.selectedIndex = index;
      this.selectedServiceLine = item;
      this.approvalCount = this.entityServiceLineApprovals[this.selectedIndex]?.l2ServicesApprovalCount;
      this.uniqueL2Approvals = [];
      this.entityL2Services = null;
      this.serviceLineApprovals[index].entityServiceLineId = item?.entityServiceLineId;
      this.updateServiceLineApprovals(item.serviceLineId, index);
    }
  }

  getApprovalLevelList() {
    this.entitiesService.getApprovalLevel().subscribe({
      next: res => {
        this.approvalLevelList = res.data ? res?.data : [];
      }
    })
  }

  closeL2services() {
    this.showL2Approvals = false;
    this.approvalCount = 0;
    this.value = false;
    this.selectedIndex = 0;
    this.isSaveApprovals = false;
    this.L1RoleOne = []; this.L1RoleTwo = [];
    this.valueChange.emit(false);
    this.entityServiceLineList = [];
    this.getEntityServiceLines();
  }

  getParameters(approvalLevel: string, list: string, index: number) {
    let levelIndex: any;
    this.isSaveApprovals = true;
    let serviceLineArray = this.serviceLineApprovals[index]?.approvalLevels;
    let L2ApprovalArray = this.serviceLineApprovals[this.selectedIndex]?.l2ServiceApprovals[index]?.approvalLevels;
    let array = (list === 'serviceLine') ? serviceLineArray : L2ApprovalArray;

    levelIndex = array?.findIndex((it: any) => {
      return it.approvalLevelId === approvalLevel;
    });
    return { levelIndex: levelIndex, array: array };
  }

  onSelectItem(item: any, level: number, list: string, index: number) {
    this.approvalLevelId = this.getApprovalLevelId(level);
    let data = this.getParameters(this.approvalLevelId, list, index);
    if (data.levelIndex > -1) {
      data.array[data.levelIndex].roleId = item.roleId;
    }
    else {
      data.array?.push({ "approvalLevelId": this.approvalLevelId, "roleId": item.roleId });
    }
    this.valueChange.emit(true);
  }

  onDeselect(level: number, list: string, index: number) {
    this.approvalLevelId = this.getApprovalLevelId(level);
    let data = this.getParameters(this.approvalLevelId, list, index);
    if (data.levelIndex > -1) {
      data.array.splice(data.levelIndex, 1);
    }
    this.valueChange.emit(true);
  }

  getApprovalLevelId(level: any) {
    let approvalLevelId = this.approvalLevelList[level].approvalLevelId;
    return approvalLevelId;
  }

  saveApprovals() {
    if (this.isSaveApprovals) {
      this.hexLoader = true;
      let approvals: any[] = [];
      let serviceLineApproval: any;
      let index: number = 0;
      let activeServiceLine: any;

      this.serviceLineApprovals.forEach((serviceLine: any) => {
        if (serviceLine.entityServiceLineId != null) {
          approvals.push(serviceLine);
        }
      });

      activeServiceLine = this.selectedServiceLine;
      serviceLineApproval = this.serviceLineApprovals[0];
      this.saveApprovalBody.serviceLineId = activeServiceLine?.serviceLineId;
      this.saveApprovalBody.serviceLineApprovals[0] = serviceLineApproval;

      this.entitiesService.saveApproval(this.saveApprovalBody).subscribe({
        next: res => {
          this.hexLoader = false;
          if (res?.statusCode === 'UNAUTHORIZED_USER') {
            this.statusCodeService.displayToastrMessage(res?.statusCode, 'error', 'entities');
          } else {
            this.statusCodeService.displayToastrMessage(res.statusCode, 'success', 'entities.approvals');
            this.formSaved.emit(true);
          }
          this.entityServiceLineApprovals[index].l2ServicesApprovalCount = res?.data?.l2ServicesApprovalCount;
          this.isSaveApprovals = false;
          // audit track
          const desc = '[' + this.selectedEntity?.entityName + '] ' + this.translate.instant('auditTrail.entities.editApprovals');
          auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
            this.translate.instant('auditTrail.entities.entityDetail'),
            this.translate.instant('common.edit'),
            desc)
        }, error: error => {
          this.hexLoader = false;
          this.formSaved.emit(false);
        }
      })
    }
  }

  removeAccess(index: number) {
    this.serviceLineApprovals[this.selectedIndex].l2ServiceApprovals[index].approvalLevels = [];
    this.L2RoleOne[this.selectedIndex][index] = [];
    this.L2RoleTwo[this.selectedIndex][index] = [];
    this.L2RoleThree[this.selectedIndex][index] = [];
    this.isSaveApprovals = true;
    this.valueChange.emit(true);
  }

  updateServiceLineApprovals(serviceLineId: string, index: number) {
    let body = {
      "entityId": this.selectedEntity?.entityId,
      "serviceLineId": serviceLineId
    }
    this.entitiesService.getServiceLineApprovals(body).subscribe({
      next: res => {
        this.approvalCount = res.data?.l2ServicesApprovalCount;
        this.setServiceLineRoles(res.data?.approvalLevels, index);
        this.mapServiceLineApprovals(res, index);
        if (this.approvalCount > 0) {
          this.getUniqueL2Approvals(this.selectedServiceLine?.serviceLineId, this.selectedIndex);
        }
        else {
          this.getL2Services();
        }
      }
    })
  }

  disableApprovals() {
    return (!this.showL2Approvals && this.getActions('Manage Approvals') && !this.getActions('View Approvals')) ||
      (!this.showL2Approvals && !this.getActions('Manage Approvals') && this.getActions('View Approvals')) ||
      (!this.showL2Approvals && this.getActions('Manage Approvals') && this.getActions('View Approvals')) ||
      (this.showL2Approvals && !this.getActions('Manage Approvals') && this.getActions('View Approvals'));
  }
}
