import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-alphabet-filter',
    templateUrl: './alphabet-filter.component.html',
    styleUrls: ['./alphabet-filter.component.scss'],
    standalone: false
})
export class AlphabetFilterComponent {
  alphabets: string[] = [];
  @Input() selectedChar: string = '';
  @Output() emitAlphabet = new EventEmitter();
  @Input() isLoader: boolean = false;

  ngOnInit() {
    this.generateAlphabets();
  }

  generateAlphabets() {
    for (let i = 65; i <= 90; i++) {
      this.alphabets.push(String.fromCharCode(i));
    }
  }

  alphabetSelection(char: string) {
    this.selectedChar = char === this.selectedChar ? '' : char;
    this.emitAlphabet.emit(this.selectedChar);
  }
}
