import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Entities } from '../../../core/constants/endpoint';

@Injectable({
  providedIn: 'root'
})
export class EntitiesService {

  constructor(private http: HttpClient) { }

  filterEntityList(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.filterEntityList, body);
  }

  fetchEntityServices(id: any): Observable<any> {
    return this.http.get(environment.url + Entities.entityServices + `?${"entityId"}=${id}`);
  }

  saveServiceLineConfig(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.entityServices, body);
  }

  fetchL2Services(body: any): Observable<any> {
    return this.http.get(environment.url + Entities.fetchL2Services + `?${body.query[0].key}=${body.query[0].value}`);
  }

  getEnateEntities(term?: string): Observable<any> {
    return this.http.get(environment.url + Entities.enateEntities + (term ? term : ''));
  }

  getCountries(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.getCountries, body);
  }

  createUpdateEntity(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.createUpdateEntity, body);
  }

  fetchPlatformListByCountry(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.fetchPlatformListByCountry, body);
  }

  fetchClientList(): Observable<any> {
    return this.http.get(environment.url + Entities.fetchEntityClients);
  }

  fetchCountryList(): Observable<any> {
    return this.http.get(environment.url + Entities.fetchCountriesList);
  }

  exportEntities(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.exportEntity, body);
  }

  fetchExecRules(): Observable<any> {
    return this.http.get(environment.url + Entities.fetchExecRules);
  }

  payDayFrequencyList(): Observable<any> {
    return this.http.get(environment.url + Entities.getPayDayFrequencyList);
  }

  payrollinfo(entity: any): Observable<any> {
    return this.http.get(environment.url + Entities.getPayrollinfo + entity);
  }

  savePayrollinfo(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.savePayrollinfo, body);
  }

  payrollFrequencyList(): Observable<any> {
    return this.http.get(environment.url + Entities.getPayrollFrequencyList);
  }

  daysFormonth(month: any): Observable<any> {
    return this.http.get(environment.url + Entities.daysFormonth + month);
  }

  payDayRange(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.getPayDayRange, body);
  }

  getEntityInfo(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.entityDetail, body);
  }

  filterPayElementsList(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.filterPayElementsList, body);
  }

  savePayElement(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.createPayElement, body);
  }

  getPayElementTypes(): Observable<any> {
    return this.http.get(environment.url + Entities.getPayElementTypes, {});
  }

  getWageGroups(): Observable<any> {
    return this.http.get(environment.url + Entities.getWageGroups, {});
  }

  manageClientImport(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.manageClientImport, body);
  }

  getEntityClients(): Observable<any> {
    return this.http.get(environment.url + Entities.entitiesClients);
  }

  uniqueEntityNameCheck(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.uniqueCheck, body);
  }

  deletePayElements(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.deletePayElements, body);
  }

  payElementsUniqueCheck(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.payElementsUniqueCheck, body);
  }

  saveUpdateJobBand(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.saveUpdateJobBand, body);
  }

  getAllJobBands(entityId: any): Observable<any> {
    return this.http.get(environment.emp_url + Entities.customValues.getAllJobBands + entityId);
  }

  switchJobBandStatus(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.switchJobBandStatus, body);
  }

  deleteJobBand(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.deleteJobBand, body);
  }

  jobBandDuplicateCheck(body: any): Observable<any> {
    return this.http.get(environment.emp_url + Entities.customValues.jobBandDuplicateCheck + `?entityId=${body.entityId}` + `&jobBandName=${encodeURIComponent(body.jobBandName)}`);
  }

  getPayGroup(id: string): Observable<any> {
    return this.http.get(environment.emp_url + Entities.customValues.getPayGroup + `?${"entityId"}=${id}`);
  }

  saveAndUpdatePayGroup(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.saveAndUpdatePayGroup, body);
  }

  switchPayGroup(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.switchPayGroup, body);
  }

  deletePayGroup(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.deletePayGroup, body);
  }

  getCostCentre(id: string): Observable<any> {
    return this.http.get(environment.emp_url + Entities.customValues.getCostCentre + `?${"entityId"}=${id}`);
  }

  switchCostCentre(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.switchCostCentre, body);
  }

  saveUpdateCostCentre(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.saveUpdateCostCentre, body);
  }

  duplicateCheckCostCentre(body: any): Observable<any> {
    return this.http.get(environment.emp_url + Entities.customValues.duplicateCheckCostCentre + `?entityId=${body.id}&text=${encodeURIComponent(body.text)}&type=${body.type}`);
  }

  deleteCostCentre(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.deleteCostCentre, body);
  }

  saveUpdateJobTitle(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.saveUpdateJobTitle, body);
  }

  getJobTitle(id: string): Observable<any> {
    return this.http.get(environment.emp_url + Entities.customValues.getJobTitle + `?${"entityId"}=${id}`);
  }

  deleteJobTitle(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.deleteJobTitle, body);
  }

  switchJobtitleStatus(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.switchJobtitleStatus, body);
  }

  jobTitleDuplicateCheck(body: any): Observable<any> {
    return this.http.get(environment.emp_url + Entities.customValues.jobTitleDuplicateCheck + `?entityId=${body.entityId}` + `&jobTitleName=${encodeURIComponent(body.jobTitleName)}`);
  }

  getDepartment(entityId: string): Observable<any> {
    return this.http.get(environment.emp_url + Entities.customValues.getdepartment + `?entityId=${entityId}`);
  }

  saveUpdateDepartment(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.saveUpdateDepartment, body);
  }

  deleteDepartment(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.deleteDepartment, body);
  }

  switchDepartment(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.switchDepartment, body);
  }

  addWorkSchedule(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.addWorkSchedule, body);
  }

  getWorkSchedules(entityId: any): Observable<any> {
    return this.http.get(environment.emp_url + Entities.customValues.getWorkSchedules + `?${"entityId"}=${entityId}`);
  }

  deleteWorkSchedule(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.deleteWorkSchedule, body);
  }

  switchWorkScheduleStatus(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.switchWorkScheduleStatus, body);
  }

  getTerminationReason(entityId: any): Observable<any> {
    return this.http.get(environment.emp_url + Entities.customValues.getTerminationReason + `?entityId=${entityId}`);
  }

  deleteTerminationReason(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.deleteTerminationReason, body);
  }

  saveUpdateTerminationReason(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.saveUpdateTerminationReason, body);
  }

  switchTerminationReason(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.switchTerminationReason, body);
  }

  uniqueCheckTerminationReason(terminationReason: string, entityId: string): Observable<any> {
    return this.http.get(environment.emp_url + Entities.customValues.uniqueTerminationReasonCheck + `?terminationReason=${encodeURIComponent(terminationReason)}&entityId=${entityId}`);
  }

  uniqueCheckPayGroup(entityId: any, payGroupName: any): Observable<any> {
    return this.http.get(environment.emp_url + Entities.customValues.uniquePayGroupCheck + `?entityId=${entityId}&payGroupName=${encodeURIComponent(payGroupName)}`);
  }

  uniqueCheckDepartment(body: any): Observable<any> {
    return this.http.get(environment.emp_url + Entities.customValues.uniqueDepartmentCheck + `?entityId=${body.entityId}&text=${encodeURIComponent(body.text)}&type=${body.type}`);
  }

  WorkScheduleDuplicateCheck(body: any): Observable<any> {
    return this.http.get(environment.emp_url + Entities.customValues.WorkScheduleDuplicateCheck + `?entityId=${body.id}&text=${encodeURIComponent(body.text)}&type=${body.type}`);
  }

  getActivityTypes(entityId: string): Observable<any> {
    return this.http.get(environment.workflow_url + Entities.schedule.getActivityTypes + `?entityId=${entityId}`);
  }

  getEntityServiceName(body: any): Observable<any> {
    return this.http.post(environment.workflow_url + Entities.schedule.getEntityServiceName, body);
  }

  getScheduleDetails(body: any): Observable<any> {
    return this.http.post(environment.workflow_url + Entities.schedule.getScheduleDetails, body);
  }

  saveSchedule(body: any): Observable<any> {
    return this.http.post(environment.workflow_url + Entities.schedule.saveScheduleConfiguration, body);
  }

  getCobaltStageMappings(): Observable<any> {
    return this.http.get(environment.workflow_url + Entities.schedule.stageMappings);
  }

  fetchExcelData(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.fetchExcelData, body);
  }

  getEntityServiceLines(entityId: string): Observable<any> {
    return this.http.get(environment.url + Entities.approvals.getEntityServiceLines + `?entityId=${entityId}`);
  }

  getServiceLineApprovals(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.approvals.getServiceLineApprovals, body);
  }

  getL2Services(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.approvals.getL2Services, body);
  }

  getUniqueL2Approval(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.approvals.getUniqueL2Approval, body);
  }

  saveApproval(body: any): Observable<any> {
    return this.http.post(environment.url + Entities.approvals.saveApprovals, body);
  }

  getApprovalRoles(): Observable<any> {
    return this.http.get(environment.url + Entities.approvals.getApprovalRoles);
  }

  getApprovalLevel(): Observable<any> {
    return this.http.get(environment.url + Entities.approvals.getApprovalLevel);
  }

  manualTrigger(entityId: string): Observable<any> {
    return this.http.get(environment.url + Entities.manualTrigger + `?entityId=${entityId}`);
  }

  checkScheduleStatus(entityId: string): Observable<any> {
    return this.http.get(environment.workflow_url + Entities.schedule.checkScheduleStatus + `?entityId=${entityId}`);
  }

  scheduleTrigger(entityId: any): Observable<any> {
    return this.http.post(environment.workflow_url + Entities.schedule.scheduleTrigger, entityId);
  }

  payElementsSampleFileDownload(): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/octet-stream'
    });
    return this.http.get(environment.url + Entities.payElementsSampleFileDownload, { headers, responseType: 'blob' });
  }

  getAbsenceType(entityId: string): Observable<any> {
    return this.http.get(environment.emp_url + Entities.customValues.getAbsenceType + `?entityId=${entityId}`);
  }

  saveUpdateAbsenceType(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.saveUpdateAbsenceType, body);
  }

  absenceTypeDuplicateCheck(body: any): Observable<any> {
    return this.http.get(environment.emp_url + Entities.customValues.absenceTypeDuplicateCheck + `?entityId=${body.entityId}` + `&absenceType=${encodeURIComponent(body.absenceType)}`);
  }

  switchAbsenceTypeStatus(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.switchAbsenceType, body);
  }

  deleteAbsenceType(body: any): Observable<any> {
    return this.http.post(environment.emp_url + Entities.customValues.deleteAbsenceType, body);
  }
}
