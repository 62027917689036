import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hex-loader',
  templateUrl: './hex-loader.component.html',
  styleUrls: ['./hex-loader.component.scss']
})
export class HexLoaderComponent {
  @Input() page: string = '';
}
