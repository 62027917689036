import { AuthService } from './../../auth/services/auth.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../app.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private toastr: ToastrService, private translate: TranslateService,  private authService: AuthService, private appService: AppService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modifiedRequest = this.getToken(request);

    // Pass the modified request to the next interceptor or to the HttpHandler
    return next.handle(modifiedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error?.error?.message[0]?.includes('JWT token expired')) {

          if (error?.error?.message[0]?.includes('user is disabled') || error?.error?.message[0]?.includes('user not found')) {
            this.authService.logout('access-revoked');
          } else { this.authService.logout(); }

        } else if (!this.router.url?.includes('login') && !this.router.url?.includes('logout')) { this.toastr.error(error.status + ": " + this.translate.instant('common.apiError')); }

        return throwError(error);
      })
    );
  }


  getToken(request: HttpRequest<any>) {
    // Get the access token from your authentication service
    const token = localStorage.getItem('token');
    if (!token && request.url.includes('api/v1')) {
      this.router.navigate(['login']);
    }

    const headers = {
      'Accept-Language': localStorage.getItem('language')?.toLowerCase() ?? 'en',
      'Authorization': `${token}`
    };

    // Clone the request and add the access token to the Authorization header
    return request.clone({ setHeaders: headers });
  }
}
