<ng-container *ngIf="field === sortField; else noSort">
    <i class="icon-sort-asc" *ngIf="sortOrder === 'asc'">
        <span class="path1"></span> <span class="path2"></span>
    </i>
    <i class="icon-sort-desc" *ngIf="sortOrder === 'desc'">
        <span class="path1"></span> <span class="path2"></span>
    </i>
</ng-container>

<ng-template #noSort>
    <i class="icon-sort"></i>
</ng-template>