<!-- calendar -->
<div class="calendar">
  <div class="calendar-header">
    <span class="mt-1 d-inline-block ms-2">
      <span class="left-arrow" [ngClass]="{disabled: selectedDate && selectedDate['year'] === minDate['year']}"
        (click)="switchCalendar(selectedDate['year'],selectedDate['month'],'previous')"></span>

      <span class="year" style="color: #415960">{{selectedDate && selectedDate["year"]}}</span>

      <span class="right-arrow" [ngClass]="{disabled: selectedDate && selectedDate['year'] === maxDate['year']}"
        (click)="switchCalendar(selectedDate['year'], selectedDate['month'], 'next')"></span>
    </span>

    <button class="btn-link float-end"
      (click)="$event.stopPropagation(); emitSelectedDate.emit({ selectedDate: selectedDate, isSelectedDateDisabled: isSelectedDateDisabled })"
      translate>
      entities.done
    </button>
  </div>

  <div class="months">
    <div class="month-item"
      [ngClass]="[selectedDate && selectedDate['month'] === monthIndex + 1 ? 'active' : '',isMonthAvailable(monthIndex) ? 'fnt-md cp' : 'disabled' ]"
      *ngFor="let month of months; index as monthIndex" (click)="switchCalendar(selectedDate['year'], monthIndex + 1)">
      {{ month.substring(0, 3) }}
    </div>
  </div>

  <ngb-datepicker #dp [(ngModel)]="selectedDate" (navigate)="date = $event.next" [firstDayOfWeek]="7"
    [minDate]="minDate" (ngModelChange)="onDateChange($event)" [maxDate]="maxDate" (dateSelect)="onDateChange($event)"
    [dayTemplate]="customDayTemplate" [dayTemplateData]="getDayTemplateData">
  </ngb-datepicker>
  <ng-template #customDayTemplate let-date let-focused="focused">
    <span class="custom-day" [class.highlighted]="isDateHighlighted(date)" [class.selected]="isDateSelected(date)" [class.disabled]="isDateDisabled(date)">
      {{ date.day }}
    </span>
  </ng-template>
</div>