<div class="custom-values h100">
  <div class="title mb-2" translate>entities.entityReferenceData</div>
  <ul class="nav nav-secondary">
    <li class="nav-item" (click)="selectedTab = 'Job Band'">
      <a class="nav-link active cp" [ngClass]="{ active: selectedTab == 'Job Band' }" translate>entities.jobBand</a>
    </li>
    <li class="nav-item" (click)="selectedTab = 'Job Title'">
      <a class="nav-link cp" [ngClass]="{ active: selectedTab == 'Job Title' }" translate>entities.jobTitle
      </a>
    </li>
    <li class="nav-item" (click)="selectedTab = 'Pay Group'">
      <a class="nav-link cp" [ngClass]="{'active' : selectedTab == 'Pay Group'}" translate>entities.payGroup
      </a>
    </li>
    <li class="nav-item" (click)="selectedTab = 'Work Schedule'">
      <a class="nav-link cp" [ngClass]="{ active: selectedTab == 'Work Schedule' }" translate>entities.workSchedule
      </a>
    </li>
    <li class="nav-item" (click)="selectedTab = 'Department'">
      <a class="nav-link cp" [ngClass]="{'active' : selectedTab == 'Department'}" translate>entities.department
      </a>
    </li>
    <li class="nav-item" (click)="selectedTab = 'Cost Centre'">
      <a class="nav-link cp" [ngClass]="{'active' : selectedTab == 'Cost Centre'}" translate>entities.costCentre
      </a>
    </li>
    <li class="nav-item" (click)="selectedTab = 'Termination Reason'">
      <a class="nav-link cp" [ngClass]="{'active' : selectedTab == 'Termination Reason'}"
        translate>entities.terminationReasons.terminationReason
      </a>
    </li>
    <li class="nav-item" (click)="selectedTab = 'Absence Type'">
      <a class="nav-link cp" [ngClass]="{'active' : selectedTab == 'Absence Type'}"
        translate>entities.absenceType.absenceType
      </a>
    </li>
  </ul>
  <section>
    <app-job-band *ngIf="selectedTab == 'Job Band'" [selectedEntity]="selectedEntity"
      [disableEdit]="disableEdit || !getActions('Manage Reference data')"></app-job-band>
    <app-work-schedule *ngIf="selectedTab == 'Work Schedule'" [selectedEntity]="selectedEntity"
      [disableEdit]="disableEdit || !getActions('Manage Reference data')"></app-work-schedule>
    <app-job-title *ngIf="selectedTab == 'Job Title'" [selectedEntity]="selectedEntity"
      [disableEdit]="disableEdit || !getActions('Manage Reference data')"></app-job-title>
    <app-department *ngIf="selectedTab == 'Department'" [selectedEntity]="selectedEntity"
      [disableEdit]="disableEdit || !getActions('Manage Reference data')"></app-department>
    <app-cost-center *ngIf="selectedTab == 'Cost Centre'" [selectedEntity]="selectedEntity"
      [disableEdit]="disableEdit || !getActions('Manage Reference data')"></app-cost-center>
    <app-pay-group *ngIf="selectedTab == 'Pay Group'" [selectedEntity]="selectedEntity"
      [disableEdit]="disableEdit || !getActions('Manage Reference data')"></app-pay-group>
    <app-termination-reason *ngIf="selectedTab == 'Termination Reason'" [selectedEntity]="selectedEntity"
      [disableEdit]="disableEdit || !getActions('Manage Reference data')"></app-termination-reason>
    <app-absence-type *ngIf="selectedTab == 'Absence Type'" [selectedEntity]="selectedEntity"
      [disableEdit]="disableEdit || !getActions('Manage Reference data')"></app-absence-type>
  </section>
</div>