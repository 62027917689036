import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ActivateUserComponent } from './activate-user/activate-user.component';
import { ProfUserClientsComponent } from './prof-user-clients/prof-user-clients.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent, ActivateUserComponent, ProfUserClientsComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    TranslateModule
  ],
  exports:[ProfUserClientsComponent]
})
export class AuthModule { }
