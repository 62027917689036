import { CryptoService } from './../../services/crypto/crypto.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserPreferencesService } from './../../services/user-preferences/user-preferences.service';
import { Component, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { KeyValue } from '@angular/common';
import { SharedService } from '../../services/shared/shared.service';
import { UserPermissionsService } from '../../services/user-permissons/user-permissions.service';
import { TranslateService } from '@ngx-translate/core';
import { auditTracking } from '../../../utils/audit-tracking';
import { StatusCodeService } from '../../services/status-code/status-code.service';

@Component({
    selector: 'app-user-preference',
    templateUrl: './user-preference.component.html',
    styleUrl: './user-preference.component.scss',
    standalone: false
})
export class UserPreferenceComponent {
  @Output() closeUserPreference = new EventEmitter();
  userProfileInfo: any;
  uploadLogo: boolean = false;
  languages: any[] = [];
  timezones: any[] = [];
  notificationPreferences: any;
  deleteprofilePic: boolean = false;
  userProfileForm: FormGroup = this.fb.group({ language: [], timezone: [], preferredName: [], profilePic: [] });
  hexLoader: boolean = true;
  deleteProfileImage: boolean = false;
  userSettings: any = JSON.parse(localStorage.getItem('user-settings') ?? '');
  userDetails: any = JSON.parse(this.crypto.decrypt(localStorage.getItem('user-details') ?? ''));
  notificationInfoText: string = '';

  constructor(private userPreferencesService: UserPreferencesService, private fb: FormBuilder, private toastr: ToastrService, private translate: TranslateService,
    private sharedService: SharedService, private crypto: CryptoService, private userPermissionsService: UserPermissionsService, private statusCodeService: StatusCodeService) { }

  checkConfigureActions() { return this.userPermissionsService.isActions('Notifications', 'Configure Notifications'); }

  ngOnInit() {
    this.sharedService.tracker();
    this.getUserProfileInfo();
    this.getAllLanguages();
    this.getTimezones();
    this.getConfigureNotificationPreferences();
  }

  getUserProfileInfo() {
    this.userPreferencesService.getUserProfileInfo().subscribe({
      next: res => {
        this.userProfileInfo = res.data;

        let description = this.translate.instant('auditTrail.viewUserPreference');
        auditTracking('', '',
          'User Preferences',
          this.translate.instant('auditTrail.auditTypeView'),
          description)

        this.updateProfileForm();
        this.hexLoader = false;
      }, error: error => { this.hexLoader = false; }
    })
  }

  updateProfileForm() {
    this.userProfileForm.patchValue({
      profilePic: this.userProfileInfo?.fileDto?.content ? { objectUrl: this.sharedService.convertBase64ToImage(this.userProfileInfo?.fileDto?.content) } : null,
      preferredName: this.userDetails?.preferredName, language: this.userSettings?.settingValueJson?.languageCode,
      timezone: this.userSettings?.settingValueJson?.timeZoneId
    });
  }

  getAllLanguages() {
    this.userPreferencesService.getAllLanguages().subscribe({
      next: res => { this.languages = res.data ? res.data : []; },
      error: error => { }
    })
  }

  getTimezones() {
    this.userPreferencesService.getTimezones().subscribe({
      next: res => {
        this.timezones = res.data ? res.data : [];
        this.timezones.forEach((it: any) => { it.fullName = it.timeZoneName + ' ' + it.timeZoneDesc + ' ( ' + it.utcOffset + ' )' });
      },
      error: error => { }
    })
  }

  getFunctionality(functionality: string) { return this.userPermissionsService.isFunc(functionality); }

  getConfigureNotificationPreferences() {
    this.userPreferencesService.getConfigureNotificationPreferences().subscribe({
      next: res => {
        // activities
        if (!this.getFunctionality('Tickets')) {
          delete res.data?.Activities;
          delete res.data?.Requests;
        }
        // actions
        if (!this.getFunctionality('Tasks')) { delete res.data?.Actions; }

        // entity
        if (!this.getFunctionality('Entities')) { delete res.data?.Entity; }
        this.getNotificationInfoText();
        this.notificationPreferences = res.data;
      }, error: error => { }
    })
  }

  createFormData() {
    let formData = new FormData();
    formData.append('jsonString', new Blob([JSON.stringify(this.createJsonString())], { type: 'application/json' }));
    if (this.userProfileForm.value.profilePic?.blob) { formData.append('file', this.userProfileForm.value?.profilePic?.blob); }

    return formData;
  }

  createJsonString() {
    let userSettings: any = { ...this.userSettings.settingValueJson };
    let jsonString: any = {
      notificationPreferenceDetails: [], settingValueJson: userSettings, deleteProfileImage: this.deleteProfileImage,
      fileName: this.userProfileForm.value.profilePic?.blob ? this.userProfileForm.value.profilePic?.fileName : ''
    };
    delete jsonString.settingValueJson?.picArray;

    for (let item in this.notificationPreferences) { jsonString.notificationPreferenceDetails.push(...this.notificationPreferences[item]); }

    jsonString.settingValueJson = {
      ...jsonString.settingValueJson, preferredName: this.userProfileForm.value?.preferredName,
      languageCode: this.userProfileForm.value?.language, timeZoneId: this.userProfileForm.value?.timezone,
      timeZoneName: this.timezones.filter((it: any) => { return it.timeZoneId === this.userProfileForm.value?.timezone })[0]?.timeZoneName,
      utcOffset: this.timezones.filter((it: any) => { return it.timeZoneId === this.userProfileForm.value?.timezone })[0]?.utcOffset
    };

    return jsonString;
  }

  saveUserPreferenceDetails() {
    this.toastr.clear();
    this.hexLoader = true;
    this.userPreferencesService.saveUserPreferenceDetails(this.createFormData()).subscribe({
      next: res => {
        setTimeout(() => {
          if (res?.statusCode === 'COBALT_003_141') {
            this.statusCodeService.displayToastrMessage(res?.statusCode, 'success', 'loginPreference');
          }
        }, 10);
        this.hexLoader = false;
        let description = this.translate.instant('auditTrail.updateUserPreference');

        auditTracking('', '',
          'User Preferences',
          'Save',
          description)

        this.updateLocalStorage(res);
      }, error: error => { this.hexLoader = false; }
    })
  }

  updateLocalStorage(res: any) {
    // update preferred name - in user details
    this.userDetails = { ...this.userDetails, preferredName: this.userProfileForm.value.preferredName };
    localStorage.setItem('user-details', this.crypto.encrypt(JSON.stringify(this.userDetails)));
    // localStorage.setItem('user-details-object', JSON.stringify(this.userDetails));

    // update user settings - profile pic / language / time zone
    let jsonString = this.createJsonString();
    delete jsonString?.settingValueJson?.preferredName;

    this.userSettings.settingValueJson = {
      ...this.userSettings.settingValueJson,
      timeZoneId: jsonString.settingValueJson.timeZoneId,
      timeZoneName: jsonString.settingValueJson.timeZoneName,
      utcOffset: jsonString.settingValueJson.utcOffset,
      languageCode: jsonString.settingValueJson.languageCode
    };

    if (res.data) { this.userSettings.settingValueJson = { ...this.userSettings.settingValueJson, picArray: res.data.content, userPicUrl: res.data.fileUrl }; }
    else if (this.deleteProfileImage) { this.userSettings.settingValueJson = { ...this.userSettings.settingValueJson, picArray: "", userPicUrl: "" }; }
    localStorage.setItem('user-settings', JSON.stringify(this.userSettings));
    localStorage.setItem('language', jsonString.settingValueJson.languageCode);
    // updating app as per new settings
    this.userPreferencesService.userSettingsUpdatedSub.next(true);
    this.getNotificationInfoText();
    this.deleteProfileImage = false;
  }

  uploadProfilePhoto(file: any) {
    this.deleteProfileImage = false;
    this.uploadLogo = false;
    if (file) { this.userProfileForm.patchValue({ profilePic: file }); }
  }

  deletePic(event: boolean) {
    if (event) {
      this.userProfileForm.patchValue({ profilePic: null });
      if (this.userProfileInfo?.fileDto) { this.deleteProfileImage = true; }
    }
    this.deleteprofilePic = false;
  }

  originalOrder = (a: KeyValue<any, any>, b: KeyValue<any, any>): any => { return 0; }

  onPreferredNameChange(event: any) {
    this.userProfileForm.patchValue({ preferredName: event.target.value.replace(/\s+/g, ' ').trim() });
  }

  localizeFeature(feature: string) {
    switch (feature) {
      case 'Activities': return this.translate.instant('workflow.activities.activities');
      case 'Actions': return this.translate.instant('workflow.actions.actions');
      case 'Requests': return this.translate.instant('workflow.requests.requests');
      case 'Entity': return this.translate.instant('entities.entity1');
      case 'Notify when a new Action is available for me': return this.translate.instant('userPreference.notifyNewAction');
      case 'Notify When an Action due date is updated': return this.translate.instant('userPreference.notifyActionDuedate');
      case 'Notify when an Action is coming due soon': return this.translate.instant('userPreference.notifyActionDue');
      case 'Notify when an Action is overdue': return this.translate.instant('userPreference.notifyActionOverdue'); 
      case 'Notify when an Action is rejected by another Approver': return this.translate.instant('userPreference.notifyActionReject');
      case 'Notify when a new Activity is created': return this.translate.instant('userPreference.notifyNewActivity');
      case 'Notify when a new communication is available': return this.translate.instant('userPreference.notifyNewCommunication');
      case 'Notify When an Activity due date is updated': return this.translate.instant('userPreference.notifyActivityDuedate');
      case 'Notify when an Activity is cancelled': return this.translate.instant('userPreference.notifyActivitycancel');
      case 'Notify when there is a change in Activity Status': return this.translate.instant('userPreference.notifyActivityStatus');
      case 'Notify When Request due date is updated': return this.translate.instant('userPreference.notifyRequestDueDate');
      case 'Notify when there is a change in Request Status': return this.translate.instant('userPreference.notifyRequestStatus'); 
      default: return '';
    }
  }

  getNotificationInfoText() {
    setTimeout(() => {
      this.notificationInfoText = this.translate.instant('userPreference.notificationInfo');
      if (this.userDetails?.userType === 'super_admin' || this.userDetails?.userType === 'tmf_admin') {
        this.notificationInfoText += this.translate.instant('entities.entity1');
      }
      else {
        if (this.getFunctionality('Tasks')) { this.notificationInfoText = this.notificationInfoText + this.translate.instant('workflow.actions.actions'); }
        if (this.getFunctionality('Tickets')) {
          if (this.getFunctionality('Tasks')) { this.notificationInfoText += ', '; }
          this.notificationInfoText += this.translate.instant('userPreference.activities');
        }
      }
    }, 10);
  }
}
