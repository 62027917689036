import { AbsenceTypeComponent } from './components/entities/custom-values/absence-type/absence-type.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CreatePayElementComponent } from './components/entities/pay-elements/create-pay-element/create-pay-element.component';
import { NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HexLoaderComponent } from './components/hex-loader/hex-loader.component';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { ExportComponent } from './components/export/export.component';
import { WildCardSearchComponent } from './components/wild-card-search/wild-card-search.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { EntityDetailComponent } from './components/entities/entity-detail/entity-detail.component';
import { GeneralComponent } from './components/entities/general/general.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SortComponent } from './components/sort/sort.component';
import { AlphabetFilterComponent } from './components/alphabet-filter/alphabet-filter.component';
import { AlphabetFilterHeaderComponent } from './components/alphabet-filter-header/alphabet-filter-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { ServicesComponent } from './components/entities/services/services.component';
import { PayrunsComponent } from './components/entities/payruns/payruns.component';
import { PayElementsComponent } from './components/entities/pay-elements/pay-elements.component';
import { PayrollBasicInfoComponent } from './components/entities/payroll-basic-info/payroll-basic-info.component';
import { PicUploadComponent } from './components/pic-upload/pic-upload.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LogoFormatComponent } from './components/logo-format/logo-format.component';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';
import { RouterModule } from '@angular/router';
import { CustomValuesComponent } from './components/entities/custom-values/custom-values.component';
import { WorkScheduleComponent } from './components/entities/custom-values/work-schedule/work-schedule.component';
import { JobBandComponent } from './components/entities/custom-values/job-band/job-band.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { FocusOnClickDirective } from './directives/focus-on-click-directive';
import { PayGroupComponent } from './components/entities/custom-values/pay-group/pay-group.component';
import { JobTitleComponent } from './components/entities/custom-values/job-title/job-title.component';
import { DepartmentComponent } from './components/entities/custom-values/department/department.component';
import { CostCenterComponent } from './components/entities/custom-values/cost-center/cost-center.component';
import { TerminationReasonComponent } from './components/entities/custom-values/termination-reason/termination-reason.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { UserPreferenceComponent } from './components/user-preferences/user-preference.component';
import { ScheduleComponent } from './components/entities/schedule/schedule.component';
import { ApprovalsComponent } from './components/entities/approvals/approvals.component';
import { DateFormatPipe } from './pipes/dateFormat/date-format.pipe';
import { DisplayListPopupComponent } from './components/display-list-popup/display-list-popup.component';

@NgModule({
  declarations: [
    HeaderComponent,
    EntityDetailComponent,
    ExportComponent,
    WildCardSearchComponent,
    ProgressBarComponent,
    ToggleComponent,
    GeneralComponent,
    CheckboxComponent,
    ServicesComponent,
    PayrunsComponent,
    FileUploadComponent,
    HexLoaderComponent,
    SortComponent,
    AlphabetFilterComponent,
    PayElementsComponent,
    PayrollBasicInfoComponent,
    PicUploadComponent,
    LogoFormatComponent,
    CreatePayElementComponent,
    ConfirmationPopupComponent,
    AlphabetFilterHeaderComponent,
    CustomValuesComponent,
    WorkScheduleComponent,
    JobBandComponent,
    FocusOnClickDirective, PayGroupComponent,
    JobTitleComponent,
    DepartmentComponent,
    CostCenterComponent,
    TerminationReasonComponent,
    CalendarComponent,
    UserPreferenceComponent, ScheduleComponent, ApprovalsComponent, AbsenceTypeComponent,
    DateFormatPipe,
    DisplayListPopupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxFileDropModule,
    NgMultiSelectDropDownModule.forRoot(),
    ImageCropperModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    NgSelectModule,
    InfiniteScrollModule,
    NgbTooltipModule,
    NgxSkeletonLoaderModule,
    RouterModule,
    NgbDatepickerModule
  ],
  exports: [
    HeaderComponent, ExportComponent, WildCardSearchComponent, ToggleComponent, HexLoaderComponent, ProgressBarComponent,
    CheckboxComponent, EntityDetailComponent, GeneralComponent, FileUploadComponent, SortComponent,
    AlphabetFilterComponent, PicUploadComponent, InfiniteScrollModule, LogoFormatComponent,
    ConfirmationPopupComponent, AlphabetFilterHeaderComponent, CustomValuesComponent, WorkScheduleComponent, JobBandComponent, FocusOnClickDirective, PayGroupComponent, DepartmentComponent, AbsenceTypeComponent,
    CostCenterComponent, TerminationReasonComponent, CalendarComponent, DateFormatPipe, DisplayListPopupComponent

  ]
})
export class SharedModule { }
