<div class="modal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content" style="padding: 22px 20px;">
            <div class="modal-header mb-2 justify-content-between">
                <div [ngClass]="clone ? 'lg' : 'w98'">
                    <!-- main header -->
                    <h5 class="modal-title textoverflow" title="{{selectedEntity?.entityName && (!clone || cloneSaved) ? selectedEntity?.entityName?.trim() :
                            selectedEntity?.clientName ?? selectedEntityClient?.clientName}}">
                        {{selectedEntity?.entityName && (!clone || cloneSaved) ? selectedEntity?.entityName?.trim() :
                        selectedEntityClient?.clientName ?? selectedEntity?.clientName}}</h5>
                    <!-- sub header -->
                    <div class="sub-title textoverflow" title="{{selectedEntity?.clientName && (!clone || cloneSaved) ?
                     selectedEntity?.clientName : 'entities.addNewEntity' | translate}}">
                        {{selectedEntity?.clientName && (!clone || cloneSaved) ? selectedEntity?.clientName :
                        'entities.addNewEntity' | translate}}</div>
                </div>

                <div class="d-flex align-items-center">
                    <!-- cloned tag -->
                    <div *ngIf="clone" class="clone"> <i class="icon-copy"></i>&nbsp; <span
                            translate>entities.cloned</span>
                    </div>

                    <!-- close -->
                    <i class="icon-close cp ms-2" style="font-size: 12px;" (click)="cancelEntityDetail()"
                        [ngClass]="{'disabled' : disableButtons}"></i>
                </div>
            </div>
            <div class="modal-body d-flex">
                <ul class="side-bar bulletted">
                    <li [ngClass]="{'current': selectedTab === 'General'}" (click)="selectEntityTab('General')" *ngIf="(getActions('Create Entity') && (selectedEntityClient || clone || cloneSaved)) ||
                     (!selectedEntityClient && getActions('Manage General Information') || getActions('View General Information'))" translate>
                        entities.general.general
                    </li>

                    <li (click)=" selectEntityTab('Services')" [ngClass]="{'current': selectedTab === 'Services'}"
                        *ngIf="(getActions('Create Entity') && selectedEntityClient || clone || cloneSaved) || (!selectedEntityClient && getActions('Manage Service Configuration') ||
                     getActions('View Service Configuration'))" translate>
                        entities.services
                    </li>

                    <li (click)="selectEntityTab('Payroll Basic Info')" *ngIf="(getActions('Create Entity') && selectedEntityClient || clone || cloneSaved) 
                        || (!selectedEntityClient && getActions('Manage Payroll Information') || getActions('View Payroll Information'))"
                        [ngClass]="{'current': selectedTab === 'Payroll Basic Info'}" translate>
                        entities.payrollBasicinfo</li>

                    <li (click)="selectEntityTab('Pay Elements')"
                        [ngClass]="{'current': selectedTab === 'Pay Elements'}" *ngIf="(getActions('Create Entity') && selectedEntityClient || clone || cloneSaved) ||
                        (!selectedEntityClient && getActions('Manage Pay Elements') || getActions('View Pay Elements'))
                         " translate>entities.payElements </li>

                    <li (click)="selectEntityTab('Entity Reference Data')" *ngIf="(getActions('Create Entity') && selectedEntityClient || clone || cloneSaved) ||
                    (!selectedEntityClient && getActions('Manage Reference data') || getActions('View Reference data'))
                     " [ngClass]="{'current': selectedTab === 'Entity Reference Data'}" translate>
                        entities.entityReferenceData</li>

                    <li (click)="selectEntityTab('Schedule')" *ngIf="(getActions('Create Entity') && selectedEntityClient || clone || cloneSaved) ||
                    (!selectedEntityClient && getActions('Manage Schedule') || getActions('View Schedule'))
                     " [ngClass]="{'current': selectedTab === 'Schedule'}" translate>entities.schedule.schedule</li>

                    <li (click)="selectEntityTab('Approvals')" *ngIf="(getActions('Create Entity') && selectedEntityClient || clone || cloneSaved) ||
                    (!selectedEntityClient && getActions('Manage Approvals') || getActions('View Approvals'))
                     " [ngClass]="{'current': selectedTab === 'Approvals'}" translate>
                        entities.approvals.approvals</li>
                </ul>

                <main>
                    <!-- general -->
                    <app-general *ngIf="selectedTab === 'General'" [selectedEntityClient]="selectedEntityClient"
                        [selectedEntity]="selectedEntity" [clone]="clone" [switchingTab]="switchingTab"
                        (formSaved)="formSubmission($event)" [disableEdit]="disableEdit || !getActions('Manage General Information')" (saveDisabled)="specialCharDisable = $event"
                        (formValid)="disableSave=!$event" [saveForm]="saveForm" [cloneSaved]="cloneSaved" />

                    <!-- services -->
                    <app-services [selectedEntity]="selectedEntity" *ngIf="selectedTab === 'Services'"
                        [switchingTab]="switchingTab" [disableEdit]="disableEdit || !getActions('Manage Service Configuration')" (formValid)="disableSave=!$event"
                        [saveForm]="saveForm" (formSaved)="formSubmission($event)" />

                    <!-- payroll basic info -->
                    <app-payroll-basic-info *ngIf="selectedTab === 'Payroll Basic Info'"
                        [selectedEntity]="selectedEntity" [disableEdit]="disableEdit || !getActions('Manage Payroll Information')" (formValid)="disableSave=!$event"
                        [saveForm]="saveForm" (formSaved)="formSubmission($event)" [switchingTab]="switchingTab" />

                    <!-- pay elements -->
                    <app-pay-elements *ngIf="selectedTab === 'Pay Elements'" [selectedEntity]="selectedEntity"
                    [disableEdit]="disableEdit" [selectedEntityClient]="selectedEntityClient"
                        (enabledAddPayElement)="disableButtons=$event" [clone]="clone" [cloneSaved]="cloneSaved" />
                    <!-- Entity Reference Data -->
                    <app-custom-values *ngIf="selectedTab === 'Entity Reference Data'"  [selectedEntity]="selectedEntity"
                    [disableEdit]="disableEdit"></app-custom-values>

                    <!-- Schedule -->
                    <app-schedule *ngIf="selectedTab === 'Schedule'" [selectedEntity]="selectedEntity"
                    [disableEdit]="disableEdit" [switchingTab]="switchingTab" (valueChange)="disableSave=!$event"
                        [saveForm]="saveForm" (formSaved)="formSubmission($event)"></app-schedule>

                    <!-- Approvals -->
                    <app-approvals *ngIf="selectedTab === 'Approvals'" [selectedEntity]="selectedEntity"
                    [disableEdit]="disableEdit" [switchingTab]="switchingTab" (valueChange)="disableSave=!$event"
                        [saveForm]="saveForm" (formSaved)="formSubmission($event)"></app-approvals>

                </main>
            </div>

            <div class="modal-footer mt-0" [ngClass]="{'disabled' : disableButtons}" *ngIf="(!selectedEntityClient && (selectedTab === 'General' && getActions('Manage General Information')) ||
            (selectedTab === 'Services' && getActions('Manage Service Configuration')) ||
            (selectedTab === 'Payroll Basic Info' && getActions('Manage Payroll Information')) ||
            (selectedTab === 'Pay Elements' && getActions('Manage Pay Elements')) || (selectedTab === 'Entity Reference Data' && getActions('Manage Reference data')) || 
            (selectedTab === 'Schedule' && getActions('Manage Schedule'))  ||  (selectedTab === 'Approvals' && getActions('Manage Approvals'))   ||((selectedEntityClient || clone || cloneSaved) && getActions('Create Entity')))">

                <!-- save / edit -->
                <button class="btn-primary" (click)="createSaveEntity()" [ngClass]="{'disabled' : (disableSave || specialCharDisable) && !disableEdit || 
                (selectedTab === 'Pay Elements' && !disableEdit)}" translate>

                    <ng-container *ngIf="selectedEntity && (!clone || cloneSaved); else createEntity">
                        <ng-container *ngIf="disableEdit">common.edit</ng-container>
                        <ng-container *ngIf="!disableEdit">common.save</ng-container></ng-container>
                    <ng-template #createEntity> entities.createEntity </ng-template>
                </button>

                <!-- cancel -->
                <button class="btn-outline" (click)="cancelEntityDetail()" translate>common.cancel</button>
            </div>
        </div>
    </div>
</div>

<app-confirmation-popup *ngIf="isConfirmPopup" (emitEvent)="handleConfirmation($event)"></app-confirmation-popup>