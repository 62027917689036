import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent {
  @Input() label: string = "";
  @Output() emitToggle = new EventEmitter();
  @Input() checked: boolean = false;
  @Input() bg: boolean = false;
  @Input() isLoader: boolean = false;
  @Input() disabled: boolean = false;
  @Input() preventDefault: boolean = false;
}
