
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../services/shared/shared.service';
import { CustomImageCroppedEvent } from '../../models/ImageCroppedEvent-interface';
@Component({
    selector: 'app-pic-upload',
    templateUrl: './pic-upload.component.html',
    styleUrl: './pic-upload.component.scss',
    standalone: false
})
export class PicUploadComponent {
  imageChangedEvent: any = '';
  croppedImage: any;
  isCorrupted = false;
  hexLoader:boolean = false;
  fileName: string = '';
  isDeleted: boolean = false;

  @Output() emitImage = new EventEmitter();
  @Input() entityLogo: boolean = false;
  @Input() default: boolean = false;
  @Input() userPic: any;

  constructor(private toastr: ToastrService, private translate: TranslateService, private sharedService: SharedService) { }

  ngOnInit() {
    if(this.userPic) {
      this.userPic = this.sharedService.convertBase64ToImage(this.userPic);
    }
  }

  fileChangeEvent(event: any): void {
    const imageFile: File = event.target.files[0];
    const extension = imageFile.name.substring(imageFile.name.lastIndexOf('.') + 1)?.toLowerCase();
    const target = event.target as HTMLInputElement;
    this.fileName = imageFile.name;
    this.toastr.clear();

    if (imageFile?.size <= 5242880 && (extension?.includes('png') || extension?.includes('jpeg') || extension?.includes('jpg'))) {
      this.imageChangedEvent = event;
      if (target.files && target.files.length > 0) {
        this.validateImage(target.files[0], event);
      }
    } else {
      event.target.value = '';
      this.imageChangedEvent = null;
      this.croppedImage = null;

      if (imageFile?.size > 5242880) { this.toastr.error(this.translate.instant('fileErrors.fileSizeExceeds')) }
      if (!extension?.includes('png') && !extension?.includes('jpeg') && !extension?.includes('jpg')) {
        this.toastr.error(this.translate.instant('fileErrors.fileTypeNotSupported'));
      }
    }    
  }

  validateImage(file: File, event: any) {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      this.userPic = null;
      this.isCorrupted = false;
    };

    img.onerror = () => {
      this.isCorrupted = true;
      event.target.value = '';
      this.imageChangedEvent = '';
      this.croppedImage = '';
      this.toastr.clear();
      this.toastr.error(this.translate.instant('fileErrors.fileIsCorrupted'));
      let currentFile = <HTMLInputElement>document.getElementById("fileTag");
      currentFile.value = '';
    };
  }

  imageCropped(event: CustomImageCroppedEvent) {
    event.fileName = this.fileName;
    this.croppedImage = event;
  }

  crop() {
    this.emitImage.emit(this.croppedImage);
  }
}