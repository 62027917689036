import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'app-unauth-access',
    templateUrl: './unauth-access.component.html',
    styleUrl: './unauth-access.component.scss',
    standalone: false
})
export class UnauthAccessComponent {
  constructor(private authService: AuthService) { }

  onLogout() { this.authService.logout(); }
}
