import { Injectable } from '@angular/core';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsService {
  userDetails: any;

  constructor(private crypto: CryptoService) {
    this.userDetails = JSON.parse(this.crypto.decrypt(localStorage.getItem('user-details') ?? ''));
  }

  isFunc(functionality: string) {
    return this.userDetails.functionalities?.map((it: any) => { return it.functionalityName }).indexOf(functionality) > -1;
  }

  isActions(functionality: string, action: string) {
    let index = this.userDetails.functionalities?.map((it: any) => { return it.functionalityName }).indexOf(functionality);
    return index > -1 && this.userDetails.functionalities[index]?.actions?.map((it: any) => { return it.actionName }).indexOf(action) > -1;
  }
}
