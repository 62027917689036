<div class="modal user-preferences">
  <div class="modal-dialog">
    <div class="modal-content p-0">
      <!-- header -->
      <div class="modal-header shadow align-items-center mb-3">
        <span class="modal-title" translate><i class="icon-gear"></i> userPreference.title</span>
        <i class="icon-close cp" (click)="closeUserPreference.emit()"></i>
      </div>

      <!-- body -->
      <div class="modal-body mt-0 mb-0">
        <div class="left-div">
          <div class="d-flex">
            <div class="profile-details">
              <!-- profile pic -->
              <i class="icon-user-profile mb-2" *ngIf="!userProfileForm.value.profilePic">
                <span class="path1"></span> <span class="path2"></span>
              </i>
              <div *ngIf="userProfileForm.value.profilePic" class="profile-pic">
                <img [src]="userProfileForm.value.profilePic?.objectUrl" alt="uploaded-profile-pic" />

                <span class="delete-pic" (click)="deleteprofilePic=true"> <i class="icon-bin"></i></span>
              </div>

              <!-- user info -->
              <div class="profile-name title fnt-md w100 text-center" [title]="userProfileInfo?.userName">
                <span class="d-inline-block textoverflow">{{userProfileInfo?.userName}} </span> </div>
               <div class="info fnt-reg mail w100 text-center" [title]="userProfileInfo?.email">
                 <span class="textoverflow d-inline-block">{{userProfileInfo?.email}}</span>
               </div>

              <!-- upload section -->
              <div class="upload-section flex-center mt-2">
                <button class="btn-link" (click)="uploadLogo = true">
                  <img height="12px" style="margin-top: -4px;" src="./assets/images/camera.png" alt="camera" />
                  <span translate>userPreference.uploadPhoto</span>
                </button>
              </div>
              <div class="note" translate>common.fileFormatInfo</div>
            </div>

            <!-- form -->
            <form class="form h100" [formGroup]="userProfileForm" style="min-height: 260px;">
              <!-- language -->
              <div class="form-group mb-4">
                <label for="" translate> userPreference.language</label>
                <ng-select [items]="languages" class="md" bindLabel="languageName" bindValue="languageCd"
                  [searchable]="true" [clearable]="false" [placeholder]="'common.select' | translate"
                  [notFoundText]="'common.nodata' | translate" formControlName="language"> </ng-select>
              </div>

              <!-- timezone -->
              <div class="form-group mb-4">
                <label for="" translate> userPreference.timezone</label>
                <ng-select [items]="timezones" class="md" bindLabel="fullName" bindValue="timeZoneId"
                  [searchable]="true" [clearable]="false" [placeholder]="'common.select' | translate"
                  [notFoundText]="'common.nodata' | translate" formControlName="timezone"> </ng-select>
              </div>

              <!-- preferred name -->
              <div class="form-group mb-4">
                <div class="d-flex justify-content-between">
                  <label for="" translate> userPreference.preferredName</label>
                  <span class="char-info"> <strong>{{userProfileForm.value.preferredName?.length ?? 0}}</strong> / 40
                    <span translate>common.chars</span> </span>
                </div>
                <input [placeholder]="'common.enterPreferredName' | translate" maxlength="40" type="text"
                  formControlName="preferredName" (change)="onPreferredNameChange($event)" />
              </div>

              <!-- client name -->
              <div class="form-group mb-4"
                *ngIf="userProfileInfo?.clientName && userDetails?.userType === 'client_user'">
                <div class="d-flex justify-content-between">
                  <label for="" translate> common.clientName</label>
                </div>
                <input disabled [value]="userProfileInfo?.clientName" [title]="userProfileInfo?.clientName" />
              </div>
            </form>
          </div>

          <!-- user based count per features -->
          <ul class="services-group">
            <li *ngIf="userDetails?.userType !== 'client_user'"><i class="icon-group"></i>
              <div class="count">{{userProfileInfo?.clientCount}}</div>
              <div class="info" translate>common.clients</div>
            </li>
            <li *ngIf="false"><i class="icon-world"></i>
              <div class="count">{{userProfileInfo?.regionCount}}</div>
              <div class="info" translate>userPreference.regions</div>
            </li>
            <li><img src="./assets/images/countries-icon.png" alt="">
              <div class="count">{{userProfileInfo?.countryCount}}</div>
              <div class="info" translate>common.countries</div>
            </li>
            <li><i class="icon-entities"></i>
              <div class="count">{{userProfileInfo?.entityCount}}</div>
              <div class="info" translate>common.entities</div>
            </li>
          </ul>
        </div>

        <!-- Notifications -->
        <div class="right-div"
          *ngIf="(getFunctionality('Tasks') || getFunctionality('Tickets') ||  getFunctionality('Entities')) && checkConfigureActions()">
          <div class="info fnt-md" translate>
            <span class="me-2" translate>userPreference.notifications</span>

            <img height="13px" src="./assets/images/info.png" alt="info" tooltipClass="md" data-trigger="hover"
              [ngbTooltip]="notificationInfoText" style="vertical-align: text-top;" />
          </div>

          <div class="table-wrapper mt-2">

            <table class="mb-3" aria-describedby="notifications"
              *ngFor="let item of notificationPreferences | keyvalue : originalOrder;index as objIndex;">
              <thead>
                <tr>
                  <th scope="">{{localizeFeature(item.key)}}</th>
                  <th class="textoverflow" *ngIf="objIndex === 0 && userDetails?.userType !== 'super_admin' && userDetails?.userType !== 'tmf_admin'" [title]="'userPreference.inApp' | translate"
                    translate>userPreference.inApp</th>
                  <th class="textoverflow" *ngIf="objIndex === 0" [title]="'userPreference.email' | translate"
                    translate>userPreference.email</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let it of item.value; index as i;">
                  <td [ngClass]="{'fnt-md' : it.portalNotification === 1 || it.emailNotification === 1}">
                    {{item.key === 'Client' || item.key === 'Entity' ? it.notificationType : it.notificationSubType}}
                  </td>
                  <td *ngIf="userDetails?.userType !== 'super_admin' && userDetails?.userType !== 'tmf_admin'">
                    <app-toggle (emitToggle)="it.portalNotification=+(!it.portalNotification)"
                      [checked]="it.portalNotification === 1"> </app-toggle>
                  </td>
                  <td>
                    <app-toggle (emitToggle)="it.emailNotification=+(!it.emailNotification)"
                      [checked]="it.emailNotification === 1"> </app-toggle>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- footer -->
      <div class="modal-footer shadow mt-3 py-1">
        <button class="btn-primary" (click)="saveUserPreferenceDetails()" translate>
          <i class="icon-save"></i>common.save
        </button>
        <button class="btn-outline" (click)="closeUserPreference.emit()" translate>
          <i class="icon-circle-close"></i>common.cancel
        </button>
        <span translate class="copyright pe-0 me-0">header.version</span>
      </div>
    </div>
  </div>
</div>


<!-- pic upload -->
<app-pic-upload *ngIf="uploadLogo" (emitImage)="uploadProfilePhoto($event)" [default]="true"></app-pic-upload>

<!-- confimation for profile pic delete -->
<app-confirmation-popup *ngIf="deleteprofilePic" [description]="'common.deleteProfilePic' | translate"
  (emitEvent)="deletePic($event)"></app-confirmation-popup>

<app-hex-loader *ngIf="hexLoader"></app-hex-loader>
