import { Component } from '@angular/core';

@Component({
    selector: 'app-payruns',
    templateUrl: './payruns.component.html',
    styleUrl: './payruns.component.scss',
    standalone: false
})
export class PayrunsComponent {

}
