import { Component, Input, OnInit } from '@angular/core';
import { EntitiesService } from '../../../../../modules/entities/services/entities.service';
import { ToastrService } from 'ngx-toastr';
import { StatusCodeService } from '../../../../services/status-code/status-code.service';
import { Subject, debounceTime } from 'rxjs';
import { auditTracking } from '../../../../../utils/audit-tracking';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-job-title',
    templateUrl: './job-title.component.html',
    styleUrl: './job-title.component.scss',
    standalone: false
})
export class JobTitleComponent implements OnInit {
  @Input() selectedEntity: any;
  @Input() disableEdit: boolean = false;
  isLoader: boolean = true;
  hexLoader: boolean = false;
  jobTitle: any = '';
  listOfJobTitles: any[] = [];
  isConfirmPopup: string = '';
  jobTitleId: any = '';
  editableRowIndex: number = -1;
  selectedRow: any;
  duplicateJobTitle: boolean = false;
  jobTitleSubject = new Subject<any>();


  constructor(private entitiesService: EntitiesService, private toastr: ToastrService, private showStatusCode: StatusCodeService,
    private translate: TranslateService
  ) {
    this.jobTitleCheck();
  }

  ngOnInit(): void {
    this.getJobTitle();

  }

  getJobTitle() {
    this.entitiesService.getJobTitle(this.selectedEntity?.entityId).subscribe({
      next: (res) => {
        if (res?.data) this.listOfJobTitles?.push(...res.data);
        this.disableloader();

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.viewJobTitle');
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('auditTrail.auditTypeView'),
          description)

      },
      error: (error) => {
        this.disableloader();
      }
    })
  }

  saveUpdateJobTitle(jobDetails?: any) {
    if (!this.duplicateJobTitle) {
      this.toastr.clear();
      this.hexLoader = true;
      let body: any = {
        "jobTitleId": jobDetails ? jobDetails?.jobTitleId : null,
        "jobTitleName": jobDetails ? jobDetails.jobTitleName.trim() : this.jobTitle.trim(),
        "entityId": this.selectedEntity?.entityId
      }
      this.entitiesService.saveUpdateJobTitle(body).subscribe({
        next: (res) => {
          if (res?.statusCode === 'UNAUTHORIZED_USER') {
            this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
          } else {
            this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', jobDetails ? 'common' : 'entities.jobTitles');
          }
          if (jobDetails) {
            let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.editJobTitle');

            auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
              this.translate.instant('auditTrail.entities.entityDetail'),
              this.translate.instant('common.edit'),
              description)

          } else {
            let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.addJobTitle');

            auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
              this.translate.instant('auditTrail.entities.entityDetail'),
              this.translate.instant('common.add'),
              description)
          }

          this.resetJobTitle();
        },
        error: (error) => {
          this.hexLoader = false;
        }
      })
    }
  }

  resetJobTitle() {
    if (this.editableRowIndex === -1) { this.jobTitle = '' };
    this.isLoader = true;
    this.listOfJobTitles = [];
    this.editableRowIndex = -1;
    this.duplicateJobTitle = false;
    this.getJobTitle();
  }

  deleteJobTitle() {
    this.toastr.clear();
    this.hexLoader = true;
    let body: any = {
      "jobTitleId": [this.jobTitleId],
      "entityId": this.selectedEntity?.entityId
    }
    this.entitiesService.deleteJobTitle(body).subscribe({
      next: (res) => {
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', 'entities.jobTitles');
        }
        this.hexLoader = false;

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.deleteJobTitle');
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('common.delete'),
          description)

        this.resetJobTitle();
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }

  onInputChange(event: any) {
    this.duplicateJobTitle = false;
    this.jobTitleSubject.next(event.target.value.trim() || '');
  }

  jobTitleCheck() {
    this.jobTitleSubject.pipe(debounceTime(500)).subscribe(keyword => {
      if (keyword !== '') {
        if (keyword === this.selectedRow?.jobTitleName) {
          return;
        } else {
          this.jobTitleUniqueCheckAPI(keyword);
        }
      }
    })
  }

  jobTitleUniqueCheckAPI(fieldName: any) {
    this.hexLoader = true;
    let body: any = {
      "entityId": this.selectedEntity?.entityId,
      "jobTitleName": fieldName,
    }
    this.entitiesService.jobTitleDuplicateCheck(body).subscribe({
      next: (res) => {
        this.duplicateJobTitle = res.data;
        this.disableloader();
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }

  inlineDelete(id: any) {
    this.isConfirmPopup = 'delete';
    this.jobTitleId = id;
  }

  handleConfirmation(ev: boolean) {
    if (ev) {
      if (this.isConfirmPopup === 'edit') {
        this.resetEditRow();
      } else {
        this.deleteJobTitle();
      }
    }
    this.isConfirmPopup = '';
  }

  resetEditRow() {
    this.listOfJobTitles[this.editableRowIndex].jobTitleName = this.selectedRow.jobTitleName;
    this.editableRowIndex = -1;
    this.selectedRow = {};
  }

  statusToggleForJobTitle(event: boolean, id: string) {
    this.toastr.clear();
    this.hexLoader = true;
    let body: any = {
      "id": id,
      "entityId": this.selectedEntity?.entityId,
      "status": event ? 'ACTIVE' : 'INACTIVE'
    }
    this.entitiesService.switchJobtitleStatus(body).subscribe({
      next: (res) => {
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', 'entities.jobTitles');
        }
        this.hexLoader = false;

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.editJobTitle');

        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('common.edit'),
          description)

        this.resetJobTitle();
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }

  editRow(index: number) {
    this.duplicateJobTitle = false;
    this.editableRowIndex = index;
    this.selectedRow = JSON.parse(JSON.stringify(this.listOfJobTitles[index]));
  }

  cancelEdit(job: any) {
    if (this.selectedRow.jobTitleName !== job.jobTitleName) {
      this.isConfirmPopup = 'edit';
    } else {
      this.editableRowIndex = -1;
      this.selectedRow = {};
    }
    this.duplicateJobTitle = false;
  }

  disableloader() {
    if (this.isLoader) { this.isLoader = false; }
    if (this.hexLoader) { this.hexLoader = false; }
  }
}
