import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  constructor() { }

  convertTimeZone() {
    //offset to covert to particular time zone
    let userSettings = localStorage.getItem('user-settings') && JSON.parse(localStorage.getItem('user-settings') ?? '');
    let utcOffset = userSettings?.settingValueJson?.utcOffset?.toString();
    // STEP 1

    //date object for current location
    let today = new Date();
    //Express this local time as the number of milliseconds -(convert to msec)
    let localTime = today.getTime();


    // STEP 2

    //find local time zone offset - and convert to msec
    let localOffset = today.getTimezoneOffset() * 60000;
    // Note that a negative return value from getTimezoneOffset() indicates that the current location
    // is ahead of UTC, while a positive value indicates that the location is behind UTC.


    // STEP 3

    // Obtain the current UTC time, by adding the local time zone offset to the local time.
    let utc = localTime + localOffset;

    // STEP 4

    // Once you have obtained UTC time, obtain the destination city's UTC offset in hours,
    // convert it to milliseconds and add it to UTC time
    let timeZoneDateTime: any;
    let startIndex = utcOffset?.indexOf('C');
    if (startIndex) {
      let offsetSign: any = utcOffset?.substring(startIndex + 1, startIndex + 2);
      let currentOffset = utcOffset?.substring(startIndex + 2, utcOffset?.length);
      let finalValue: any = currentOffset ? this.timeStringToFloat(currentOffset) : 0;
      timeZoneDateTime = utc + (3600000 * offsetSign?.concat(finalValue));
    }

    return new Date(timeZoneDateTime);
  }

  timeStringToFloat(time: any) {
    let hoursMinutes = time.split(/[.:]/);
    let hours = parseInt(hoursMinutes[0], 10);
    let minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return (hours + minutes / 60).toFixed(2);
  }
}
