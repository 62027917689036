import { Component, Input } from '@angular/core';
import { EntitiesService } from '../../../../../modules/entities/services/entities.service';
import { StatusCodeService } from '../../../../services/status-code/status-code.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime } from 'rxjs';
import { auditTracking } from '../../../../../utils/audit-tracking';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-termination-reason',
    templateUrl: './termination-reason.component.html',
    styleUrl: './termination-reason.component.scss',
    standalone: false
})
export class TerminationReasonComponent {
  @Input() selectedEntity: any;
  @Input() disableEdit: boolean = false;
  terminationReason: string = '';
  terminationReasonList: any[] = [];
  hexLoader: boolean = false;
  editableRowIndex: number = -1;
  selectedRow: any = {};
  isConfirmPopup: string = '';
  isLoader: boolean = true;
  terminationReasonId: any[] = [];
  duplicateTerminationReason: boolean = false;
  terminationReasonCheckSubject = new Subject<any>();

  constructor(private entitiesService: EntitiesService, private showStatusCode: StatusCodeService, private toastr: ToastrService,
    private translate: TranslateService
  ) { this.terminationReasonCheck(); }

  ngOnInit() {
    this.getTerminationReason();
  }

  getTerminationReason() {
    this.entitiesService.getTerminationReason(this.selectedEntity?.entityId).subscribe({
      next: res => {
        if (res.data) {
          this.terminationReasonList.push(...res.data);
        }
        this.disableLoader();

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.viewTerminationReason');
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('auditTrail.auditTypeView'),
          description)

      }, error: err => {
        this.disableLoader();
      }
    })
  }

  saveupdateTerminateReason(termination?: any) {
    if (!this.duplicateTerminationReason) {
      this.toastr.clear();
      this.hexLoader = true;
      let body: any = {
        terminationReasonId: termination ? termination.terminationReasonId : null,
        terminationReason: termination ? termination.terminationReason?.trim() : this.terminationReason.trim(),
        entityId: this.selectedEntity?.entityId
      };
      this.entitiesService.saveUpdateTerminationReason(body).subscribe({
        next: (res) => {
          if (res?.statusCode === 'UNAUTHORIZED_USER') {
            this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
          } else {
            this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', termination ? 'common' : 'entities.terminationReasons');
          }
          this.hexLoader = false;

          if (termination) {
            let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.editTerminationReason');

            auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
              this.translate.instant('auditTrail.entities.entityDetail'),
              this.translate.instant('common.edit'),
              description)

          } else {
            let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.addTerminationReason');

            auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
              this.translate.instant('auditTrail.entities.entityDetail'),
              this.translate.instant('common.add'),
              description)
          }

          this.resetTerminationReason();
        },
        error: (error) => {
          this.hexLoader = false;
        }
      });
    }
  }

  handleConfirmation(ev: boolean) {
    if (ev) {
      if (this.isConfirmPopup === 'edit') {
        this.resetEditRow();
      } else {
        this.deleteTermination();
      }
    }
    this.isConfirmPopup = '';
    this.duplicateTerminationReason = false;
  }

  resetEditRow() {
    this.terminationReasonList[this.editableRowIndex].terminationReason = this.selectedRow.terminationReason;
    this.editableRowIndex = -1;
    this.selectedRow = {};
  }

  cancelEdit(termination: any) {
    if (this.selectedRow.terminationReason !== termination.terminationReason) {
      this.isConfirmPopup = 'edit';
    } else {
      this.editableRowIndex = -1;
      this.selectedRow = {};
    }
    this.duplicateTerminationReason = false;
  }

  deleteTermination() {
    this.toastr.clear();
    this.hexLoader = true;
    let body = {
      "terminationReasonId": this.terminationReasonId,
      "entityId": this.selectedEntity?.entityId
    }
    this.entitiesService.deleteTerminationReason(body).subscribe({
      next: res => {
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else if (res?.statusCode === 'EMPLOYEE_005_202') {
          this.toastr.error(this.translate.instant('entities.error.failedToDeleteTerminationReason'));
        } else {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', 'entities.terminationReasons');
        }
        this.hexLoader = false;

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.deleteTerminationReason');

        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('common.delete'),
          description)

        this.resetTerminationReason();
      }, error: err => {
        this.hexLoader = false;
      }
    })
  }

  statusToggleForTerminateReason(event: boolean, id: string) {
    this.toastr.clear();
    this.hexLoader = true;
    let body = {
      "id": id,
      "entityId": this.selectedEntity?.entityId,
      "status": event ? 'ACTIVE' : 'INACTIVE'
    }
    this.entitiesService.switchTerminationReason(body).subscribe({
      next: res => {
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else if (res?.statusCode === 'EMPLOYEE_005_206') {
          this.toastr.error(this.translate.instant('entities.error.failedTerminationReasonStatusChange'));
        } else {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', 'entities.terminationReasons');
        }
        this.hexLoader = false;

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.editTerminationReason');

        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('common.edit'),
          description)

        this.resetTerminationReason();
      }, error: error => {
        this.hexLoader = false;
      }
    })
  }

  deleteTerminateReason(id?: any) {
    this.isConfirmPopup = 'delete';
    if (id) {
      this.terminationReasonId = [id];
    } else {
      this.terminationReasonId = this.terminationReasonList.map(it => { return it.terminationReasonId });
    }
  }

  resetTerminationReason() {
    if (this.editableRowIndex === -1) { this.terminationReason = '' }
    this.isLoader = true;
    this.terminationReasonList = [];
    this.editableRowIndex = -1;
    this.duplicateTerminationReason = false;
    this.getTerminationReason();
  }

  editRow(index: number) {
    this.editableRowIndex = index;
    this.duplicateTerminationReason = false;
    this.selectedRow = JSON.parse(JSON.stringify(this.terminationReasonList[index]));
  }

  disableLoader() {
    if (this.hexLoader) { this.hexLoader = false; }
    if (this.isLoader) { this.isLoader = false; }
  }

  terminationReasonUniqueCheckAPI(terminationReason: any) {
    this.hexLoader = true;
    this.entitiesService.uniqueCheckTerminationReason(terminationReason, this.selectedEntity?.entityId).subscribe({
      next: res => {
        this.duplicateTerminationReason = res.data;
        this.disableLoader();
      }, error: error => {
        this.disableLoader();
      }
    })
  }

  onInputChange(event: any) {
    this.duplicateTerminationReason = false;
    this.terminationReasonCheckSubject.next(event.target.value.trim() || '');
  }

  terminationReasonCheck() {
    this.terminationReasonCheckSubject.pipe(debounceTime(500)).subscribe(keyword => {
      if (keyword != '') {
        if (keyword === this.selectedRow?.terminationReason) {
          return;
        } else {
          this.terminationReasonUniqueCheckAPI(keyword);
        }
      }
    })
  }
}
