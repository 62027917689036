import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrl: './calendar.component.scss',
    standalone: false
})
export class CalendarComponent {
  months: any[] = [];
  selectedDate: NgbDateStruct = this.calendar.getToday();
  @ViewChild('dp') datepicker!: NgbDatepicker;
  date!: { year: number; month: number };
  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;
  timestamp: any;
  dateString: any;
  @Output() emitSelectedDate = new EventEmitter();
  calendarData: any;
  @Input() effectiveDates: any;
  @Output() emitCalenderClick = new EventEmitter();
  isSelectedDateDisabled: boolean = false;
  @Input() resetDate:any;
  @Output() emitResetClick = new EventEmitter();

  constructor(private calendar: NgbCalendar) { }
  
  ngOnChanges(changes: SimpleChanges) {
    if ((!changes['resetDate']?.firstChange && this.resetDate)) {
      this.selectedDate = { year: moment(this.resetDate, 'DD MMM YYYY').year(), month: moment(this.resetDate, 'DD MMM YYYY').month() + 1, day: moment(this.resetDate, 'DD MMM YYYY').date() };
      this.resetDate = null;
      this.switchCalendar(this.selectedDate['year'], this.selectedDate['month'],'dateReset', this.selectedDate['day'])
      this.emitResetClick.emit(this.resetDate);
    }
  }

  ngOnInit() {
    this.months = moment.months();
    this.minDate = new NgbDate(this.selectedDate['year'] - 1, 1, 1);
    this.maxDate = new NgbDate(this.selectedDate['year'], 12, 31);
  }

  switchCalendar(year: number, month: number, action?: string, day?: any) {
    if (action === 'previous') {
      year = year - 1;
    } else if (action === 'next') {
      year = year + 1;
    }

    this.datepicker.navigateTo({ year: year, month: month });
    if(action === 'dateReset') {
      this.selectedDate = { year: year, month: month, day: day };
    } else {
      this.selectedDate = { year: year, month: month, day: 1 };
    }
    this.emitCalenderClick.emit(this.selectedDate);
  }

  onDateChange(date: NgbDateStruct) {
    if (date && this.isDateDisabled(date)) {
      // If the selected date is disabled, keep the current selectedDate
      this.isSelectedDateDisabled = true;
      return;
    }
    this.selectedDate = date;
    this.isSelectedDateDisabled = false;
    //this.emitCalenderClick.emit(this.selectedDate);
  }

  isMonthAvailable(monthIndex: any) {
    return (
      this.effectiveDates?.months?.map(Number).indexOf(monthIndex + 1) > -1
    );
  }

  isDateHighlighted = (date: NgbDateStruct): boolean => {
    return this.effectiveDates?.dates?.some(
      (highlightedDate: any) =>
        this.selectedDate?.year === date?.year &&
        this.selectedDate?.month === date?.month &&
        new Date(highlightedDate).getDate() === date?.day
    );
  }

  isDateDisabled = (date: NgbDateStruct): boolean => {
    return !this.isDateHighlighted(date);
  }

  getDayTemplateData = (date: NgbDateStruct) => {
    return {
      highlighted: this.isDateHighlighted(date),
      'disabled': this.isDateDisabled(date),
      'selected': this.isDateSelected(date)
    }
  }

  isDateSelected  = (date: NgbDateStruct) => {
    return (date && !this.isDateDisabled(date) && this.selectedDate?.year === date.year && this.selectedDate?.month === date.month && this.selectedDate?.day === date.day)
  }
}
