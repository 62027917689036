import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserPreference } from '../../../core/constants/endpoint';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {
  userSettingsUpdatedSub = new BehaviorSubject<any>(false);

  constructor(private http: HttpClient) { }

  getUserProfileInfo(): Observable<any> {
    return this.http.get(environment.url + UserPreference.getUserProfileInfo);
  }

  getAllLanguages(): Observable<any> {
    return this.http.get(environment.url + UserPreference.getAllLanguages);
  }

  getTimezones(): Observable<any> {
    return this.http.get(environment.url + UserPreference.getTimezones);
  }

  getConfigureNotificationPreferences(): Observable<any> {
    return this.http.get(environment.url + UserPreference.getConfigureNotificationPreferences);
  }

  saveUserPreferenceDetails(body: any): Observable<any> {
    return this.http.post(environment.url + UserPreference.saveUserPreferenceDetails, body);
  }
}
