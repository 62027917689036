<header *ngIf="!url?.includes('activate-user') && !url?.includes('/login') &&
  !url?.includes('/logout') && !url?.includes('/preferences')">
  <!-- logo -->
  <img *ngIf="!userSettings?.logoContent" height="80px" src="./assets/images/logo.png" alt="logo" />

  <!-- client logo -->
  <ng-container *ngIf="userSettings?.logoContent">
    <img [src]="clientLogo" alt="client-logo" height="80px" style="max-width: 200px;" />
    <p class="info mt-1">
      <span translate>workspace.poweredBy </span>
      <strong class="fnt-md">
        <span translate> workspace.tmf-group</span>
      </strong>
    </p>
  </ng-container>

  <!-- nav info -->
  <div class="nav-wrapper" [ngClass]="{'rounded-0' : showModules}">
    <i class="icon-home" [ngClass]="{'disabled': disabledHome()}" [routerLink]="['/my-workspace']"></i>
    <div class="module-name">{{getFeatureLocalization(currentModule.replace('-', ' ').split('?')[0])}}</div>

    <i class="icon-domino" *ngIf="!showModules"
      [ngClass]="{'disabled' : !getWorkflowAccess() && !getAdminAccess() && !getFunctionality('Entities') && !getFunctionality('Employees')}"
      (click)="(getAdminAccess() || getWorkflowAccess() || getFunctionality('Entities') || getFunctionality('Employees')) && showModules=true"></i>

    <i class="icon-close" *ngIf="showModules" (click)="showModules=false"></i>

    <!-- modules list -->
    <ul class="module-list" *ngIf="showModules">
      <li [ngClass]="{'active' : currentModule === 'entities'}" *ngIf="getFunctionality('Entities')"
        (click)="handleAdminRouting('entities')" translate>
        <i class="icon-business"></i> common.entities
      </li>

      <li [ngClass]="{'active' : currentModule === 'employees'}" *ngIf="getFunctionality('Employees') && false"
        (click)="handleAdminRouting('employees')" translate>
        <img class="employee-img" src="./assets/images/icon-employees.png" alt="employees" />
        <img class="employee-img-active" src="./assets/images/employees-active.png" alt="employees-active" />
        common.employees
      </li>

      <li [ngClass]="{'active' : currentModule === 'workflow'}" *ngIf="getWorkflowAccess()"
        (click)="handleClientRouting('workflow')" translate>
        <i class="icon-workflow"></i> workflow.workflow
      </li>

      <li *ngIf="false" [ngClass]="{'active' : currentModule === 'planner'}" translate>
        <i class="icon-calendar"></i> common.planner
      </li>

      <li *ngIf="false" [ngClass]="{'active' : currentModule === 'insights'}" routerLink="/insights/workflow" translate>
        <i class="icon-statistics"></i> common.insights
      </li>

      <li [ngClass]="{'active' : currentModule === 'admin'}" *ngIf="getAdminAccess()"
        (click)="handleAdminRouting('admin')" translate>
        <i class="icon-admin"></i> header.admin
      </li>
    </ul>
  </div>

  <!-- greet text + user name -->
  <div class="greeting" *ngIf="getGreetText() && !showModules">
    <span>{{getGreetText() | translate}}</span>&nbsp;<strong class="textoverflow" style="max-width: 40ch;"
      [title]="userName">{{userName}}</strong>
  </div>

  <!-- selected client for tmf user -->
  <div class="selected-client info gray-bg" *ngIf="(userDetails?.userType === 'tmf_user' && url === '/my-workspace') ||
  ((userDetails?.userType === 'tmf_admin' || userDetails?.userType === 'super_admin') && url === '/workflow')">

    <strong class="fnt-md" translate>common.client</strong>&nbsp;
    <span class="textoverflow d-inline-block" [title]="userDetails?.clientName">{{userDetails?.clientName}}</span>
    <i class="icon-pencil ms-2 cp" *ngIf="userDetails?.clientsCount > 1" (click)="tmfUserClientsSwitch()"></i>
  </div>

  <!-- Reset Preferences  -->
  <a class="route-link cp reset-preferences" [ngClass]="{'t10' : (userDetails?.userType === 'client_user')}"
    (click)="resetUserPreference()"
    *ngIf="((url === '/my-workspace' || url === '/') && (userDetails?.userType === 'tmf_user' ||  userDetails?.userType === 'client_user'))"
    translate>
    <img height="14px" style="vertical-align: text-top;" src="./assets/images/refresh.png" alt="logo" />
    workspace.resetPreferences </a>

  <!-- menu links -->
  <ul class="menu-links">
    <li class="profile" (click)="userPreference = true">
      <i *ngIf="!profilePic" class="icon-user-profile">
        <span class="path1"></span> <span class="path2"></span>
      </i>
      <img class="icon-user-profile" *ngIf="profilePic" [src]="profilePic" alt="profile-pic" />
    </li>

    <li routerLink="/notifications"
      *ngIf="checkActionsForNotification() && userDetails?.userType !== 'super_admin' && userDetails?.userType !== 'tmf_admin'"
      [ngClass]="{'active': notificationCount > 0}">
      <i class="icon-flash"></i>
      <i class="icon-flash-active"></i>
    </li>

    <li><i class="icon-gear" (click)="userPreference = true"></i></li>

    <li class="logout" (click)="onLogout()">
      <i class="icon-logout"></i>
      <i class="icon-logout-hover"></i>
    </li>
  </ul>
</header>

<app-user-preference *ngIf="userPreference" (closeUserPreference)="userPreference = false"></app-user-preference>