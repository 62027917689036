<div *ngIf="userDetails && (userDetails?.status === 'InActive' || userDetails?.isDeleted === 1)">
  <img class="m-3" height="80px" src="./assets/images/logo.png" alt="logo" />

  <div class="card sm">
    <div class="text-center title" *ngIf="!userDetails?.userDisabled" translate> Unauthorized Access </div>
    <div class="text-center title" *ngIf="userDetails?.userDisabled" translate> Unauthorized Access </div>

    <button class="btn-primary mx-auto mt-3" style="width: fit-content;" (click)="onLogout()">Logout</button>
  </div>
</div>

<app-hex-loader *ngIf="hexLoader"></app-hex-loader>