export const environment = {
    production: false,
    env: 'development',
    login_url: 'https://api.dev.cobalt.tmf-group.com/cms/',
    url: 'https://api.dev.cobalt.tmf-group.com/cms/api/v1/',
    emp_url: 'https://api.dev.cobalt.tmf-group.com/emp/api/v1/',
    workflow_url: 'https://api.dev.cobalt.tmf-group.com/core/api/v1/',
    upload_url: 'https://api.dev.cobalt.tmf-group.com/upload/api/v1/',
    collect_url: 'https://api.dev.cobalt.tmf-group.com/collect-import/api/v1/',
    notification_url: 'https://api.dev.cobalt.tmf-group.com/nms/api/v1/'
}