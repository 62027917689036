<div class="services h100">
  <div class="title mb-2" translate>entities.services</div>

  <section>
    <ul class="section-header" [ngClass]="{'disabled no-opacity' : disableEdit ||
             !getActions('Create Entity')}">
      <ng-container *ngIf="!isLoader">
        <li *ngFor="let serviceName of listOfServiceLine; let i=index"
          (click)="serviceName.isServiceEnabled = !serviceName.isServiceEnabled;checkFormChanges(serviceName)">
          <i class="icon-solid-minus" *ngIf="serviceName.isServiceEnabled">
            <span class="path1"></span> <span class="path2"></span>
          </i>
          <i class="icon-create" *ngIf="!serviceName.isServiceEnabled"></i>
          <span [ngClass]="{'fnt-md':serviceName.isServiceEnabled === true}">
            {{serviceName.serviceLineName}}</span>
        </li>
      </ng-container>
      <ngx-skeleton-loader [count]="1" *ngIf="isLoader"></ngx-skeleton-loader>
    </ul>

    <div class="section-body">
      <div class="h100" [ngClass]="{'disabled no-opacity' : disableEdit}">
        <div class="no-services flex-center flex-column" *ngIf="!hexLoader && !noL2Services()">
          <h5 style="font-weight: 300;" translate> <strong class="fnt-md">0&nbsp;</strong>entities.services</h5>
          <p><span translate>entities.serviceText1</span> &nbsp;<i class="icon-create"></i> &nbsp; <span
              translate>entities.serviceText2</span></p>
        </div>

        <div class="list-section" *ngFor="let serviceName of l2Services">
          <ng-container *ngIf="isServiceLineSelected(serviceName.serviceLineName)">
            <div class="list-header">{{serviceName.serviceLineName}}</div>
            <ul *ngFor="let service of serviceName.l1ServiceList">
              <div class="sub-header"> {{service.l1ServiceName}}&nbsp;({{service.count}})</div>
              <li *ngFor="let servicename of service.l2ServiceList" [ngClass]="{'disabled' : !servicename.isActive }">
                {{servicename.l2ServiceName}}</li>
            </ul>
          </ng-container>
        </div>
      </div>
    </div>
  </section>
</div>

<app-hex-loader *ngIf="hexLoader"></app-hex-loader>