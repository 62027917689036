<div class="payroll-basic-info h100">
    <div class="title mb-2" translate>entities.payrollBasicinfo</div>
    <form class="form-wrapper d-flex mt-0 h100" [formGroup]="entitiesPayrollBasicForm">
        <div [ngClass]="{'disabled no-opacity' : disableEdit || !getActions('Create Entity')}"
            class="d-flex w100 h100">
            <div class="form">
                <div class="form-group frequencyEntity">
                    <label translate for="payrollFrequencynameselect"><span class="asterik">*</span>
                        entities.frequency</label>
                    <ng-select class="full-border" bindLabel="name" bindValue="payrollFrequencyId"
                        [placeholder]="'common.select' | translate" [searchable]="true"
                        [notFoundText]="'common.nodata' | translate" [clearable]="false"
                        [items]="entityPayrollFrequencyList" (change)="payrollFrequencyIdChanged($event)"
                        (load)="payrollFrequencyIdChanged($event)" formControlName="payrollFrequencyId"
                        id='payrollFrequencynameselect' [ngClass]="{'error-select': entitiesPayrollBasicForm.get('payrollFrequencyId')?.errors && 
                    entitiesPayrollBasicForm.get('payrollFrequencyId')?.touched}"></ng-select>
                    <div class="error"
                        *ngIf="entitiesPayrollBasicForm.controls['payrollFrequencyId'].errors && entitiesPayrollBasicForm.controls['payrollFrequencyId'].touched"
                        translate>common.error.required</div>
                </div>
                <div class="d-flex paydaydiv">
                    <div class="form-group full-border">
                        <div class="d-flex justify-content-between">
                            <div class="paydayEntity">
                                <label translate><span class="asterik">*</span> entities.payDay1</label>
                                <ng-select class="full-border" bindLabel="name" bindValue="paydayFrequencyId"
                                    [placeholder]="'common.select' | translate" [searchable]="true" [clearable]="false"
                                    [items]="entityPayDayFrequencyList" formControlName="payDay1FrequencyId"
                                    [notFoundText]="'common.nodata' | translate" [ngClass]="{'error-select': entitiesPayrollBasicForm.get('payDay1FrequencyId')?.errors && 
                    entitiesPayrollBasicForm.get('payDay1FrequencyId')?.touched}"
                                    (change)="payDayRange(entitiesPayrollBasicForm.value.payrollFrequencyId,
                                    entitiesPayrollBasicForm.value.payDay1FrequencyId,entitiesPayrollBasicForm.value.payDay1Value,payDay1name, 'onChange')"></ng-select>
                            </div>
                            <div class="payxdayEntity d-flex justify-content-between">
                                <label style="white-space: nowrap;" translate>entities.xDaypayDay</label>
                                <ng-select bindLabel="payDay1Value" bindValue="payDay1Value" [searchable]="true"
                                    [placeholder]="'common.select' | translate" [clearable]="false"
                                    [items]="entityPayDay1Range" [notFoundText]="'common.nodata' | translate"
                                    [notFoundText]="'common.nodata' | translate" formControlName="payDay1Value"
                                    [ngClass]="{'error-select': entitiesPayrollBasicForm.get('payDay1Value')?.errors && 
                    entitiesPayrollBasicForm.get('payDay1Value')?.touched}"></ng-select>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="paydayEntity">
                                <div class="error"
                                    *ngIf="entitiesPayrollBasicForm.controls['payDay1FrequencyId'].errors && entitiesPayrollBasicForm.controls['payDay1FrequencyId'].touched"
                                    translate> common.error.required</div>
                            </div>
                            <div class="payxdayEntity">
                                <div class="error"
                                    *ngIf="entitiesPayrollBasicForm.controls['payDay1Value'].errors && entitiesPayrollBasicForm.controls['payDay1Value'].touched"
                                    translate> common.error.required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group full-border" *ngIf="payrollFrequencynameChanged === 'Semi-Monthly'">
                        <div class="d-flex justify-content-between">
                            <div class="paydayEntity">
                                <label translate><span class="asterik">*</span> entities.payDay2</label>
                                <ng-select class="full-border" bindLabel="name" bindValue="paydayFrequencyId"
                                    [placeholder]="'common.select' | translate" [searchable]="true" [clearable]="false"
                                    [items]="entityPayDayFrequencyList" formControlName="payDay2FrequencyId"
                                    [notFoundText]="'common.nodata' | translate" [ngClass]="{'error-select': entitiesPayrollBasicForm.get('payDay2FrequencyId')?.errors && 
                    entitiesPayrollBasicForm.get('payDay2FrequencyId')?.touched}"
                                    (change)="payDayRange(entitiesPayrollBasicForm.value.payrollFrequencyId,entitiesPayrollBasicForm.value.payDay2FrequencyId,entitiesPayrollBasicForm.value.payDay2Value,payDay2name, 'onChange')"></ng-select>
                            </div>
                            <div class="payxdayEntity d-flex justify-content-between">
                                <label style="white-space: nowrap;" translate>entities.xDaypayDay</label>
                                <ng-select bindLabel="payDay2Value" bindValue="payDay2Value" [searchable]="true"
                                    [placeholder]="'common.select' | translate" [clearable]="false"
                                    [items]="entityPayDay2Range" [notFoundText]="'common.nodata' | translate"
                                    formControlName="payDay2Value" [ngClass]="{'error-select': entitiesPayrollBasicForm.get('payDay2Value')?.errors && 
                    entitiesPayrollBasicForm.get('payDay2Value')?.touched}"></ng-select>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="paydayEntity">
                                <div class="error"
                                    *ngIf="entitiesPayrollBasicForm.controls['payDay2FrequencyId'].errors && entitiesPayrollBasicForm.controls['payDay2FrequencyId'].touched"
                                    translate> common.error.required</div>
                            </div>
                            <div class="payxdayEntity">
                                <div class="error"
                                    *ngIf="entitiesPayrollBasicForm.controls['payDay2Value'].errors && entitiesPayrollBasicForm.controls['payDay2Value'].touched"
                                    translate> common.error.required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group full-border execRule">
                    <label translate><span class="asterik">*</span> entities.execRule</label>
                    <ng-select [items]="entityExecRules" bindLabel="name" bindValue="payDayExecutionRuleId"
                        [placeholder]="'common.select' | translate" [searchable]="true" [clearable]="false"
                        class="full-border" [notFoundText]="'common.nodata' | translate"
                        formControlName="payDayExecutionRuleId" [ngClass]="{'error-select': entitiesPayrollBasicForm.get('payDayExecutionRuleId')?.errors && 
                        entitiesPayrollBasicForm.get('payDayExecutionRuleId')?.touched}"></ng-select>
                    <div class="error"
                        *ngIf="entitiesPayrollBasicForm.controls['payDayExecutionRuleId'].errors && entitiesPayrollBasicForm.controls['payDayExecutionRuleId'].touched"
                        translate>common.error.required</div>
                </div>
                <div class="form-group full-border taxYear">
                    <label translate><span class="asterik">*</span> entities.taxYear</label>
                    <div class="d-flex justify-content-between">
                        <ng-select bindLabel="name" bindValue="month" class="position-top" id="changedMonth"
                            [searchable]="true" [placeholder]="'common.select' | translate" [clearable]="false"
                            [items]="entityMonth" [notFoundText]="'common.nodata' | translate"
                            (change)="daysFormonth(entitiesPayrollBasicForm.value.taxYearMonth, 'onChange')"
                            formControlName="taxYearMonth" [ngClass]="{'error-select': entitiesPayrollBasicForm.get('taxYearMonth')?.errors && 
                            entitiesPayrollBasicForm.get('taxYearMonth')?.touched}"></ng-select>

                        <ng-select bindLabel="name" bindValue="name" class="position-top" [searchable]="true"
                            [clearable]="false" [placeholder]="'common.select' | translate"
                            [notFoundText]="'common.nodata' | translate" [items]="entityDaysFormonth"
                            formControlName="taxYearDate" [ngClass]="{'error-select': entitiesPayrollBasicForm.get('taxYearDate')?.errors && 
                            entitiesPayrollBasicForm.get('taxYearDate')?.touched}"></ng-select>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div class="paydayTaxmonth">
                            <div class="error"
                                *ngIf="entitiesPayrollBasicForm.controls['taxYearMonth'].errors && entitiesPayrollBasicForm.controls['taxYearMonth'].touched"
                                translate>common.error.required</div>
                        </div>
                        <div class="payxdayTaxdate">
                            <div class="error"
                                *ngIf="entitiesPayrollBasicForm.controls['taxYearDate'].errors && entitiesPayrollBasicForm.controls['taxYearDate'].touched"
                                translate> common.error.required</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>