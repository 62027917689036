<div class="pay-group p-2 px-3">
    <div class="add-paygroup" style="height: 65px;" [ngClass]="{'disabled no-opacity' : disableEdit}">
        <div class="d-flex align-items-end">
            <div class="w50">
                <div class="char-info text-end">
                    <strong>{{payGroup.length}}</strong> / 256
                    <span translate>common.chars</span>
                </div>
                <input type="text" [placeholder]="'entities.general.enterHere' | translate" [title]="payGroup"
                    maxlength="256" [(ngModel)]="payGroup" (input)="onInputChange($event)"
                    [ngClass]="{'error': (duplicatePayGroup && editableRowIndex === -1)}" />
            </div>
            <button class="btn-link teal" translate (click)="saveUpdatePayGroup()"
                [ngClass]="{'disabled': payGroup.trim()==='' || duplicatePayGroup}">
                <i class="icon-create"></i>common.add
            </button>
        </div>
        <span [ngStyle]="{'visibility': duplicatePayGroup && editableRowIndex === -1 ? 'visible' :'hidden'}"
            class="error" translate>entities.payGroups.error.payGroupExist</span>
    </div>

    <div>
        <div class="title"><span translate>clients.added</span> ({{payGroupList.length}})</div>
        <div class="table-wrapper mt-2" style="height: calc(100vh - 390px);overflow-y: auto;">
            <table aria-describedby="pay-group" [ngClass]="{'loading':isLoader}">
                <thead>
                    <tr>
                        <th>
                            <span class="textoverflow" [title]="'entities.payGroup' | translate"
                                translate>entities.payGroup</span>
                            <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
                        </th>
                        <th style="width: 150px;">
                            <span>&nbsp;</span>
                            <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="!isLoader">
                    @if(payGroupList.length > 0 )
                    {
                    <tr *ngFor="let paygroup of payGroupList; let i = index"
                        [ngClass]="{'edit-row': editableRowIndex ===i}">
                        <ng-container>
                            <td [title]="paygroup.payGroupName">
                                <div class="input-container" *ngIf="editableRowIndex ===i;else payGroupName"><input
                                        name="payGroupName" required maxlength="256" [(ngModel)]="paygroup.payGroupName"
                                        (input)="onInputChange($event)" data-trigger="hover"
                                        [ngbTooltip]="paygroup.payGroupName === '' ? ('common.error.required' | translate): duplicatePayGroup && editableRowIndex  === i ? ('entities.payGroups.error.payGroupExist' | translate) : ''"
                                        [ngClass]="{'border-error':paygroup.payGroupName.trim() === '' || (duplicatePayGroup && editableRowIndex === i)}" />
                                </div>
                                <ng-template #payGroupName>
                                    <div class="textoverflow">{{paygroup.payGroupName}}</div>
                                </ng-template>
                            </td>
                            <td class="icons" style="width: 150px;">
                                <div class="d-flex align-items-center" style="justify-content: flex-end;"
                                    [ngClass]="{'disabled no-opacity' : disableEdit}">
                                    <div class="d-flex" *ngIf="editableRowIndex ===i">
                                        <img style="height: 13px;" class="cp"
                                            [ngClass]="{'disabled':(selectedRow.payGroupName === paygroup.payGroupName || paygroup.payGroupName.trim() === '' || duplicatePayGroup)}"
                                            src="./assets/images/tick-active.png" alt="tick"
                                            (click)="saveUpdatePayGroup(paygroup)" />
                                        <i style="padding-left: 10px;" class="icon-circle-close cp"
                                            (click)="cancelEdit(paygroup)"></i>
                                    </div>
                                    <i style="font-size: 11px;" class="icon-pencil cp" *ngIf="editableRowIndex !==i"
                                        (click)="editRow(i)"></i>
                                    <app-toggle style="margin-left: 20px; margin-top: -10px;"
                                        [checked]="paygroup.statusName==='Active'? true:false"
                                        (emitToggle)="statusToggleForPayGroup($event,paygroup.payGroupId)"
                                        [ngClass]="{'disabled':editableRowIndex ===i}"></app-toggle>
                                    <i style="margin-left: 20px" class="icon-bin cp red"
                                        [ngClass]="{'disabled':editableRowIndex ===i}"
                                        (click)="inlineDelete(paygroup.payGroupId)"></i>
                                </div>
                            </td>
                        </ng-container>
                    </tr>
                    }
                    @else if(payGroupList.length === 0) {
                    <tr class="h100">
                        <td colspan="3" class="border-0">
                            <div class="no-data" translate>common.nodata</div>
                        </td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>
</div>

<app-hex-loader *ngIf="hexLoader"></app-hex-loader>

<div class="restrict-overlay" style="z-index: 2;left: -10px;top: -45px;" *ngIf="editableRowIndex > -1 "></div>

<!-- confirmation-popup-delete -->
<app-confirmation-popup *ngIf="isConfirmPopup !==''" [innerModal]="true" (emitEvent)="handleConfirmation($event)"
    [description]="isConfirmPopup ==='delete' ? 'common.confirmDelete' : 'common.cancelMsg' "></app-confirmation-popup>
