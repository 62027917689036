<div class="schedule h100">
    <div class="title mb-2" translate>entities.schedule.schedule</div>

    <div class="form schedule-form">
        <div class="form-group me-2">
            <label translate for=""><span class="asterik">*</span>
                workflow.actions.activityType</label>
            <ng-multiselect-dropdown focusOnClick class="full-border single-select sm" [settings]="activityTypeSettings"
                [placeholder]="'entities.schedule.selectActivityType' | translate" [data]="activityTypeList"
                [(ngModel)]="activityTypeDetails" (onSelect)="fetchServiceName()"
                (onDeSelect)="onDeselectActivityType()"></ng-multiselect-dropdown>
        </div>

        <div class="form-group">
            <label translate for=""><span class="asterik">*</span>
                entities.schedule.serviceName</label>
            <ng-multiselect-dropdown focusOnClick class="full-border single-select sm" [settings]="serviceNameSettings"
                [placeholder]="'entities.schedule.selectServiceName' | translate" [data]="serviceNameList"
                [(ngModel)]="serviceNameDetails" (onSelect)="getScheduleDetails()"
                (onDeSelect)="onDeselectServiceName()"></ng-multiselect-dropdown>
        </div>

        <button *ngIf="getActions('Manage Schedule')" class="btn border-0"
            [ngClass]="{'disabled' : !saveSchedule || disableEdit}" (click)="saveScheduleConfigurations();save=true"
            translate>
            <img src="./assets/images/tick-active.png" alt="apply" />common.save
        </button>
    </div>

    <div class="table-wrapper mt-2">
        <table aria-describedby="schedule" *ngIf="!message" [ngClass]="{'loading':isLoader}">
            <thead>
                <tr>
                    <th>
                        <span class="textoverflow" [title]="'entities.schedule.scheduleHeader' | translate"
                            translate>entities.schedule.scheduleHeader</span>
                        <ngx-skeleton-loader [count]="5" *ngIf="isLoader"></ngx-skeleton-loader>
                    </th>
                    <th>
                        <span class="textoverflow" [title]="'entities.schedule.headerName' | translate"
                            translate>entities.schedule.headerName</span>
                        <ngx-skeleton-loader [count]="5" *ngIf="isLoader"></ngx-skeleton-loader>
                    </th>
                    <th>
                        <span class="textoverflow" [title]="'entities.schedule.headerMapping' | translate"
                            translate>entities.schedule.headerMapping</span>
                        <ngx-skeleton-loader [count]="5" *ngIf="isLoader"></ngx-skeleton-loader>
                    </th>
                    <th class="text-center" *ngIf="false">
                        <span class="textoverflow" style="position: relative;right: 4px;"
                            [title]="'entities.schedule.visible' | translate" translate>entities.schedule.visible</span>
                        <ngx-skeleton-loader [count]="5" *ngIf="isLoader"></ngx-skeleton-loader>
                    </th>
                </tr>
            </thead>
            <tbody class="h100" *ngIf="!isLoader">
                @if(scheduleData?.length > 0 )
                {
                <tr *ngFor="let schedule of scheduleData; let i = index">
                    <td class="textoverflow">{{schedule.headerName}}</td>
                    <td
                        [ngClass]="{'disable' : disableEdit  || (!getActions('Manage Schedule') && !getActions('Create Entity'))}">
                        <div class="input-container">
                            <input type="text" style="flex: 1;" [value]="schedule.aliasHeaderName"
                                [placeholder]="'entities.general.enterHere' | translate"
                                [(ngModel)]="schedule.aliasHeaderName"
                                (ngModelChange)="checkModules($event, i, schedule, 'aliasHeaderName')"
                                maxlength="100" />
                            <div class="char-info" style="width: 70px;position: relative;bottom: -6px;">
                                {{schedule.aliasHeaderName?.length ?? 0}}/100
                            </div>
                        </div>
                    </td>
                    <td
                        [ngClass]="{'disable' : disableEdit  || (!getActions('Manage Schedule') && !getActions('Create Entity'))}">
                        <ng-multiselect-dropdown focusOnClick class="full-border single-select sm mappingList"
                            [ngClass]="{'adjust-dropdown': scheduleData?.length > 4 && (i >= scheduleData?.length - 3)}"
                            [settings]="stageMappingListSettings" [placeholder]="'common.select' | translate" [data]="
                            stageMappingList[i]" [(ngModel)]="stageMappingTypeData[i]"
                            (onSelect)="onSelectStageMapping($event,i);checkModules($event,i, schedule, 'stageMappingSelect')"
                            (onDeSelect)=" onDeselectStageMapping($event,i); checkModules($event,i,
                            schedule, 'stageMappingUnSelect' )"></ng-multiselect-dropdown>
                    </td>
                    <td class="center-align" *ngIf="false"
                        [ngClass]="{'disable' : disableEdit  || (!getActions('Manage Schedule') && !getActions('Create Entity'))}">
                        <app-toggle [checked]="schedule.isDisplayed !== 0"
                            (emitToggle)="emitToggle($event, schedule)"></app-toggle>
                    </td>
                </tr>
                }
                @else if(scheduleData?.length === 0) {
                <tr class=" h100">
                    <td colspan="3" class="border-0">
                        <div class="no-data" translate>common.nodata</div>
                    </td>
                </tr>
                }
            </tbody>
        </table>
        <div *ngIf="message" class="no-data" translate>entities.schedule.selectActivityTypeToConfigureSchedule</div>
    </div>
</div>

<!-- hex loader -->
<app-hex-loader *ngIf="hexLoader"></app-hex-loader>