import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  constructor() { }

  encrypt(value: string): string {
    let secretKey: any = localStorage.getItem('principalId');
    return CryptoJS.AES.encrypt(value, secretKey).toString();
  }

  decrypt(textToDecrypt: string, key?: any) {
    let secretKey: any = key ? key : localStorage.getItem('principalId');
    return CryptoJS.AES.decrypt(textToDecrypt, secretKey).toString(CryptoJS.enc.Utf8);
  }
}
