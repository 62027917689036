<div class="approvals h100">
    <div class="title mb-2" translate>entities.approvals.approvals</div>
    <ul class="approvals-list">
        <li class="gray-bg approval-item" *ngFor="let item of entityServiceLineList; index as i;"
            (click)="selectRow(item, i)" [ngClass]="{'active' : i === selectedIndex}">
            <div class="service-line">{{item.serviceLineName}}</div>
            <div class="form-group" [ngClass]="{'disable': disableEdit || disableApprovals()}">
                <span class="fnt-light pe-2" translate>entities.approvals.level1</span>
                <ng-multiselect-dropdown focusOnClick class="full-border single-select sm" [settings]="settings"
                    [placeholder]="'common.select' | translate" [data]="rolesList" [(ngModel)]="L1RoleOne[i]"
                    (onSelect)="onSelectItem($event,0,'serviceLine', i)"
                    (onDeSelect)="onDeselect(0,'serviceLine',i)"></ng-multiselect-dropdown>
            </div>
            <div class="form-group" [ngClass]="{'disable': disableEdit || disableApprovals()}">
                <span class="fnt-light pe-2" translate>entities.approvals.level2</span>
                <ng-multiselect-dropdown focusOnClick class="full-border single-select sm" [settings]="settings"
                    [placeholder]="'common.select' | translate" [data]="rolesList" [(ngModel)]="L1RoleTwo[i]"
                    (onSelect)="onSelectItem($event,1,'serviceLine', i)"
                    (onDeSelect)="onDeselect(1,'serviceLine',i)"></ng-multiselect-dropdown>
            </div>
            <ng-container>
                <div class="form-group" [ngStyle]="{'justify-content': showL2Approvals ? 'center' : (i === selectedIndex ? 'space-between' : 'end')
              }">
                    <span style="max-width: 120px;font-size: 11px;" *ngIf="!showL2Approvals && i === selectedIndex">
                        {{ 'entities.approvals.specialAccess' | translate: { count: approvalCount ? approvalCount : 0 }
                        }}
                    </span>
                    <button class="btn-link"
                        *ngIf="!showL2Approvals && getActions('Manage Approvals') && !disableEdit;else viewApprovals"
                        [ngClass]="{'disabled no-opacity':i !== selectedIndex || !getapprovalCount}"
                        (click)="showL2ApprovalsList(item)" translate> <i
                            class="icon-create"></i>entities.approvals.addApprovals</button>
                    <ng-template #viewApprovals>
                        <button class="btn-link"
                            *ngIf="!showL2Approvals && getActions('View Approvals') && getDisableEdit()"
                            [ngClass]="{'disabled no-opacity':i !== selectedIndex || !getapprovalCount}"
                            (click)="showL2ApprovalsList(item)" translate>
                            <i class="icon-create"></i>entities.approvals.viewApprovals</button>
                    </ng-template>
                    <button *ngIf="showL2Approvals && getActions('Manage Approvals') && !disableEdit"
                        class="btn border-0" (click)="saveApprovals()" translate
                        [ngClass]="{'disabled ': !isSaveApprovals}">
                        <img src="./assets/images/tick-active.png" alt="apply" />common.apply
                    </button>
                    <button *ngIf="showL2Approvals" (click)="closeL2services()" class="btn border-0" translate><i
                            class="icon-circle-close cp"></i>common.close
                    </button>
                </div>
            </ng-container>
        </li>

    </ul>

    <div style="height: calc(100% - 150px);"
        *ngIf="showL2Approvals && uniqueL2Approvals?.length  === 0 && entityL2Services?.length === 0 && !hexLoader">
        <div class="no-data" translate>entities.approvals.noApprovalsFound
        </div>
    </div>

    <div class="table-wrapper"
        *ngIf="showL2Approvals && (uniqueL2Approvals?.length > 0 || entityL2Services?.length > 0)"
        style="height: calc(100% - 85px);">
        <table aria-describedby="approval-table">
            <thead>
                <tr>
                    <th class="cp" [title]="'entities.approvals.contractedServiceProcess' | translate">
                        <span class="textoverflow" translate>entities.approvals.contractedServiceProcess</span>
                    </th>
                    <th class="cp" [title]="'entities.approvals.level1' | translate">
                        <span class="textoverflow" translate>
                            entities.approvals.level1</span>
                    </th>
                    <th class="cp" [title]="'entities.approvals.level2' | translate">
                        <span class="textoverflow" translate>
                            entities.approvals.level2</span>
                    </th>
                    <th class="cp" [title]="'entities.approvals.level3' | translate">
                        <span class="textoverflow" translate>
                            entities.approvals.level3</span>
                    </th>
                    <th style="width: 60px;">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="uniqueL2Approvals?.length > 0">
                    <tr *ngFor="let item of uniqueL2Approvals; let i = index">
                        <td class="textoverflow">
                            {{item.l2ServiceName}}
                        </td>
                        <td (click)="levelIndex = 0"
                            [ngClass]="{'disabled no-opacity' : disableEdit || (!getActions('Manage Approvals') && !getActions('Create Entity'))}">
                            <ng-multiselect-dropdown focusOnClick class="full-border single-select sm service-process"
                                [ngClass]="{ 'adjust-dropdown': applyAdjustDropdown(i)}" [settings]="settings"
                                [placeholder]="'common.select' | translate" [data]="rolesList"
                                [(ngModel)]="L2RoleOne[selectedIndex][i]" (onSelect)="onSelectItem($event,0,'l2', i)"
                                (onDeSelect)="onDeselect(0,'l2',i)"></ng-multiselect-dropdown>
                        </td>
                        <td (click)="levelIndex = 1"
                            [ngClass]="{'disabled no-opacity' : disableEdit || (!getActions('Manage Approvals') && !getActions('Create Entity'))}">
                            <ng-multiselect-dropdown focusOnClick class="full-border single-select sm service-process"
                                [ngClass]="{'adjust-dropdown': applyAdjustDropdown(i)}" [settings]="settings"
                                [placeholder]="'common.select' | translate" [data]="rolesList"
                                [(ngModel)]="L2RoleTwo[selectedIndex][i]" (onSelect)="onSelectItem($event,1,'l2', i)"
                                (onDeSelect)="onDeselect(1,'l2',i)"></ng-multiselect-dropdown>
                        </td>
                        <td (click)="levelIndex = 2"
                            [ngClass]="{'disabled no-opacity' : disableEdit || (!getActions('Manage Approvals') && !getActions('Create Entity'))}">
                            <ng-multiselect-dropdown focusOnClick class="full-border single-select sm service-process"
                                [ngClass]="{'adjust-dropdown': applyAdjustDropdown(i)}" [settings]="settings"
                                [placeholder]="'common.select' | translate" [data]="rolesList"
                                [(ngModel)]="L2RoleThree[selectedIndex][i]" (onSelect)="onSelectItem($event,2,'l2', i)"
                                (onDeSelect)="onDeselect(2,'l2',i)"></ng-multiselect-dropdown>
                        </td>
                        <td style="width: 78px;"
                            [ngClass]="{'disabled no-opacity' : disableEdit || (!getActions('Manage Approvals') && !getActions('Create Entity'))}">
                            <button [title]="'common.resetFilter'| translate" (click)="removeAccess(i)"
                                *ngIf="L2RoleOne[selectedIndex][i]?.length || L2RoleTwo[selectedIndex][i]?.length || L2RoleThree[selectedIndex][i]?.length">
                                <img src="./assets/images/refresh.png" alt="reset">
                            </button>
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="entityL2Services?.length > 0">
                    <tr *ngFor="let item of entityL2Services; let i = index">
                        <td class="textoverflow">
                            {{item.serviceName}}
                        </td>
                        <td (click)="levelIndex = 1"
                            [ngClass]="{'disabled no-opacity' : disableEdit || (!getActions('Manage Approvals') && !getActions('Create Entity'))}">
                            <ng-multiselect-dropdown focusOnClick class="full-border single-select sm service-process"
                                [ngClass]="{'adjust-dropdown': applyAdjustDropdown(i)}" [settings]="settings"
                                [placeholder]="'common.select' | translate" [data]="rolesList"
                                [(ngModel)]="L2RoleOne[selectedIndex][i]" (onSelect)="onSelectItem($event,0,'l2', i)"
                                (onDeSelect)="onDeselect(0,'l2',i)"></ng-multiselect-dropdown>
                        </td>
                        <td (click)="levelIndex = 2"
                            [ngClass]="{'disabled no-opacity' : disableEdit || (!getActions('Manage Approvals') && !getActions('Create Entity'))}">
                            <ng-multiselect-dropdown focusOnClick class="full-border single-select sm service-process"
                                [ngClass]="{'adjust-dropdown': applyAdjustDropdown(i)}" [settings]="settings"
                                [placeholder]="'common.select' | translate" [data]="rolesList"
                                [(ngModel)]="L2RoleTwo[selectedIndex][i]" (onSelect)="onSelectItem($event,1,'l2', i)"
                                (onDeSelect)="onDeselect(1,'l2',i)"></ng-multiselect-dropdown>
                        </td>
                        <td (click)="levelIndex = 3"
                            [ngClass]="{'disabled no-opacity' : disableEdit || (!getActions('Manage Approvals') && !getActions('Create Entity'))}">
                            <ng-multiselect-dropdown focusOnClick class="full-border single-select sm service-process"
                                [ngClass]="{'adjust-dropdown': applyAdjustDropdown(i)}" [settings]="settings"
                                [placeholder]="'common.select' | translate" [data]="rolesList"
                                [(ngModel)]="L2RoleThree[selectedIndex][i]" (onSelect)="onSelectItem($event,2,'l2', i)"
                                (onDeSelect)="onDeselect(2,'l2',i)"></ng-multiselect-dropdown>
                        </td>
                        <td style="width: 78px;"
                            [ngClass]="{'disabled no-opacity' : disableEdit || (!getActions('Manage Approvals') && !getActions('Create Entity'))}">
                            <button [title]="'common.resetFilter'| translate" (click)="removeAccess(i)"
                                *ngIf="L2RoleOne[selectedIndex][i]?.length || L2RoleTwo[selectedIndex][i]?.length || L2RoleThree[selectedIndex][i]?.length">
                                <img src="./assets/images/refresh.png" alt="reset">
                            </button>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>

    <div class="no-service-lines" *ngIf="!hexLoader && entityServiceLineList?.length === 0">
        <h5 translate>entities.approvals.noServiceLines</h5>
    </div>
</div>

<!-- hex loader -->
<app-hex-loader *ngIf="hexLoader"></app-hex-loader>