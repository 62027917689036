import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, Subject } from 'rxjs';
import { EntitiesService } from '../../../../../modules/entities/services/entities.service';
import { StatusCodeService } from '../../../../services/status-code/status-code.service';

@Component({
  selector: 'app-absence-type',
  templateUrl: './absence-type.component.html',
  styleUrl: './absence-type.component.scss'
})
export class AbsenceTypeComponent {
  @Input() selectedEntity: any;
  @Input() disableEdit: boolean = false;
  absenceTypeList: any[] = [];
  isLoader: boolean = true;
  hexLoader: boolean = false;
  isConfirmPopup: string = '';
  editableRowIndex: number = -1;
  selectedRow: any;
  absenceTypeId: any = '';
  absenceType: any = '';
  duplicateAbsenceType: number = -1;
  absenceTypeSubject = new Subject<any>();

  constructor(private entitiesService: EntitiesService, private showStatusCode: StatusCodeService, private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.absenceTypeCheck();
  }

  ngOnInit(): void {
    this.getAbsenceType();
  }

  getAbsenceType() {
    this.entitiesService.getAbsenceType(this.selectedEntity?.entityId).subscribe({
      next: (res) => {
        if (res?.data) this.absenceTypeList.push(...res.data);
        this.disableloader();
      },
      error: (error) => {
        this.disableloader();
      }
    })
  }

  disableloader() {
    if (this.isLoader) { this.isLoader = false; }
    if (this.hexLoader) { this.hexLoader = false; }
  }

  saveUpdateAbsenceType(absenceType?: any) {
    if (this.duplicateAbsenceType !== 1) {
      this.toastr.clear();
      this.hexLoader = true;

      let body: any = {
        absenceTypeId: absenceType ? absenceType?.absenceTypeId : null,
        absenceType: absenceType ? absenceType.absenceType.trim() : this.absenceType.trim(),
        entityId: this.selectedEntity?.entityId
      }

      this.entitiesService.saveUpdateAbsenceType(body).subscribe({
        next: (res) => {
          this.hexLoader = false;
          if (res?.statusCode === 'UNAUTHORIZED_USER') {
            this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
          } else {
            this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', absenceType ? 'common' : 'entities.absenceType');
          }
          this.resetAbsenceType();
        },
        error: (error) => {
          this.hexLoader = false;
        }
      })
    }
  }

  onInputChange(event: any) {
    this.duplicateAbsenceType = -1;
    this.absenceTypeSubject.next(event.target.value?.trim() || '');
  }

  absenceTypeCheck() {
    this.absenceTypeSubject.pipe(debounceTime(500)).subscribe(keyword => {
      if (keyword && (keyword !== this.selectedRow?.absenceType)) {
        this.absenceTypeUniqueCheckAPI(keyword);
      }
    });
  }

  absenceTypeUniqueCheckAPI(absenceType: any) {
    this.hexLoader = true;
    let body: any = { "entityId": this.selectedEntity?.entityId, "absenceType": absenceType };

    this.entitiesService.absenceTypeDuplicateCheck(body).subscribe({
      next: (res) => {
        this.duplicateAbsenceType = +res.data;
        this.disableloader();
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }

  toggleStatusAbsenceType(event: boolean, id: string) {
    this.toastr.clear();
    this.hexLoader = true;
    let body: any = {
      "id": id,
      "entityId": this.selectedEntity?.entityId,
      "status": event ? 'ACTIVE' : 'INACTIVE'
    }
    this.entitiesService.switchAbsenceTypeStatus(body).subscribe({
      next: (res) => {
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', 'entities.absenceType');
        }
        this.hexLoader = false;
        this.resetAbsenceType();
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }

  deleteAbsenceType() {
    this.toastr.clear();
    this.hexLoader = true;
    let body = {
      "absenceTypeId": [this.absenceTypeId],
      "entityId": this.selectedEntity?.entityId
    }
    this.entitiesService.deleteAbsenceType(body).subscribe({
      next: (res) => {
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', 'entities.absenceType');
        }
        this.hexLoader = false;
        this.resetAbsenceType();
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }

  resetAbsenceType() {
    if (this.editableRowIndex === -1) { this.absenceType = '' };
    this.isLoader = true;
    this.absenceTypeList = [];
    this.editableRowIndex = -1;
    this.duplicateAbsenceType = -1;
    this.getAbsenceType();
  }

  handleConfirmation(ev: boolean) {
    if (ev) {
      if (this.isConfirmPopup === 'edit') {
        this.resetEditRow();
      } else {

        this.deleteAbsenceType();
      }
    }
    this.isConfirmPopup = '';
    this.duplicateAbsenceType = -1;
  }

  resetEditRow() {
    this.absenceTypeList[this.editableRowIndex].absenceType = this.selectedRow.absenceType;
    this.editableRowIndex = -1;
    this.selectedRow = {};
  }

  cancelEdit(absenceType: any) {
    if (this.selectedRow.absenceType !== absenceType.absenceType) {
      this.isConfirmPopup = 'edit';
    } else {
      this.editableRowIndex = -1;
      this.selectedRow = {};
    }
    this.duplicateAbsenceType = -1;
  }

  editRow(index: number) {
    this.duplicateAbsenceType = -1;
    this.editableRowIndex = index;
    this.selectedRow = JSON.parse(JSON.stringify(this.absenceTypeList[index]));
  }

  inlineDelete(id: any) {
    this.isConfirmPopup = 'delete';
    this.absenceTypeId = id;
  }
}
