<div class="work-schedule p-2 px-3">
    <div class="add-work-schedule" style="height: 65px;" [ngClass]="{'disabled no-opacity' : disableEdit}">
        <div class="form w100 row">
            <!-- work schedule code -->
            <div class="form-group col-3 mb-0">
                <div class="d-flex justify-content-between align-items-center">
                    <label for="" translate><span class="asterik">* </span>common.code</label>
                    <span class="char-info">
                        <strong>{{workScheduleCode.length}}</strong> / 256
                        <span translate>common.chars</span>
                    </span>
                </div>
                <input type="text" maxlength="256" [placeholder]="'entities.general.enterHere' | translate"
                    [title]="workScheduleCode" [(ngModel)]="workScheduleCode"
                    [ngClass]="{'error': (duplicateWorkSchedule && editableRowIndex === -1)}"
                    (input)="duplicateWorkSchedule=false" (input)="onInputChange('code', $event)" required />
                <span [ngStyle]="{'visibility': duplicateWorkSchedule && editableRowIndex === -1 ? 'visible' :'hidden'}"
                    class="error" translate>entities.error.codeExist</span>
            </div>

            <!-- work schedule description -->
            <div class="form-group col-6 pe-1 mb-0">
                <div class="d-flex justify-content-between align-items-center">
                    <label for="" translate><span class="asterik">* </span>entities.general.description</label>
                    <span class="char-info">
                        <strong>{{description.length}}</strong> / 2500
                        <span translate>common.chars</span>
                    </span>
                </div>
                <input type="text" maxlength="2500" [placeholder]="'entities.general.enterHere' | translate"
                    [title]="description" [(ngModel)]="description" required />
            </div>

            <div class="col-3 p-0">
                <button class="btn-link teal" style="padding-top: 22px" translate (click)="saveupdateWorkSchedule()"
                    [ngClass]="{'disabled': workScheduleCode.trim() === '' || description.trim() === ''}">
                    <i class=" icon-create"></i>common.add</button>
            </div>
        </div>
    </div>

    <div>
        <div class="title"><span><span translate>clients.added</span> ({{workschedulesList.length}})</span>
            <button class="btn-link ms-3" *ngIf="workschedulesList.length" style="vertical-align: top;"
                [ngClass]="{'disabled no-opacity' : disableEdit}" (click)="deleteWorkSchedule()">Remove All</button>
        </div>
        <div class="table-wrapper mt-2" style="height: calc(100vh - 390px);overflow-y: auto;">
            <table aria-describedby="work-schedule" [ngClass]="{'loading':isLoader}">
                <thead>
                    <tr>
                        <th style="width: 35%">
                            <span class="textoverflow" [title]="'entities.code' | translate" translate>common.code
                            </span>
                            <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
                        </th>
                        <th>
                            <span class="textoverflow" [title]="'entities.description' | translate"
                                translate>entities.general.description</span>
                            <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
                        </th>
                        <th style="width: 150px"><span>&nbsp;</span>
                            <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="!isLoader">
                    @if(workschedulesList.length > 0 )
                    {
                    <tr *ngFor="let workSchedule of workschedulesList;let i = index"
                        [ngClass]="{'edit-row': editableRowIndex ===i}">
                        <ng-container>
                            <td [title]="workSchedule.workScheduleCode" style="width: 35%;">
                                <div class="input-container" *ngIf="editableRowIndex === i;else workScheduleCode"><input
                                        name="workScheduleCode" required maxlength="256"
                                        [(ngModel)]="workSchedule.workScheduleCode"
                                        (input)="duplicateWorkSchedule=false" (input)="onInputChange('code', $event)" data-trigger="hover"
                                        [ngbTooltip]="workSchedule.workScheduleCode === '' ? ('common.error.required' | translate):  duplicateWorkSchedule && editableRowIndex  === i ? ('entities.error.codeExist' | translate) : ''"
                                        [ngClass]="{'border-error':workSchedule.workScheduleCode.trim() === '' || (duplicateWorkSchedule && editableRowIndex === i)}" />
                                </div>
                                <ng-template #workScheduleCode>
                                    <div class="textoverflow">{{workSchedule.workScheduleCode}}</div>
                                </ng-template>
                            </td>

                            <td [title]="workSchedule.workScheduleDescription">
                                <div class="input-container" *ngIf="editableRowIndex ===i;else workScheduleDescription">
                                    <input name="workScheduleDescription" required maxlength="2500"
                                        [(ngModel)]="workSchedule.workScheduleDescription" data-trigger="hover"
                                        [ngbTooltip]="workSchedule.workScheduleDescription === '' ? ('common.error.required' | translate): null"
                                        [ngClass]="{'border-error':workSchedule.workScheduleDescription.trim() === ''}" />
                                </div>
                                <ng-template #workScheduleDescription>
                                    <div class="textoverflow">{{workSchedule.workScheduleDescription}}</div>
                                </ng-template>
                            </td>
                            <td class="icons" style="width: 150px;">
                                <div class="d-flex align-items-center justify-content-end"
                                    [ngClass]="{'disabled no-opacity' : disableEdit}">
                                    <div class="d-flex" *ngIf="editableRowIndex ===i">
                                        <img style="height: 13px;" class="cp"
                                            [ngClass]="{'disabled':(workSchedule.workScheduleCode.trim() === '' || duplicateWorkSchedule )|| (workSchedule.workScheduleCode ===selectedRow?.workScheduleCode && workSchedule.workScheduleDescription === selectedRow?.workScheduleDescription)}"
                                            src="./assets/images/tick-active.png" alt="tick"
                                            (click)="saveupdateWorkSchedule(workSchedule)" />
                                        <i style="padding-left: 10px;" class="icon-circle-close cp"
                                            (click)="cancelEdit(workSchedule)"></i>
                                    </div>
                                    <i style="font-size: 11px;" class="icon-pencil cp" *ngIf="editableRowIndex !==i"
                                        (click)="editRow(i)"></i>
                                    <app-toggle style="margin-left: 20px; margin-top: -10px;"
                                        [checked]="workSchedule.statusName==='Active'"
                                        (emitToggle)="statusToggleForWorkSchedule($event,workSchedule.workScheduleId)"
                                        [ngClass]="{'disabled':editableRowIndex ===i}"></app-toggle>
                                    <i style="margin-left: 20px" class="icon-bin cp red"
                                        [ngClass]="{'disabled':editableRowIndex ===i}"
                                        (click)="deleteWorkSchedule(workSchedule.workScheduleId)"></i>
                                </div>
                            </td>
                        </ng-container>
                    </tr>
                    }
                    @else if(workschedulesList.length === 0) {
                    <tr class="h100">
                        <td colspan="3" class="border-0">
                            <div class="no-data" translate>common.nodata</div>
                        </td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>
</div>

<app-hex-loader *ngIf="hexLoader"></app-hex-loader>

<div class="restrict-overlay" style="z-index: 2;left: -10px;top: -45px;" *ngIf="editableRowIndex > -1 "></div>

<!-- confirmation-popup-delete -->
<app-confirmation-popup *ngIf="isConfirmPopup" [innerModal]="true" (emitEvent)="handleConfirmation($event)"
    [description]="isConfirmPopup==='delete' ? 'common.confirmDelete' : 'common.cancelMsg' "></app-confirmation-popup>
