import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-hex-loader',
    templateUrl: './hex-loader.component.html',
    styleUrls: ['./hex-loader.component.scss'],
    standalone: false
})
export class HexLoaderComponent {
  @Input() page: string = '';
}
