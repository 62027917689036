<div style="width: max-content;">
  <div class="popup-title" translate>entities.createNewPayElement</div>
  <div class="popup">
    <form class="form mt-0" [formGroup]="payElementForm">
      <!-- payElementCode -->
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label for="" translate><span class="asterik">*</span> entities.payElementCode</label>
          <span class="char-info"><strong>{{payElementForm.value.payElementCode?.length}}</strong> / 256 <span
              translate>common.chars</span></span>
        </div>
        <input maxlength="256" type="text" formControlName="payElementCode"
          [placeholder]="'entities.general.enterHere' | translate"
          [ngClass]="{'error' : payElementForm.get('payElementCode')?.errors && payElementForm.get('payElementCode')?.touched }" />
        <div class="error" *ngIf="
          payElementForm.get('payElementCode')?.hasError('required') &&
          payElementForm.get('payElementCode')?.touched" translate>
          common.error.required
        </div>
        <div class="error" *ngIf="payElementForm.get('payElementCode')?.hasError('payElementCodeDuplicate') &&
          payElementForm.get('payElementCode')?.touched" translate>entities.general.payElementCodeExist</div>
        <div class="error" *ngIf="
          payElementForm.get('payElementCode')?.hasError('noAlphabet') && !payElementForm.get('payElementCode')?.hasError('required') &&
          payElementForm.get('payElementCode')?.dirty" translate> common.error.alphaNumericRequired
        </div>
      </div>

      <!-- localLanguageName -->
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label for="" translate><span class="asterik">*</span> entities.localLanguageName</label>
          <span class="char-info"><strong>{{payElementForm.value.localLanguageName?.length}}</strong> / 256 <span
              translate>common.chars</span></span>
        </div>
        <input maxlength="256" type="text" formControlName="localLanguageName"
          [placeholder]="'entities.general.enterHere' | translate"
          [ngClass]="{'error' : payElementForm.get('localLanguageName')?.errors && payElementForm.get('localLanguageName')?.touched }" />
        <div class="error" *ngIf="
            payElementForm.get('localLanguageName')?.hasError('required') &&
            payElementForm.get('localLanguageName')?.touched" translate>
          common.error.required
        </div>
        <div class="error" *ngIf="
            payElementForm.get('localLanguageName')?.hasError('localNameDuplicate') &&
            payElementForm.get('localLanguageName')?.touched" translate>
          entities.general.localLanguageNameExist
        </div>
        <div class="error" *ngIf="
              payElementForm.get('localLanguageName')?.hasError('noAlphabet') && !payElementForm.get('localLanguageName')?.hasError('required') &&
              payElementForm.get('localLanguageName')?.dirty" translate> common.error.alphaNumericRequired
        </div>
      </div>

      <!-- englishLanguageName -->
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label for="" translate><span class="asterik">*</span> entities.englishLanguageName</label>
          <span class="char-info"><strong>{{payElementForm.value.englishLanguageName?.length}}</strong> / 256 <span
              translate>common.chars</span></span>
        </div>
        <input maxlength="256" type="text" formControlName="englishLanguageName"
          [placeholder]="'entities.general.enterHere' | translate"
          [ngClass]="{'error' : payElementForm.get('englishLanguageName')?.errors && payElementForm.get('englishLanguageName')?.touched }" />
        <div class="error" *ngIf="
            payElementForm.get('englishLanguageName')?.hasError('required') &&
            payElementForm.get('englishLanguageName')?.touched" translate>common.error.required
        </div>
        <div class="error" *ngIf="
            payElementForm.get('englishLanguageName')?.hasError('englishNameDuplicate') &&
            payElementForm.get('englishLanguageName')?.touched" translate>
          entities.general.englishLanguageNameExist
        </div>
        <div class="error" *ngIf="
              payElementForm.get('englishLanguageName')?.hasError('noAlphabet') && !payElementForm.get('englishLanguageName')?.hasError('required') &&
              payElementForm.get('englishLanguageName')?.dirty" translate> common.error.alphaNumericRequired
        </div>
      </div>

      <!-- wageGroup -->
      <div class="form-group">
        <label for="" translate><span class="asterik">*</span> entities.wageGroup</label>
        <ng-select [items]="wageGroupItems" bindLabel="wageGroupName" bindValue="wageGroupId" [searchable]="true"
          [clearable]="false" formControlName="wageGroup" [virtualScroll]="true"
          [placeholder]="'common.select' | translate" [notFoundText]="'common.nodata' | translate"
          [ngClass]="{'error-select' : payElementForm.get('wageGroup')?.errors && payElementForm.get('wageGroup')?.touched }">
        </ng-select>

        <div class="error" *ngIf="payElementForm.get('wageGroup')?.hasError('required') &&
            payElementForm.get('wageGroup')?.touched" translate>common.error.required
        </div>
      </div>

      <!-- elementType -->
      <div class="form-group">
        <label for="" translate><span class="asterik">*</span> entities.elementType</label>
        <ng-select class="position-top" [items]="elementTypeItems" bindLabel="elementTypeCode"
          bindValue="payElementTypeId" [searchable]="true" [clearable]="false" formControlName="elementType"
          [virtualScroll]="true" [placeholder]="'common.select' | translate"
          [notFoundText]="'common.nodata' | translate" [ngClass]="{'error-select' : payElementForm.get('elementType')?.errors &&
           payElementForm.get('elementType')?.touched }">
        </ng-select>

        <div class="error" *ngIf="payElementForm.get('elementType')?.hasError('required') &&
            payElementForm.get('elementType')?.touched" translate>common.error.required
        </div>
      </div>

      <!-- clientImportEnabled -->
      <div class="form-group">
        <div class="d-flex justify-content-start">
          <label for="" translate><span class="asterik">*</span> entities.clientImportEnabled</label>
          <div class="error ms-2 mt-0" *ngIf="payElementForm.get('importClient')?.hasError('required') &&
            payElementForm.get('importClient')?.touched" translate>common.error.required
          </div>
        </div>
        <div class="d-flex mt-1">
          <label class="radio-container me-3">
            <div class="radio-text" translate>common.yes</div>
            <input type="radio" class="radio-input" name="importClient" value="1" formControlName="importClient">
            <span class="checkmark"></span>
          </label>
          <label class="radio-container">
            <div class="radio-text" translate>common.no</div>
            <input type="radio" class="radio-input" name="importClient" value="0" formControlName="importClient">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </form>

    <!-- Buttons -->
    <div class="mt-2 text-center">
      <button class="btn-primary btn-dark" type="submit" (click)="savePayElement()" translate>common.add</button>
      <button class="btn-outline btn-dark-outline" (click)="handleCancel()" translate>common.cancel</button>
    </div>
  </div>
</div>

<app-hex-loader *ngIf="hexLoader"></app-hex-loader>