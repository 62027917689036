import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss']
})
export class SortComponent {
  @Input() field: string = '';
  @Input() sortField: string = '';
  @Input() sortOrder: string = '';

}
