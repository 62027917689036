import { Router } from '@angular/router';
import { AuthService } from './../services/auth.service';
import { Component } from '@angular/core';
import { CryptoService } from '../../shared/services/crypto/crypto.service';

@Component({
  selector: 'app-prof-user-clients',
  templateUrl: './prof-user-clients.component.html',
  styleUrl: './prof-user-clients.component.scss'
})
export class ProfUserClientsComponent {
  userClients: any[] = [];
  selectedClientId: string = '';
  hexLoader: boolean = true;
  url: string = this.router.url;
  userDetails: any;
  searchTerm: string = '';
  isShowPopup: Boolean = false;

  constructor(private authService: AuthService, private router: Router, private crypto: CryptoService) { }

  ngOnInit() {
    if (localStorage.getItem('user-details')) {
      this.userDetails = JSON.parse(this.crypto.decrypt(localStorage.getItem('user-details') ?? ''));
    }
    this.getUserClients('fetch');
  }

  getUserClients(action: string) {
    this.hexLoader = true;
    this.authService.getUserClients(this.searchTerm).subscribe({
      next: res => {
        // client id
        if (this.url === '/my-workspace' || this.url === '/workflow') { this.selectedClientId = this.userDetails?.clientId; }
        else { this.selectedClientId = res.data[0]?.clientId; }

        // user clients
       if (res.data?.length === 1 && action !== 'search') {   this.isShowPopup = false; this.loadProfUserCache(); }
        else {this.userClients = res.data ? res.data : [];  this.isShowPopup = true; this.hexLoader = false; }
      
      }, error: error => { this.hexLoader = false; }
    })
  }

  loadProfUserCache() {
    this.hexLoader = true;
    this.authService.profUserCache(this.selectedClientId).subscribe({
      next: res => {
        this.hexLoader = false;
        this.authService.emitSelectUserClientForTmfUser.next(true);
      }, error: error => { this.hexLoader = false; }
    })
  }

  closeUserClients() { this.authService.userClientsSubject.next(false); }

  receivedSearch(text: any) {
    this.isShowPopup = true;
    if (!this.hexLoader) {
      this.searchTerm = text ;
      this.getUserClients('search');
    }
  }
}
