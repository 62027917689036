<div class="modal" [ngClass]="{'inside-modal':innerModal, 'inline':inline}">
    <div class="modal-dialog mt-0">
        <div class="modal-content sm pt-3">
            <div class="modal-header mb-2">
                <div class="modal-title" translate>common.confirmation</div>
                <i class="icon-close cp" style="font-size: 12px;" (click)="emitEvent.emit(false)"></i>
            </div>

            <div class="modal-body gray-bg">
                <p *ngIf="description" [innerHTML]="description | translate"> </p>
                <p *ngIf="!description" translate>common.cancelMsg</p>
            </div>

            <div class="modal-footer mx-auto">
                <button class="btn-primary" (click)="emitEvent.emit(true)" translate>{{confButton ? confButton :
                    'common.ok'}}</button>
                <button class="btn-outline" (click)="emitEvent.emit(false)" translate>{{cancelButton ? cancelButton :
                    'common.cancel'}}</button>
            </div>
        </div>
    </div>
</div>