<div class="termination-reason p-2 px-3">
  <div class="add-termination-reason" style="height: 65px;" [ngClass]="{'disabled no-opacity' : disableEdit}">
    <div class="form w100 row">
      <div class="form-group col-6 pe-1 mb-0">
        <div class="d-flex justify-content-between align-items-center">
          <span class="char-info ms-auto">
            <strong>{{terminationReason.length}}</strong> / 256
            <span translate>common.chars</span>
          </span>
        </div>
        <input type="text" maxlength="256" [placeholder]="'entities.general.enterHere' | translate"
          [title]="terminationReason" [(ngModel)]="terminationReason"
          [ngClass]="{'error': (duplicateTerminationReason && editableRowIndex === -1)}" (input)="onInputChange($event)"
          required />
        <span [ngStyle]="{'visibility': duplicateTerminationReason && editableRowIndex === -1 ? 'visible' :'hidden'}"
          class="error" translate>entities.terminationReasons.error.TerminationReasonExist</span>
      </div>

      <div class="col-3 p-0">
        <button class="btn-link teal" style="padding-top: 22px" translate (click)="saveupdateTerminateReason()"
          [ngClass]="{'disabled': terminationReason.trim() === '' || duplicateTerminationReason}">
          <i class=" icon-create"></i>common.add</button>
      </div>

    </div>
  </div>

  <div>
    <div class="title" [ngClass]="{'disabled no-opacity' : disableEdit}"><span><span translate>clients.added</span>
        ({{terminationReasonList.length}})</span>
      <button class="btn-link ms-3" *ngIf="terminationReasonList.length" style="vertical-align: top;"
        (click)="deleteTerminateReason()">Remove All</button>
    </div>
    <div class="table-wrapper mt-2" style="height: calc(100vh - 390px);overflow-y: auto;">
      <table aria-describedby="termination-reason" [ngClass]="{'loading':isLoader}">
        <thead>
          <tr>
            <th>
              <span class="textoverflow" [title]="'entities.departmentDescription' | translate"
                translate>entities.terminationReasons.terminationReason</span>
              <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
            </th>
            <th style="width: 150px"><span>&nbsp;</span>
              <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="!isLoader">
          @if(terminationReasonList.length > 0 )
          {
          <tr *ngFor="let termination of terminationReasonList;let i = index"
            [ngClass]="{'edit-row': editableRowIndex ===i}">
            <ng-container>
              <td [title]="termination.terminationReason">
                <div class="input-container" *ngIf="editableRowIndex ===i;else terminationReason">
                  <input name="terminationReason" required maxlength="2500" [(ngModel)]="termination.terminationReason" data-trigger="hover"
                    [ngbTooltip]="termination.terminationReason === '' ? ('common.error.required' | translate): duplicateTerminationReason && editableRowIndex === i?('entities.terminationReasons.error.TerminationReasonExist' | translate) : ''"
                    [ngClass]="{'border-error':termination.terminationReason.trim() === '' || (duplicateTerminationReason && editableRowIndex === i)}"
                    (input)="onInputChange($event)" />
                </div>
                <ng-template #terminationReason>
                  <div class="textoverflow">{{termination.terminationReason}}</div>
                </ng-template>
              </td>
              <td class="icons" style="width: 150px;">
                <div class="d-flex align-items-center" style="justify-content: flex-end;"
                  [ngClass]="{'disabled no-opacity' : disableEdit}">
                  <div class="d-flex" *ngIf="editableRowIndex ===i">
                    <img style="height: 13px;" class="cp"
                      [ngClass]="{'disabled':(selectedRow.terminationReason === termination.terminationReason || duplicateTerminationReason || termination.terminationReason.trim() === '')}"
                      src="./assets/images/tick-active.png" alt="tick"
                      (click)="saveupdateTerminateReason(termination)" />
                    <i style="padding-left: 10px;" class="icon-circle-close cp" (click)="cancelEdit(termination)"></i>
                  </div>
                  <i style="font-size: 11px;" class="icon-pencil cp" *ngIf="editableRowIndex !==i"
                    (click)="editRow(i)"></i>
                  <app-toggle style="margin-left: 20px; margin-top: -10px;"
                    [checked]="termination.status==='Active'? true:false"
                    (emitToggle)="statusToggleForTerminateReason($event,termination.terminationReasonId)"
                    [ngClass]="{'disabled':editableRowIndex ===i}"></app-toggle>
                  <i style="margin-left: 20px" class="icon-bin cp red" [ngClass]="{'disabled':editableRowIndex ===i}"
                    (click)="deleteTerminateReason(termination.terminationReasonId)"></i>
                </div>
              </td>
            </ng-container>
          </tr>
          }

          @else if(terminationReasonList.length === 0) {
          <tr class="h100">
            <td colspan="3" class="border-0">
              <div class="no-data" translate>common.nodata</div>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>

<app-hex-loader *ngIf="hexLoader"></app-hex-loader>

<div class="restrict-overlay" style="z-index: 2;left: -10px;top: -45px;" *ngIf="editableRowIndex > -1 "></div>

<!-- confirmation-popup-delete -->
<app-confirmation-popup *ngIf="isConfirmPopup" (emitEvent)="handleConfirmation($event)"
  [description]="isConfirmPopup==='delete' ? 'common.confirmDelete' : 'common.cancelMsg' "></app-confirmation-popup>
