import { Component, OnInit, Input } from '@angular/core';
import { EntitiesService } from '../../../../../modules/entities/services/entities.service';
import { StatusCodeService } from '../../../../services/status-code/status-code.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime } from 'rxjs';
import { auditTracking } from '../../../../../utils/audit-tracking';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-job-band',
    templateUrl: './job-band.component.html',
    styleUrl: './job-band.component.scss',
    standalone: false
})
export class JobBandComponent implements OnInit {
  @Input() selectedEntity: any;
  @Input() disableEdit: boolean = false;
  jobBandList: any[] = [];
  isLoader: boolean = true;
  hexLoader: boolean = false;
  isConfirmPopup: string = '';
  editableRowIndex: number = -1;
  selectedRow: any;
  jobBandId: any = '';
  jobBandName: any = '';
  duplicateJobBand: number = -1;
  jobBandSubject = new Subject<any>();

  constructor(private entitiesService: EntitiesService, private showStatusCode: StatusCodeService, private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.jobBandCheck();
  }

  ngOnInit(): void {
    this.getAllJobBands();
  }

  getAllJobBands() {
    this.entitiesService.getAllJobBands(this.selectedEntity?.entityId).subscribe({
      next: (res) => {
        if (res?.data) this.jobBandList.push(...res.data);
        this.disableloader();

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.viewJobBands');
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('auditTrail.auditTypeView'),
          description)

      },
      error: (error) => {
        this.disableloader();
      }
    })
  }

  disableloader() {
    if (this.isLoader) { this.isLoader = false; }
    if (this.hexLoader) { this.hexLoader = false; }
  }

  saveUpdateJobBand(jobDetails?: any) {
    if (this.duplicateJobBand !== 1) {
      this.toastr.clear();
      this.hexLoader = true;

      let body: any = {
        jobBandId: jobDetails ? jobDetails?.jobBandId : null,
        jobBandName: jobDetails ? jobDetails.jobBandName.trim() : this.jobBandName.trim(),
        entityId: this.selectedEntity?.entityId
      }

      this.entitiesService.saveUpdateJobBand(body).subscribe({
        next: (res) => {
          this.hexLoader = false;
          if (res?.statusCode === 'UNAUTHORIZED_USER') {
            this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
          } else {
            this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', jobDetails ? 'common' : 'entities.jobBands');
          }

          if (jobDetails) {
            let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.editJobBands');

            auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
              this.translate.instant('auditTrail.entities.entityDetail'),
              this.translate.instant('common.edit'),
              description)

          } else {
            let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.addJobBands');

            auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
              this.translate.instant('auditTrail.entities.entityDetail'),
              this.translate.instant('common.add'),
              description)
          }


          this.resetJobBand();
        },
        error: (error) => {
          this.hexLoader = false;
        }
      })
    }
  }

  onInputChange(event: any) {
    this.duplicateJobBand = -1;
    this.jobBandSubject.next(event.target.value?.trim() || '');
  }

  jobBandCheck() {
    this.jobBandSubject.pipe(debounceTime(500)).subscribe(keyword => {
      if (keyword && (keyword !== this.selectedRow?.jobBandName)) {
        this.jobBandUniqueCheckAPI(keyword);
      }
    });
  }

  jobBandUniqueCheckAPI(jobBand: any) {
    this.hexLoader = true;
    let body: any = { "entityId": this.selectedEntity?.entityId, "jobBandName": jobBand };

    this.entitiesService.jobBandDuplicateCheck(body).subscribe({
      next: (res) => {
        this.duplicateJobBand = +res.data;
        this.disableloader();
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }

  statusToggleForJobBandName(event: boolean, id: string) {
    this.toastr.clear();
    this.hexLoader = true;
    let body: any = {
      "id": id,
      "entityId": this.selectedEntity?.entityId,
      "status": event ? 'ACTIVE' : 'INACTIVE'
    }
    this.entitiesService.switchJobBandStatus(body).subscribe({
      next: (res) => {
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', 'entities.jobBands');
        }
        this.hexLoader = false;

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.editJobBands');

        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('common.edit'),
          description)

        this.resetJobBand();
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }

  deleteJobBand() {
    this.toastr.clear();
    this.hexLoader = true;
    let body = {
      "jobBandIds": [this.jobBandId],
      "entityId": this.selectedEntity?.entityId
    }
    this.entitiesService.deleteJobBand(body).subscribe({
      next: (res) => {
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', 'entities.jobBands');
        }
        this.hexLoader = false;

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.deleteJobBands');

        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('common.delete'),
          description)

        this.resetJobBand();
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }

  resetJobBand() {
    if (this.editableRowIndex === -1) { this.jobBandName = '' };
    this.isLoader = true;
    this.jobBandList = [];
    this.editableRowIndex = -1;
    this.duplicateJobBand = -1;
    this.getAllJobBands();
  }

  handleConfirmation(ev: boolean) {
    if (ev) {
      if (this.isConfirmPopup === 'edit') {
        this.resetEditRow();
      } else {

        this.deleteJobBand();
      }
    }
    this.isConfirmPopup = '';
    this.duplicateJobBand = -1;
  }

  resetEditRow() {
    this.jobBandList[this.editableRowIndex].jobBandName = this.selectedRow.jobBandName;
    this.editableRowIndex = -1;
    this.selectedRow = {};
  }

  cancelEdit(job: any) {
    if (this.selectedRow.jobBandName !== job.jobBandName) {
      this.isConfirmPopup = 'edit';
    } else {
      this.editableRowIndex = -1;
      this.selectedRow = {};
    }
    this.duplicateJobBand = -1;
  }

  editRow(index: number) {
    this.duplicateJobBand = -1;
    this.editableRowIndex = index;
    this.selectedRow = JSON.parse(JSON.stringify(this.jobBandList[index]));
  }

  inlineDelete(id: any) {
    this.isConfirmPopup = 'delete';
    this.jobBandId = id;
  }

}
