import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { CryptoService } from '../services/crypto/crypto.service';
import { jwtDecode } from "jwt-decode";
import { EventHubProducerClient} from "@azure/event-hubs";
import { SharedService } from '../services/shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class AzureService {

  decodedToken: any = "";
  clientConnectionstring: any;
  clientHubname: any;
  consumerGroup = '$Default';

  constructor(private crypto: CryptoService, private sharedService: SharedService) { }
  

  eventsHubSendData = async (auditObj: any) => {
     this.getConnectionString();
     this.sendEventData(this.clientConnectionstring, this.clientHubname, auditObj).catch((error) => {
      console.error("Error running sample:", error);
    });
  }

  getConnectionString() {
    let decryptData: any;
    this.decodedToken = this.sharedService.getDecodedToken();
    let clickstream = localStorage.getItem("clickstream");
    let secretKey = "";
    let userDetails = JSON.parse(this.crypto.decrypt(localStorage.getItem('user-details') ?? ''));
     if (this.decodedToken) {
      secretKey = this.decodedToken["tracingId"]?.trim() + this.decodedToken["nts"]?.trim() + userDetails?.userId?.trim();      
    }
    if(clickstream!== null) {
      decryptData = this.crypto.decrypt(clickstream.trim(), secretKey);
    }
     let decryptArray = decryptData?.split("EntityPath=");
    if (decryptArray && decryptArray.length > 1) {
      this.clientConnectionstring = decryptArray[0];
      this.clientHubname = decryptArray[1];
    }
  }

  sendEventData = async (clientConnectionstring: any, clientHubname: any, auditObj: any) => {
    console.table(auditObj)
    const producer = new EventHubProducerClient(clientConnectionstring, clientHubname);

    const eventsToSend = auditObj;
      try {
        const batchOptions = {
          maxSizeInBytes: 4000 //Changed from 200 to 4000
        };

        let batch = await producer.createBatch(batchOptions);

        let numEventsSent = 0;

        let i = 0;
        while (i < eventsToSend.length) {
          const isAdded = batch.tryAdd({ body: eventsToSend[i] });

          if (isAdded) {
            ++i;
            continue;
          }

          if (batch.count === 0) {
            console.log(`Message was too large and can't be sent until it's made smaller. Skipping...`);
            ++i;
            continue;
          }

          console.log(`Batch is full - sending ${batch.count} messages as a single batch.`);
          await producer.sendBatch(batch);
          numEventsSent += batch.count;

          batch = await producer.createBatch(batchOptions);
        }

        if (batch.count > 0) {
          await producer.sendBatch(batch);
          numEventsSent += batch.count;
        }
        if (numEventsSent !== eventsToSend.length) {
          throw new Error(`Not all messages were sent (${numEventsSent}/${eventsToSend.length})`);
        }
      } catch (err: any) {
        console.log("Error when creating & sending a batch of events: ", err);
      }

     await producer.close();
  }
}