import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { loginPreference } from '../../../core/constants/endpoint';

@Injectable({
  providedIn: 'root'
})
export class LoginPreferencesService {

  constructor(private http: HttpClient, private router: Router) { }

  preferencesRouteSubject = new BehaviorSubject({});
  picUploadSubject = new BehaviorSubject<any>({});

  resetUserPreference(): Observable<any> {
    return this.http.post(environment.url  + loginPreference.resetUserPreference, {});
  }

  getUserPreferenceStatus(): Observable<any> {
    return this.http.get(environment.url + loginPreference.getUserPreferenceStatus);
  }

  getCurrentPreferencesRoute(index: number) {
    switch (index) {
      case 1: this.router.navigate(['/preferences/language-preferences']); break;
      case 2: this.router.navigate(['/preferences/personal-preferences']); break;
      case 3: this.router.navigate(['/preferences/timezone-preferences']); break;
      case 4: this.router.navigate(['/preferences/notification-preferences']); break;
    }
  }

  getUserPreference(pageNo: number): Observable<any> {
    return this.http.get(environment.url + loginPreference.getUserPreference + '?pageNo=' + pageNo);
  }

  saveUserPreference(body: any): Observable<any> {
    return this.http.post(environment.url +loginPreference.getUserPreference, body);
  }

  uploadProfilePicture(body: any): Observable<any> {
    return this.http.post(environment.url +loginPreference.uploadPic, body);
  }

  deleteUserProfilePic(): Observable<any> {
    return this.http.post(environment.url +loginPreference.deletePic, {});
  }

}
