import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginPreferencesService } from '../../modules/login-preferences/services/login-preferences.service';
import { SharedService } from '../../shared/services/shared/shared.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  token: string = '';
  params: string[] = []; // Login Flow POC
  userDetails: any;
  hexLoader: boolean = false;
  userType: any;

  constructor(private authService: AuthService, private router: Router, private cookieService: CookieService, private loginPreferencesService: LoginPreferencesService,
    private sharedService: SharedService
  ) { this.checkForToken(); }

  checkForToken() {
    if (!this.token)
      this.getTokenFromUrl();

    if (this.token)
      this.validateToken();
    else
      this.authService.getAuthenticationToken();
  }

  getTokenFromUrl() {
    window.localStorage.setItem("token", '');

    if (this.cookieService.get("id_token")) {
      this.token = this.cookieService.get("id_token");
      window.localStorage.setItem("token", this.token);
    } else if (this.router.url?.indexOf("id_token") != -1) {
      this.params = this.router.url?.split("=");
      if (this.params?.[1]) {
        this.token = this.params[1];
        window.localStorage.setItem("token", this.token);
      }
    }

    this.sharedService.setToken(this.token)
  }

  validateToken() {
    this.hexLoader = true;
    let body = { type: 'TOKEN', methodArn: '' };
    this.authService.validateToken(body).subscribe({
      next: response => {
        window.localStorage.setItem('principalId', response.principalId);
        if (response.policyDocument.statement[0].effect === "Deny") {
          window.localStorage.clear();
          this.authService.getAuthenticationToken();
          this.hexLoader = false;
        } else {
          this.getUserType();
        }
      }, error: error => { this.hexLoader = false; }
    });
  }

  getUserType() {
    this.authService.getUserType().subscribe({
      next: res => {
        this.userType = res?.data;
        localStorage.setItem('user-type-info', JSON.stringify(res.data));
        if (this.userType.userType === 'tmf_admin' || this.userType.userType === 'super_admin') {
          this.userDetails = this.authService.getUserDetailsInfo();
          this.hexLoader = false;
        } else { this.getUserPreferenceStatus(); }
        /*Redirected from email */
        if (window.localStorage.getItem("redirectUrl") != null) {
          this.hexLoader = false;
          let redirectUrl = window.localStorage.getItem("redirectUrl") ?? '';
          this.params = redirectUrl.split("=");
          if (this.params[2]) {
            this.authService.getUserTaskByGuid({ notificationKeyId: this.params[2] }, this.userType);
          } else {
            window.localStorage.removeItem("redirectUrl");
          }
        }
      }, error: error => {
        this.hexLoader = false;
        if (error.error.message[0]?.includes('user is disabled') || error.error.message[0]?.includes('User does not have any active entities')) {
          this.userDetails = { ...this.userDetails, status: 'InActive', userDisabled: true };
        }
      }
    })
  }

  onLogout() { this.authService.logout(); }

  getUserPreferenceStatus() {
    this.hexLoader = true;
    this.loginPreferencesService.getUserPreferenceStatus().subscribe({
      next: res => {
        if (res?.data?.preferenceStatus === "In-progress") {
          this.loginPreferencesService.getCurrentPreferencesRoute(res?.data?.pageNo);
        } else if (res?.data?.preferenceStatus === "COMPLETED") {
          if (this.userType.userType !== 'tmf_user') { this.userDetails = this.authService.getUserDetailsInfo(); }
          else {
            this.authService.userClientsSubject.next(true);
            this.hexLoader = false;
          }
        }
      }, error: error => { this.hexLoader = false; }
    });
  }
}
