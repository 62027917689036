<div class="costCentre p-2 px-3">
  <div class="add-cost-center" style="height: 65px;" [ngClass]="{'disabled no-opacity' : disableEdit}">
    <div class="form w100 row">
      <!-- costCentre code -->
      <div class="form-group col-3 mb-0">
        <div class="d-flex justify-content-between align-items-center">
          <label for="" translate><span class="asterik">* </span>common.code</label>
          <div class="char-info">
            <strong>{{costCentreCode.length}}</strong> / 256
            <span translate>common.chars</span>
          </div>
        </div>
        <input type="text" maxlength="256" [placeholder]="'entities.general.enterHere' | translate"
          [title]="costCentreCode" [(ngModel)]="costCentreCode" [ngClass]="
                {'error': (duplicateCostCentreCode && editableRowIndex === -1)}"
          (input)="duplicateCostCentreCode=false" required (input)="onInputChange('code', $event)" />
        <span [ngStyle]="{'visibility': duplicateCostCentreCode && editableRowIndex === -1 ? 'visible' :'hidden'}"
          class="error" translate>entities.error.codeExist</span>
      </div>

      <!-- costCentre description -->
      <div class="form-group col-6 pe-1 mb-0">
        <div class="d-flex justify-content-between align-items-center">
          <label for="" translate><span class="asterik">* </span>entities.general.description</label>
          <div class="char-info">
            <strong>{{costCentreDesc.length}}</strong> / 256
            <span translate>common.chars</span>
          </div>
        </div>
        <input type="text" maxlength="256" [placeholder]="'entities.general.enterHere' | translate"
          [title]="costCentreDesc" [(ngModel)]="costCentreDesc" />
        <!-- [ngClass]="{'error': (duplicateCostCentreDesc && editableRowIndex === -1)}"
                    (input)="duplicateCostCentreDesc=false" required (input)="onInputChange('desc',$event)"
                <span
                    [ngStyle]="{'visibility': duplicateCostCentreDesc && editableRowIndex === -1 ? 'visible' :'hidden'}"
                    class="error" translate>entities.error.descriptionExist</span> -->
      </div>

      <div class="col-3 p-0">
        <button class="btn-link teal" style="padding-top: 22px" translate (click)="saveUpdateCostCentre()"
          [ngClass]="{'disabled': costCentreCode.trim() === '' || costCentreDesc.trim() === '' || duplicateCostCentreCode}">
          <i class=" icon-create"></i>common.add</button>
      </div>
    </div>
  </div>

  <div>
    <div class="title">
      <span><span translate>clients.added</span> ({{listOfCostCentres.length}})</span>
      <button class="btn-link ms-3" *ngIf="listOfCostCentres.length" style="vertical-align: top;"
        [ngClass]="{'disabled no-opacity' : disableEdit}" (click)="inlineDelete();" translate>clients.removeAll</button>
    </div>

    <div class="table-wrapper mt-2" style="height: calc(100vh - 390px);overflow-y: auto;">
      <table aria-describedby="costCentre" [ngClass]="{'loading':isLoader}">
        <thead>
          <tr>
            <th style="width: 35%">
              <span class="textoverflow" [title]="'entities.code' | translate" translate>common.code</span>
              <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
            </th>
            <th>
              <span class="textoverflow" [title]="'entities.description' | translate"
                translate>entities.general.description</span>
              <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
            </th>
            <th style="width: 150px">
              <span>&nbsp;</span>
              <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="!isLoader">
          @if(listOfCostCentres.length > 0 )
          {
          <tr *ngFor="let costCentre of listOfCostCentres; let i = index"
            [ngClass]="{'edit-row': editableRowIndex ===i}">
            <ng-container>
              <td [title]="costCentre.costCentreCode" style="width: 35%;">
                <div class="input-container" *ngIf="editableRowIndex ===i;else costCentreCode"><input
                    name="costCentreCode" required maxlength="256" (input)="onInputChange('code',$event)"
                    [(ngModel)]="costCentre.costCentreCode" (input)="duplicateCostCentreCode=false" data-trigger="hover"
                    [ngbTooltip]="costCentre.costCentreCode === '' ? ('common.error.required' | translate): duplicateCostCentreCode && editableRowIndex === i ? ('entities.error.codeExist' | translate) : ''"
                    [ngClass]="{'border-error':costCentre.costCentreCode.trim() === '' || (duplicateCostCentreCode && editableRowIndex === i)}" />
                </div>
                <ng-template #costCentreCode>
                  <div class="textoverflow">{{costCentre.costCentreCode}}</div>
                </ng-template>
              </td>
              <td [title]="costCentre.costCentreDesc">
                <div class="input-container" *ngIf="editableRowIndex ===i;else costCentreDesc">
                  <input name="costCentreDesc" required maxlength="256" (input)="onInputChange('desc',$event)"
                    [(ngModel)]="costCentre.costCentreDesc" data-trigger="hover"
                    [ngbTooltip]="costCentre.costCentreDesc === '' ? ('common.error.required' | translate) : ''"
                    [ngClass]="{'border-error': costCentre.costCentreDesc.trim() === ''}" />
                </div>
                <ng-template #costCentreDesc>
                  <div class="textoverflow">{{costCentre.costCentreDesc}}</div>
                </ng-template>
              </td>
              <td class="icons" style="width: 150px;">
                <div class="d-flex align-items-center" style="justify-content: flex-end;"
                  [ngClass]="{'disabled no-opacity' : disableEdit}">
                  <div class="d-flex" *ngIf="editableRowIndex ===i">
                    <img style="height: 13px;" class="cp"
                      [ngClass]="{'disabled':(costCentre.costCentreCode.trim() === '' || duplicateCostCentreCode )|| (costCentre.costCentreDesc.trim() ==='') || (costCentre.costCentreCode ===selectedRow?.costCentreCode && costCentre.costCentreDesc === selectedRow?.costCentreDesc)}"
                      src="./assets/images/tick-active.png" alt="tick" (click)="saveUpdateCostCentre(costCentre)" />
                    <i style="padding-left: 10px;" class="icon-circle-close cp" (click)="cancelEdit(costCentre)"></i>
                  </div>
                  <i style="font-size: 11px;" class="icon-pencil cp" *ngIf="editableRowIndex !==i"
                    (click)="editRow(i)"></i>
                  <app-toggle style="margin-left: 20px;margin-top: -10px"
                    [checked]="costCentre.statusName==='Active'? true:false"
                    (emitToggle)="statusToggleForCostCentre(costCentre.costCentreId,$event)"
                    [ngClass]="{'disabled':editableRowIndex ===i}"></app-toggle>
                  <i style="margin-left: 20px" class="icon-bin cp red" [ngClass]="{'disabled':editableRowIndex ===i}"
                    (click)="inlineDelete(costCentre.costCentreId)"></i>
                </div>
              </td>
            </ng-container>
          </tr>
          }
          @else if(listOfCostCentres.length === 0) {
          <tr class="h100">
            <td colspan="3" class="border-0">
              <div class="no-data" translate>common.nodata</div>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>

<app-hex-loader *ngIf="hexLoader"></app-hex-loader>

<div class="restrict-overlay" style="z-index: 2;left: -10px;top: -45px;" *ngIf="editableRowIndex > -1 "></div>

<!-- confirmation-popup-delete -->
<app-confirmation-popup *ngIf="isConfirmPopup !==''" [innerModal]="true" (emitEvent)="handleConfirmation($event)"
  [description]="isConfirmPopup ==='delete' ? 'common.confirmDelete' : 'common.cancelMsg' "></app-confirmation-popup>
