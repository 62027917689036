import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-wild-card-search',
    templateUrl: './wild-card-search.component.html',
    styleUrls: ['./wild-card-search.component.scss'],
    standalone: false
})
export class WildCardSearchComponent {
  @Input() searchKeyword = '';
  searchResults = [];
  searchSubject = new Subject<string>();
  @Output() emitSearchText = new EventEmitter();
  @Input() searchPlaceholder: string = '';
  @Input() lg: boolean = false;
  @Input() isLoader: boolean = false;
  @Input() width = '';

  constructor() {
    this.searchSubject.pipe(debounceTime(500)).subscribe(keyword => {
      if (keyword.trim() !== this.searchKeyword) {
        this.emitSearchText.emit(keyword.trim());
        this.searchKeyword = keyword;
      }
    });
  }

  onSearchKeywordChange(event: any) { this.searchSubject.next(event.target.value); }
  
}
