import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-export',
    templateUrl: './export.component.html',
    styleUrls: ['./export.component.scss'],
    standalone: false
})
export class ExportComponent {
  @Output() exportEvent = new EventEmitter();
  @Input() totalItems: number = 0;
}
