import moment from "moment";
import { auditTrackingParams } from "./audit-tracking-interface";

let getAzureServiceInstance: any;
let system_name: any;
let sub_system_name: any;
let getSharedService: any;

export function initiateAuditTrackingUtil(azureService: any, translate: any, sharedService: any, url: any, onRoute?: any) {
let commonParams = {};
getAzureServiceInstance = azureService;
getSharedService = sharedService
system_name = translate.instant('auditTrail.systemName');
sub_system_name = translate.instant('auditTrail.subSystemName');
}

export function auditTracking(clientName: any, entityName: any, screenName: any, getActionType: any, getDescription: any, activityId ?: any ) {
 
  let params = {
    'screenName' : screenName,
    'description': getDescription,
    'activityId': activityId,
    'entityName': entityName,
    'clientName': clientName,
    'actionType': getActionType
  }
  trackPageEventsForAudit(params);
}

export function trackPageEventsForAudit(commonParams: any) {
  let auditTrackCollection: any = [];
  let sourceDetails = {
    "tracing_id": getSharedService?.getDecodedToken() !== null ? getSharedService?.getDecodedToken()['tracingId']: '',
    "activity_id": commonParams.activityId ? (commonParams.activityId)?.trim() : "",
    "user_email_id": localStorage.getItem('principalId'),
    "client_name": commonParams.clientName ? commonParams.clientName : "NA",
    "entity_name": commonParams.entityName ? commonParams.entityName: "NA",
    "screen_name": (commonParams.screenName)?.trim(),
    "system_name": system_name,
    "sub_system_name": sub_system_name,
    "action_type": (commonParams.actionType)?.trim(),
    "action_description": (commonParams.description)?.trim(),
    "event_date": moment().utc().format('DD MMM YYYY'),
    "event_time": moment().utc().format('DD MMM YYYY HH:mm:ss')

  } as auditTrackingParams;
  auditTrackCollection.push(sourceDetails);
  getAzureServiceInstance?.eventsHubSendData(auditTrackCollection);
}