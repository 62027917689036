<div class="payruns">
    <div class="title mb-2">Payruns</div>

    <div class="table-wrapper">
        <table aria-describedby="entities-payruns">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Process Name</th>
                    <th>Period</th>
                    <th>Employees</th>
                    <th>Starters</th>
                    <th>Leavers</th>
                    <th>Payslips</th>
                    <th>Release Date</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr></tr>
            </tbody>
        </table>
    </div>
</div>
