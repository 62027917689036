<div class="department p-2 px-3">
  <div class="add-department" style="height: 65px;" [ngClass]="{'disabled no-opacity' : disableEdit}">
    <div class="form w100 row">
      <!-- department code -->
      <div class="form-group col-3 mb-0">
        <div class="d-flex justify-content-between align-items-center">
          <label for="" translate><span class="asterik">* </span>common.code</label>
          <div class="char-info">
            <strong>{{departmentCode.length}}</strong> / 256
            <span translate>common.chars</span>
          </div>
        </div>
        <input type="text" maxlength="256" [placeholder]="'entities.general.enterHere' | translate"
          [title]="departmentCode" [(ngModel)]="departmentCode"
          [ngClass]="
                    {'error': (duplicateCode && editableRowIndex === -1) || (duplicateCode && editableRowIndex === -1)}" required
          (input)="onInputChange('code', $event);duplicateCode=false" />
        <span [ngStyle]="{'visibility': duplicateCode && editableRowIndex === -1 ? 'visible' :'hidden'}" class="error"
          translate>entities.error.codeExist</span>
      </div>

      <!-- department description -->
      <div class="form-group col-6 pe-1 mb-0">
        <div class="d-flex justify-content-between align-items-center">
          <label for="" translate><span class="asterik">* </span>entities.general.description</label>
          <div class="char-info">
            <strong>{{departmentDescription.length}}</strong> / 256
            <span translate>common.chars</span>
          </div>
        </div>
        <input type="text" maxlength="256" [placeholder]="'entities.general.enterHere' | translate"
          [title]="departmentDescription" [(ngModel)]="departmentDescription" />
        <!-- [ngClass]="{'error': duplicateDescription && editableRowIndex === -1}" required
                    (input)="onInputChange('desc',$event);duplicateDescription=false"
                <span [ngStyle]="{'visibility': duplicateDescription && editableRowIndex === -1 ? 'visiblLe' :'hidden'}"
                    class="error" translate>entities.error.descriptionExist</span> -->
      </div>

      <div class="col-3 p-0">
        <button class="btn-link teal" style="padding-top: 22px" translate (click)="saveUpdateDepartment()"
          [ngClass]="{'disabled': (departmentCode.trim() === '' || departmentDescription.trim() === '') || ( duplicateCode)}">
          <i class=" icon-create"></i>common.add</button>
      </div>
    </div>
  </div>

  <div>
    <div class="title">
      <span><span translate>clients.added</span> ({{departmentList.length}})</span>
      <button class="btn-link ms-3" style="vertical-align: top;" *ngIf="departmentList.length"
        [ngClass]="{'disabled no-opacity' : disableEdit}" (click)="inlineDelete();">Remove All</button>
    </div>

    <div class="table-wrapper mt-2" style="height: calc(100vh - 390px);overflow-y: auto;">
      <table aria-describedby="departments" [ngClass]="{'loading':isLoader}">
        <thead>
          <tr>
            <th style="width: 35%">
              <span class="textoverflow" [title]="'entities.code' | translate" translate>common.code</span>
              <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
            </th>
            <th>
              <span class="textoverflow" [title]="'entities.description' | translate"
                translate>entities.general.description</span>
              <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
            </th>
            <th style="width: 150px">
              <span>&nbsp;</span>
              <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="!isLoader">
          @if(departmentList.length > 0 )
          {
          <tr *ngFor="let department of departmentList; let i = index" [ngClass]="{'edit-row': editableRowIndex ===i}">
            <ng-container>
              <td [title]="department.departmentCode" style="width: 35%;">
                <div class="input-container" *ngIf="editableRowIndex ===i;else departmentCode"><input
                    name="departmentCode" required maxlength="256" (input)="onInputChange('code',$event)"
                    [(ngModel)]="department.departmentCode" data-trigger="hover"
                    [ngbTooltip]="department.departmentCode === '' ? ('common.error.required' | translate): duplicateCode && editableRowIndex === i ? ('entities.error.codeExist' | translate) : ''"
                    [ngClass]="{'border-error':department.departmentCode.trim() === '' || (duplicateCode && editableRowIndex === i)}" />
                </div>
                <ng-template #departmentCode>
                  <div class="textoverflow">{{department.departmentCode}}</div>
                </ng-template>
              </td>
              <td [title]="department.departmentDescription">
                <div class="input-container" *ngIf="editableRowIndex ===i;else departmentDescription">
                  <input name="departmentDescription" required maxlength="256" (input)="onInputChange('desc',$event)"
                    [(ngModel)]="department.departmentDescription" data-trigger="hover"
                    [ngbTooltip]="department.departmentDescription === '' ? ('common.error.required' | translate) : ''"
                    [ngClass]="{'border-error': department.departmentDescription.trim() === ''}" />
                </div>
                <ng-template #departmentDescription>
                  <div class="textoverflow">{{department.departmentDescription}}</div>
                </ng-template>
              </td>
              <td class="icons" style="width: 150px;">
                <div class="d-flex align-items-center" style="justify-content: flex-end;"
                  [ngClass]="{'disabled no-opacity' : disableEdit}">
                  <div class="d-flex" *ngIf="editableRowIndex ===i">
                    <img style="height: 13px;" class="cp"
                      [ngClass]="{'disabled':(department.departmentCode.trim() === '' || duplicateCode )|| (department.departmentDescription.trim() ==='') || (department.departmentCode === selectedRow.departmentCode && department.departmentDescription === selectedRow.departmentDescription)}"
                      src="./assets/images/tick-active.png" alt="tick" (click)="saveUpdateDepartment(department);" />
                    <i style="padding-left: 10px;" class="icon-circle-close cp" (click)="cancelEdit(department)"></i>
                  </div>
                  <i style="font-size: 11px;" class="icon-pencil cp" *ngIf="editableRowIndex !==i"
                    (click)="editRow(i)"></i>
                  <app-toggle style="margin-left: 20px;margin-top: -10px"
                    [checked]="department.status==='Active'? true:false"
                    (emitToggle)="statusToggleDepartment(department.departmentId,$event)"
                    [ngClass]="{'disabled':editableRowIndex ===i}"></app-toggle>
                  <i style="margin-left: 20px" class="icon-bin cp red" [ngClass]="{'disabled':editableRowIndex ===i}"
                    (click)="inlineDelete(department.departmentId)"></i>
                </div>
              </td>
            </ng-container>
          </tr>
          }
          @else if(departmentList.length === 0) {
          <tr class="h100">
            <td colspan="3" class="border-0">
              <div class="no-data" translate>common.nodata</div>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>

<app-hex-loader *ngIf="hexLoader"></app-hex-loader>

<div class="restrict-overlay" style="z-index: 2;left: -10px;top: -45px;" *ngIf="editableRowIndex > -1 "></div>

<!-- confirmation-popup-delete -->
<app-confirmation-popup *ngIf="isConfirmPopup !==''" [innerModal]="true" (emitEvent)="handleConfirmation($event)"
  [description]="isConfirmPopup ==='delete' ? 'common.confirmDelete' : 'common.cancelMsg' "></app-confirmation-popup>
