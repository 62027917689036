<app-header *ngIf="loggedIn"></app-header>

<div class="modal idletimeout-popup" style="position: absolute; z-index: 9999;"
  [ngClass]="{'inside-modal':false, 'inline': true}" *ngIf="idleTimeoutPopup && !url?.includes('/logout')">
  <div class="modal-dialog mt-0">
    <div class="modal-content sm pt-3" style="top: 120px;width: 100% !important;">
      <div class="modal-header mb-2">
        <div class="modal-title idle-timeout-title" translate>idleTimeout.warning</div>
      </div>
      <hr>
      <div class="modal-body title" style="font-size: 12px;">
        <p><span translate>idleTimeout.countdownText</span> {{idleTimeoutCountDown}}</p>
      </div>
      <hr>
      <div class="modal-footer mx-auto">
        <button class="btn-primary idletimeout1" tabindex="0" (click)="resetIdle('click');idleTimeoutPopup=false;"
          translate>idleTimeout.continue</button>
        <button class="btn-primary idletimeout2" (click)="onLogout();idleTimeoutPopup=false;"
          translate>auditTrail.logout</button>
      </div>
    </div>
  </div>
</div>

<div class="restrict-overlay" *ngIf="idleTimeoutPopup && !url?.includes('/logout')"></div>
<router-outlet></router-outlet>

<app-prof-user-clients *ngIf="tmfUserClients"></app-prof-user-clients>

<app-hex-loader *ngIf="hexLoader"></app-hex-loader>
