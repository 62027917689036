import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Access, Auth, TmfUserLogin, UserPreference, Notification } from '../../core/constants/endpoint';
import { Router } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { CryptoService } from '../../shared/services/crypto/crypto.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userClientsSubject = new BehaviorSubject<any>(false);
  emitSelectUserClientForTmfUser = new BehaviorSubject<any>(false);
  userDetails: any;
  updateCilentBasedSubject = new BehaviorSubject<any>(false);
  showLoginLoader = new BehaviorSubject<any>(false);
  compWindow: any;
  decodedToken: any;

  constructor(private http: HttpClient, private appService: AppService, private router: Router, private idle: Idle, private crypto: CryptoService, private toastr: ToastrService) {
    this.tmfUserLogin();
    this.compWindow = window;
  }

  getAuthenticationToken() {
    window.localStorage.setItem("token", '');
    window.location.href = environment.login_url + Auth.saml_auth_endpoint_url;
  }

  validateToken(body: any): Observable<any> {
    return this.http.post(environment.login_url + Auth.validate_token_url, body);
  }

  getUserDetails(): Observable<any> {
    return this.http.get(environment.url + Access.users.userDetail);
  }

  getUserSettings(): Observable<any> {
    return this.http.post(environment.url + UserPreference.userSettings, { settingName: "USER_PROFILE", accountDirectProfileSelect: false });
  }

  logout(action?: string) {
    let language = localStorage.getItem('language');
    this.idle.stop();
    this.clearToken().subscribe({
      next: (response) => { },
      error: (error) => { }
    });
    localStorage.clear();
    sessionStorage.clear();
    this.appService.setUserLoggedIn(false);

    if (action) { this.router.navigate(['unauthorized-access']); }
    else { this.router.navigate(['logout']); }

    localStorage.setItem('language', language ?? '');
  }

  activateUser(token: string): Observable<any> {
    return this.http.get(environment.url + Access.users.activateUser + '?token=' + token)
  }

  clearToken(): Observable<any> {
    return this.http.get(environment.login_url + Auth.saml_clearToken);
  }

  getConnectionStringForClickstream() {
    return this.http.get(environment.url + 'clickstream/getCS')
  }

  getUserType(): Observable<any> {
    return this.http.get(environment.url + TmfUserLogin.getUserType);
  }

  getUserClients(searchTerm: any): Observable<any> {
    return this.http.get(environment.url + TmfUserLogin.getUserClients + '?searchTerm=' + searchTerm);
  }

  profUserCache(clientId: string): Observable<any> {
    return this.http.get(environment.url + TmfUserLogin.profUserCache + clientId);
  }

  getUserCache(userId: string): Observable<any> {
    return this.http.get(environment.url + TmfUserLogin.userCache + userId);
  }

  tmfUserLogin() {
    this.emitSelectUserClientForTmfUser.subscribe(res => {
      if (res) {
        this.showLoginLoader.next(true);
        this.updateCilentBasedSubject.next(false);
        this.userClientsSubject.next(false);
        this.getUserDetailsInfo();
      }
    });
  }

  adminUserCache() {
    this.updateCilentBasedSubject.next(false);
    if (!this.userDetails) { this.userDetails = JSON.parse(this.crypto.decrypt(localStorage.getItem('user-details') ?? '')); }

    this.getUserCache(this.userDetails?.userId).subscribe({
      next: res => { this.getUserDetailsInfo(); },
      error: error => { }
    })
  }

  getUserDetailsInfo(activityType?: any, keyId?: any) {
    this.showLoginLoader.next(true);

    this.getUserDetails().subscribe({
      next: res => {
        this.userDetails = res.data;
        if ((this.userDetails?.userType === 'super_admin' && res.data?.status === 'Active') ||
          (res.data?.status === 'Active' && res.data?.isDeleted === 0)) {

          localStorage.setItem('user-details-object', JSON.stringify(res?.data));
          localStorage.setItem('user-details', this.crypto.encrypt(JSON.stringify(res?.data)));

          this.getConnectionString();
          this.getUserSettingsInfo();
        }
        if (this.userDetails?.status === 'InActive' || this.userDetails?.isDeleted === 1) { this.showLoginLoader.next(false); }
        if (activityType) { this.gotoActivityDetails(activityType, keyId); }
        return this.userDetails;
      }, error: error => {
        this.showLoginLoader.next(false);
        this.userDetails = { ...this.userDetails, status: 'InActive' };
        return this.userDetails;
      }
    })
  }

  getUserSettingsInfo() {
    this.getUserSettings().subscribe({
      next: res => {
        localStorage.setItem('user-settings', JSON.stringify(res.data));
        localStorage.setItem('language', res.data?.settingValueJson?.languageCode);

        if (localStorage.getItem('top-nav-route')) {
          if (localStorage.getItem('top-nav-route')?.includes('admin')) { this.adminRouting(); }
          else { this.router.navigate([localStorage.getItem('top-nav-route')]); }
          if (localStorage.getItem('top-nav-route') === 'workflow') { this.updateCilentBasedSubject.next(true); }
          localStorage.removeItem('top-nav-route');
        } else {
          if (this.router.url?.includes('login') || this.router.url?.includes('-preferences')) { this.routeOnLogin(); }
          else {
            if (this.router.url === '/workflow' || this.router.url === '/my-workspace') { this.updateCilentBasedSubject.next(true); }
          }
        }
        this.appService.setUserLoggedIn(true);
        this.showLoginLoader.next(false);
      }, error: error => { this.showLoginLoader.next(false); }
    })
  }

  routeOnLogin() {
    if (this.userDetails?.userType === 'super_admin') {
      if (this.userDetails?.functionalities != '') { this.router.navigate(['/entities']); }
      else { this.logout(); }
    }
    else if (this.userDetails?.userType === 'tmf_admin') { this.tmfAdminRouting(); }
    else { this.router.navigate(['/my-workspace']); }
  }

  getFunctionality(functionality: string) { return this.userDetails.functionalities.findIndex((it: any) => it.functionalityName === functionality) > -1; }

  tmfAdminRouting() {
    if (this.getFunctionality('Entities')) { this.router.navigate(['/entities']); }
    else if (this.getFunctionality('Employees')) { this.router.navigate(['/employees']); }
    else if (this.getFunctionality('Tickets') || this.getFunctionality('Tasks')) {
      localStorage.setItem('top-nav-route', 'workflow');
      this.userClientsSubject.next(true);
    } else if (this.getFunctionality('Clients')) { this.router.navigate(['/admin/clients']); }
    else if (this.getFunctionality('User Management') || this.getFunctionality('Role Management')) { this.router.navigate(['/admin/access']); }
  }

  adminRouting() {
    if (this.getFunctionality('Clients')) { this.router.navigate(['/admin/clients']); }
    else if (this.getFunctionality('User Management') || this.getFunctionality('Role Management')) { this.router.navigate(['/admin/access']); }
  }

  getConnectionString(): any {
    this.getConnectionStringForClickstream().subscribe({
      next: (res: any) => {
        window.localStorage.setItem('clickstream', res?.data);
      }, error: error => { }
    })
  }

  gotoActivityDetails(activityType: string, keyId: number) {
    switch (activityType) {
      case 'Task':
        this.router.navigate(['/my-workspace']).then(() => {
          this.router.navigate(['workflow/action-detail'], { state: { emailRedirection: keyId, tab: "Actions" } });
        });
        break;
      case 'Ticket':
        this.router.navigate(['/my-workspace']).then(() => {
          this.router.navigate(['workflow/request-detail'], { state: { emailRedirection: keyId, tab: "Requests" } });
        });
        break;
      case 'Case':
        this.router.navigate(['/my-workspace']).then(() => {
          this.router.navigate(['workflow/activity-detail'], { state: { emailRedirection: keyId, tab: "Activities" } });
        });
        break;
    }
  }

  fetchUserTask(body: any): Observable<any> {
    return this.http.post(environment.workflow_url + Notification.fetchUserTask, body);
  }

  getUserTaskByGuid(body: any, userType: any) {
    this.fetchUserTask(body).subscribe({
      next: res => {
        var keyId: number = res?.data?.keyId;
        var activityType: any = res?.data?.activityType;
        var clientId: any = res?.data?.clientId;
        if (res.message == "The link has expired.") {
          this.toastr.error(res.message);
          window.localStorage.removeItem('redirectUrl');
          return;
        }

        if (userType !== 'client_user') {
          this.profUserCache(clientId).subscribe(() => {
            this.getUserDetailsInfo(activityType, keyId);
          });
        } else {
          // this.gotoActivityDetails(activityType, keyId);
          this.getUserDetailsInfo(activityType, keyId);
        }
      }, error: error => { }
    });
  }

}
