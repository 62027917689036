<div class="activate-user">
    <img height="80px" src="./assets/images/logo.png" alt="logo" />

    <div class="card sm">
        <ng-container *ngIf="!infoMesssage; else login">
            <div class="title text-center mb-3">Please click on the button below to complete your account activation:</div>
            <button class="btn-primary" (click)="activateUser()">Activate User</button>
        </ng-container>
        <ng-template #login>
            <div class="title mb-3">{{infoMesssage}}</div>
            <button class="btn-primary" routerLink="/login">Click here to Login</button>
        </ng-template>
    </div>
</div>

<app-hex-loader *ngIf="hexLoader"></app-hex-loader>