<div class="job-band p-2 px-3">
  <div class="add-job" style="height: 65px;" [ngClass]="{'disabled no-opacity' : disableEdit}">
    <div class="d-flex align-items-end">
      <div class="w50">
        <div class="char-info text-end">
          <strong>{{jobBandName.length}}</strong> / 256
          <span translate>common.chars</span>
        </div>

        <input type="text" [placeholder]="'entities.general.enterHere' | translate" [title]="jobBandName"
          maxlength="256" [(ngModel)]="jobBandName" (input)="onInputChange($event)"
          [ngClass]="{'error': (duplicateJobBand === 1 && editableRowIndex === -1)}" />
      </div>
      <button class="btn-link teal" translate (click)="saveUpdateJobBand()"
        [ngClass]="{'disabled': !jobBandName.trim() || duplicateJobBand !== 0}">
        <i class="icon-create"></i>common.add
      </button>
    </div>
    <span [ngStyle]="{'visibility': duplicateJobBand === 1 && editableRowIndex === -1 ? 'visible' :'hidden'}"
      class="error" translate>entities.jobBands.error.jobBandExist</span>
  </div>

  <div>
    <div class="title"><span translate>clients.added</span> ({{jobBandList.length}})</div>
    <div class="table-wrapper mt-2" style="height: calc(100vh - 390px);overflow-y: auto;">
      <table aria-describedby="job-title" [ngClass]="{'loading':isLoader}">
        <thead>
          <tr>
            <th>
              <span class="textoverflow" [title]="'entities.jobBand' | translate" translate>entities.jobBand</span>
              <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
            </th>
            <th style="width: 150px;">
              <span>&nbsp;</span>
              <ngx-skeleton-loader count="5" *ngIf="isLoader"></ngx-skeleton-loader>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="!isLoader">
          @if(jobBandList.length > 0 )
          {
          <tr *ngFor="let job of jobBandList; let i = index" [ngClass]="{'edit-row': editableRowIndex ===i}">
            <ng-container>
              <td [title]="job.jobBandName">
                <div class="input-container" *ngIf="editableRowIndex ===i;else jobBand"><input name="jobName" required
                    maxlength="256" [(ngModel)]="job.jobBandName" (input)="onInputChange($event)" data-trigger="hover"
                    [ngbTooltip]="job.jobBandName === '' ? ('common.error.required' | translate):
                     duplicateJobBand === 1 && editableRowIndex  === i ? ('entities.jobBands.error.jobBandExist' | translate) : ''"
                    [ngClass]="{'border-error':job.jobBandName.trim() === '' || (duplicateJobBand === 1 && editableRowIndex === i)}" />
                </div>
                <ng-template #jobBand>
                  <div class="textoverflow">{{job.jobBandName}}</div>
                </ng-template>
              </td>
              <td class="icons" style="width: 150px;">
                <div class="d-flex align-items-center justify-content-end"
                  [ngClass]="{'disabled no-opacity' : disableEdit}">
                  <div class="d-flex" *ngIf="editableRowIndex ===i">
                    <img style="height: 13px;" class="cp"
                      [ngClass]="{'disabled':(selectedRow.jobBandName === job.jobBandName || (job.jobBandName.trim() === '' || duplicateJobBand === 1))}"
                      src="./assets/images/tick-active.png" alt="tick" (click)="saveUpdateJobBand(job);" />
                    <i style="padding-left: 10px;" class="icon-circle-close cp" (click)="cancelEdit(job)"></i>
                  </div>
                  <i style="font-size: 11px;" class="icon-pencil cp" *ngIf="editableRowIndex !==i"
                    (click)="editRow(i)"></i>
                  <app-toggle style="margin-left: 20px;margin-top: -10px;" [checked]="job.statusName==='Active'"
                    (emitToggle)="statusToggleForJobBandName($event,job.jobBandId)"
                    [ngClass]="{'disabled':editableRowIndex ===i}"></app-toggle>
                  <i style="margin-left: 20px" class="icon-bin cp red" [ngClass]="{'disabled':editableRowIndex ===i}"
                    (click)="inlineDelete(job.jobBandId)"></i>
                </div>
              </td>
            </ng-container>
          </tr>
          }
          @else if(jobBandList.length === 0) {
          <tr class="h100">
            <td colspan="3" class="border-0">
              <div class="no-data" translate>common.nodata</div>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>

<app-hex-loader *ngIf="hexLoader"></app-hex-loader>

<div class="restrict-overlay" style="z-index: 2;left: -10px;top: -45px;" *ngIf="editableRowIndex > -1 "></div>

<!-- confirmation-popup-delete -->
<app-confirmation-popup *ngIf="isConfirmPopup !==''" [innerModal]="true" (emitEvent)="handleConfirmation($event)"
  [description]="isConfirmPopup ==='delete' ? 'common.confirmDelete' : 'common.cancelMsg' "></app-confirmation-popup>
