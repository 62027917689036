import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[focusOnClick]'
})
export class FocusOnClickDirective {

  constructor(private elementRef: ElementRef) { }

  @HostListener('click', ['$event.target'])
  onClick(btn: any) {
    const input = this.elementRef.nativeElement.querySelector('.filter-textbox > input');
    if (input) {
      input.focus();
    }
  }
}