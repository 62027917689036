<div class="logo-format popup arrow" [ngClass]="{'align-middle' : alignMiddle}">
    <label for="" translate>clients.logoFormat</label>
    <ul>
        <li>
            <img src="./assets/images/completed.png" alt="completed" />
            <div>
                <img src="./assets/images/best-fit-logo.png" alt="best-fit-logo" />
            </div>
            <p translate>clients.bestFit</p>
        </li>
        <li>
            <img src="./assets/images/close.png" alt="close" />
            <div><img src="./assets/images/skewed-logo.png" alt="skewed-logo" /></div>
            <p translate>clients.skewed</p>
        </li>
    </ul>
    <p class="gray-bg note p-2 mt-3" translate>clients.logoInfo</p>
</div>