import { ToastrService } from 'ngx-toastr';
import { Component, Input, OnInit } from '@angular/core';
import { EntitiesService } from '../../../../../modules/entities/services/entities.service';
import { StatusCodeService } from './../../../../services/status-code/status-code.service';
import { Subject, debounceTime } from 'rxjs';
import { auditTracking } from '../../../../../utils/audit-tracking';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrl: './department.component.scss'
})
export class DepartmentComponent implements OnInit {
  @Input() selectedEntity: any;
  @Input() disableEdit: boolean = false;
  departmentList: any[] = [];
  hexLoader: boolean = false;
  editableRowIndex: number = -1;
  selectedRow: any = {};
  isLoader: boolean = true;
  isConfirmPopup: string = '';
  departmentIds: any[] = [];
  departmentCode: string = '';
  departmentDescription: string = '';
  duplicateCode: boolean = false;
  // duplicateDescription: boolean = false;
  departmentCheckSubject = new Subject<any>();
  fieldType: string = '';

  constructor(private entitiesService: EntitiesService, private toastr: ToastrService, private showStatusCode: StatusCodeService,
    private translate: TranslateService) {
    this.duplicateCheck();
  }

  ngOnInit() {
    this.getDeparments();
  }

  getDeparments() {
    this.entitiesService.getDepartment(this.selectedEntity?.entityId).subscribe({
      next: res => {
        if (res.data) {
          this.departmentList?.push(...res.data);
        }
        this.disableloader();

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.viewDepartment');
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('auditTrail.auditTypeView'),
          description)

      },
      error: error => {
        this.disableloader();
      }
    });
  }

  saveUpdateDepartment(department?: any) {
    // && !this.duplicateDescription
    if (!this.duplicateCode) {
      this.toastr.clear();
      this.hexLoader = true;
      let body = {
        departmentId: department ? department.departmentId : null,
        departmentCode: department ? department.departmentCode.trim() : this.departmentCode.trim(),
        departmentDescription: department ? department.departmentDescription?.trim() : this.departmentDescription.trim(),
        entityId: this.selectedEntity?.entityId
      };
      this.entitiesService.saveUpdateDepartment(body).subscribe({
        next: (res) => {
          this.hexLoader = false;
          if (res?.statusCode === 'UNAUTHORIZED_USER') {
            this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
          } else {
            this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', department ? 'common' : 'entities.departments');
          }

          if (department) {
            let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.editDepartment');

            auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
              this.translate.instant('auditTrail.entities.entityDetail'),
              this.translate.instant('common.edit'),
              description)

          } else {
            let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.addDepartment');

            auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
              this.translate.instant('auditTrail.entities.entityDetail'),
              this.translate.instant('common.add'),
              description)
          }

          this.resetDepartments();
        },
        error: (error) => {
          this.hexLoader = false;
        }
      });
    }
  }

  resetDepartments() {
    if (this.editableRowIndex === -1) { this.departmentCode = ''; }
    this.isLoader = true;
    this.departmentList = [];
    this.editableRowIndex = -1;
    this.departmentDescription = '';
    this.duplicateCode = false;
    // this.duplicateDescription = false;
    this.getDeparments();
  }

  editRow(index: number) {
    this.duplicateCode = false;
    // this.duplicateDescription = false;
    this.editableRowIndex = index;
    this.selectedRow = JSON.parse(JSON.stringify(this.departmentList[index]));
  }

  disableloader() {
    if (this.isLoader) { this.isLoader = false; }
    if (this.hexLoader) { this.hexLoader = false; }
  }

  cancelEdit(department: any) {
    if (this.selectedRow?.departmentCode !== department?.departmentCode || this.selectedRow?.departmentDescription !== department?.departmentDescription) {
      this.isConfirmPopup = 'edit';
    } else {
      this.editableRowIndex = -1;
      this.selectedRow = {};
    }
    this.duplicateCode = false;
    // this.duplicateDescription = false;
  }

  deleteDepartment() {
    this.toastr.clear();
    this.hexLoader = true;
    let body = {
      "departmentId": this.departmentIds,
      "entityId": this.selectedEntity?.entityId
    }
    this.entitiesService.deleteDepartment(body).subscribe({
      next: res => {
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', 'entities.departments');
        }
        this.hexLoader = false;

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.deleteDepartment');

        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('common.delete'),
          description)

        this.resetDepartments();
      }, error: error => {
        this.hexLoader = false;
      }
    })
  }

  inlineDelete(id?: string) {
    this.isConfirmPopup = 'delete';
    if (id) {
      this.departmentIds = [id];
    } else {
      this.departmentIds = this.departmentList.map(it => { return it.departmentId });
    }
  }

  handleConfirmation(ev: boolean) {
    if (ev) {
      if (this.isConfirmPopup === 'edit') {
        this.resetEditRow();
      } else {
        this.deleteDepartment();
      }
    }
    this.isConfirmPopup = '';
    this.duplicateCode = false;
    // this.duplicateDescription = false;
  }

  resetEditRow() {
    this.departmentList[this.editableRowIndex].departmentCode = this.selectedRow.departmentCode;
    this.departmentList[this.editableRowIndex].departmentDescription = this.selectedRow.departmentDescription;
    this.editableRowIndex = -1;
    this.selectedRow = {};
  }

  statusToggleDepartment(departmentId: string, event: boolean) {
    this.toastr.clear();
    this.hexLoader = true;
    let body = {
      "id": departmentId,
      "entityId": this.selectedEntity?.entityId,
      "status": event ? 'ACTIVE' : 'INACTIVE'
    }
    this.entitiesService.switchDepartment(body).subscribe({
      next: res => {
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', 'entities.departments');
        }
        this.hexLoader = false;

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.editDepartment');

        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('common.edit'),
          description)

        this.resetDepartments();
      }, error: error => {
        this.hexLoader = false;
      }
    })
  }

  uniqueDepartmentCheckAPI(fieldName: any, type: string) {
    let body = {
      entityId: this.selectedEntity?.entityId,
      text: fieldName,
      type: type
    }
    this.hexLoader = true;
    this.entitiesService.uniqueCheckDepartment(body).subscribe({
      next: res => {
        this.hexLoader = false;
        if (this.fieldType === 'code') {
          this.duplicateCode = res.data;
        } else if (this.fieldType === 'desc') {
          // this.duplicateDescription = res.data;
        }
      }, error: error => {
        this.hexLoader = false;
      }
    })
  }

  onInputChange(type: string, event: any) {
    this.fieldType = type;
    this.departmentCheckSubject.next(event.target.value.trim() || '');
  }

  duplicateCheck() {
    this.departmentCheckSubject.pipe(debounceTime(500)).subscribe(
      keyword => {
        if (keyword != '') {
          if (keyword === (this.selectedRow.deparmentCode || this.selectedRow.departmentDescription)) {
            return;
          } else {
            this.uniqueDepartmentCheckAPI(keyword, this.fieldType)
          }
        }
      }
    )
  }
}
