import { UserPermissionsService } from './../../../services/user-permissons/user-permissions.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { EntitiesService } from '../../../../modules/entities/services/entities.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { SharedService } from '../../../services/shared/shared.service';
import { StatusCodeService } from '../../../services/status-code/status-code.service';
import * as moment from 'moment';
import { auditTracking } from '../../../../utils/audit-tracking';
import { ToastrService } from 'ngx-toastr';
import * as fileSaver from 'file-saver';

@Component({
    selector: 'app-pay-elements',
    templateUrl: './pay-elements.component.html',
    styleUrl: './pay-elements.component.scss',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PayElementsComponent implements OnInit, OnDestroy {
  @Input() selectedEntityClient: any;
  @Input() selectedEntity: any;
  createPayElement: any;
  body: any = { "entityId": "", "pageNumber": 1, "pageSize": 100, "sortOrder": "asc", "sortBy": "payElementCode", "searchTerm": '' };
  isLoader: boolean = true;
  payElements: any[] = [];
  totalPayElements: number = 0;
  hexLoader: boolean = false;
  wageGroupItems: [] = [];
  elementTypeItems: [] = [];
  selectedWageGroup: any[] = [];
  selectedPayElement: any[] = [];
  isConfirmPopupForDelete: boolean = false;
  isConfirmPopupForEdit: boolean = false;
  editableRowIndex: any;
  selectedIds: string[] = [];
  isSelectAll: boolean = false;
  englishNameDuplicate: boolean = false;
  payElementCodeDuplicate: boolean = false;
  localNameDuplicate: boolean = false;
  ngbToolTipMessage: any = null;
  ngbToolTipPayelementcode: any;
  ngbToolTipLocalName: any;
  ngbToolTipEnglishName: any;
  selectedRow: any = {};
  cancelConfirmationValue: boolean = false;
  isRowChanged: boolean = false;
  @Input() disableEdit: boolean = false;
  @Output() enabledAddPayElement = new EventEmitter();
  private payElementsUniqueCheckSubject: { [key: string]: Subject<string> } = {};
  private payElementsUniqueCheckSubscription: { [key: string]: Subscription } = {};
  inlineDeletePayElement: any;
  @Input() clone: boolean = false;
  @Input() cloneSaved: boolean = false;
  doneSub: any;
  importFiles: boolean = false;
  formData: any;

  wageGroupSettings: IDropdownSettings = {
    enableCheckAll: true, selectAllText: '', searchPlaceholderText: this.translate.instant('common.search'), clearSearchFilter: true, closeDropDownOnSelection: false,
    idField: 'wageGroupId', textField: 'wageGroupName', allowSearchFilter: true, unSelectAllText: '', itemsShowLimit: 0,
    noDataAvailablePlaceholderText: this.translate.instant('common.nodata'), noFilteredDataAvailablePlaceholderText: this.translate.instant('common.nodata')
  };

  elementTypeSettings: IDropdownSettings = {
    enableCheckAll: true, selectAllText: '', searchPlaceholderText: this.translate.instant('common.search'), clearSearchFilter: true, closeDropDownOnSelection: false,
    idField: 'payElementTypeId', textField: 'elementTypeCode', allowSearchFilter: true, unSelectAllText: '', itemsShowLimit: 0,
    noDataAvailablePlaceholderText: this.translate.instant('common.nodata'), noFilteredDataAvailablePlaceholderText: this.translate.instant('common.nodata')
  };

  constructor(private entitiesService: EntitiesService, private translate: TranslateService, private sharedService: SharedService,
    private statusCodeService: StatusCodeService, private userPermissionsService: UserPermissionsService, private toastr: ToastrService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['disableEdit']?.firstChange && this.disableEdit) {
      this.selectedIds = [];
      this.isSelectAll = false;
    }
  }

  ngOnInit(): void {
    this.body = { ...this.body, entityId: this.selectedEntity?.entityId };
    this.fetchPayElementsList();
    this.getWageGroups();
    this.getPayElementTypes();
    this.addDoneButton();
    this.doneSub = this.sharedService.selectedDropdownItem.subscribe((value: any) => {
      if (value && value.module === '.pay-elements-footer') {
        this.doneButtonClick(value.selectedClass);
      }
    });
  }

  receivedFiles(files: any) {
    this.formData = new FormData();
    this.formData.append('file', files);
    this.formData.append('entityId', this.selectedEntity?.entityId);
    this.fileUpload(files);
  }

  fileUpload(files: any) {
    this.hexLoader = true;
    this.entitiesService.fetchExcelData(this.formData).subscribe({
      next: res => {
        this.hexLoader = false;

        switch (res.statusCode) {
          case 'COBALT_002_093':
            const description = ('[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.importPayElements') + '[' + files?.name + ']');
            auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
              this.translate.instant('auditTrail.entities.entityDetail'),
              this.translate.instant('common.import'),
              description)
            this.statusCodeService.displayToastrMessage(res.statusCode, 'success', 'entities')
            this.resetPayElements();
            break;

          case 'COBALT_002_092':
            const foundError = res.data.errors.find((err: any) => {
              const error = this.translate.instant('entities.error.codeisAlreadyExists')
              return err.errorMessage.includes(error);
            });

            if (foundError) {
              this.toastr.error(this.translate.instant('entities.error.combinationExists'));
            } else {
              this.statusCodeService.displayToastrMessage(res.statusCode, 'error', 'entities');
            }

            const rows = res.data.errors.map((error: any) => {
              return {
                slNum: error.slNum,
                errorMessage: error.errorMessage
              }
            });
            const errorLog = this.translate.instant('entities.errorLog');
            const date = new Date();
            const columns = [
              `${errorLog} - ${this.formatDate(date)}`,
              " "
            ];
            this.sharedService.exportToExcel(columns, rows, 'errors');
            break;

          case 'COBALT_002_091':
          case 'UNAUTHORIZED_USER':
            this.statusCodeService.displayToastrMessage(res.statusCode, 'error', 'entities');
            break;

          case 'COBALT_002_100':
          case 'COBALT_002_103':
          case 'COBALT_002_101':
          case 'COBALT_002_102':
            this.toastr.error(res.message);
            break;
        }
      }, error: err => {
        this.hexLoader = false;
      }
    })
  }

  formatDate(date: any) { return date && moment.utc(date).format('YYYY MM DD HH:mm:ss'); }

  getActions(action: string) { return this.userPermissionsService.isActions('Entities', action); }

  fetchPayElementsList() {
    this.entitiesService.filterPayElementsList(this.body).subscribe({
      next: (res) => {
        if (res?.data?.content) this.payElements.push(...res.data.content);
        this.totalPayElements = res?.data?.totalElements;
        // audit tracking
        if (this.body.searchTerm != '') {
          this.setAuditTracking('auditTrail.entities.searchPayElements', 'auditTrail.entities.entityDetail', 'auditTrail.auditTypeSearch');
        } else {
          this.setAuditTracking('auditTrail.entities.viewPayElements', 'auditTrail.entities.entityDetail', 'auditTrail.auditTypeView');
        }
        this.disableloader();
      },
      error: (error) => {
        this.disableloader();
      }
    })
  }
  setAuditTracking(actionDesc: string, processName: string, action: string) {
    const description = ('[' + this.selectedEntity?.entityName + ']' + this.translate.instant(actionDesc)) + (this.body.searchTerm ? ('[' + this.body.searchTerm + ']') : '');
    auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
      this.translate.instant(processName),
      this.translate.instant(action),
      description)
  }

  getWageGroups() {
    this.entitiesService.getWageGroups().subscribe({
      next: (res) => {
        this.wageGroupItems = res.data;
      },
      error: (error) => { }
    })
  }

  getPayElementTypes() {
    this.entitiesService.getPayElementTypes().subscribe({
      next: (res) => {
        this.elementTypeItems = res.data;
      },
      error: (error) => { }
    })
  }

  disableloader() {
    if (this.isLoader) { this.isLoader = false; }
    if (this.hexLoader) { this.hexLoader = false; }
  }

  sortPayElements(fieldName: string) {
    if (!this.isLoader) {
      if (this.body.sortBy == fieldName) {
        this.body.sortOrder = this.body.sortOrder == 'desc' ? 'asc' : 'desc';
      } else {
        this.body.sortBy = fieldName;
        this.body.sortOrder = 'asc';
      }
      this.resetPayElements();
    }
  }

  receivedSearch(text: string) {
    if (!this.isLoader) {
      this.body = { ...this.body, searchTerm: text };
      this.resetPayElements();
    }
  }

  resetPayElements() {
    this.body.pageNumber = 1;
    this.isSelectAll = false;
    this.inlineDeletePayElement = null;
    this.selectedIds = [];
    this.totalPayElements = 0;
    this.isLoader = true;
    this.payElements = [];
    this.fetchPayElementsList();
  }

  onScroll() {
    if (this.payElements.length < this.totalPayElements) {
      this.body.pageNumber += 1;
      this.hexLoader = true;
      this.fetchPayElementsList();
      this.isSelectAll = false;
    }
  }

  updateParamsAndFilterPayElementList(selectedDropdown: string) {
    if ((selectedDropdown === 'wage-group' || selectedDropdown === 'All')) {
      this.body['payElementWageGroupId'] = this.selectedWageGroup.map(it => { return it.wageGroupId });
    }

    if ((selectedDropdown === 'pay-elements' || selectedDropdown === 'All')) {
      this.body['payElementTypeId'] = this.selectedPayElement.map(it => { return it.payElementTypeId });
    }

    this.resetPayElements();
  }

  addDoneButton() {
    let dropDownList = ['wage-group', 'pay-elements'];
    let classNameToTarget = '.pay-elements-footer';
    this.sharedService.addDoneButton(dropDownList, classNameToTarget);
  }

  doneButtonClick(selectedClass: any) {
    if (selectedClass === 'wage-group') {
      this.updateParamsAndFilterPayElementList('wage-group');
      this.wageGroupSettings = { ...this.wageGroupSettings, defaultOpen: false };
    } else if (selectedClass === 'pay-elements') {
      this.updateParamsAndFilterPayElementList('pay-elements');
      this.elementTypeSettings = { ...this.elementTypeSettings, defaultOpen: false };
    }
  }

  resetDropdownFilter() {
    if (!this.isLoader) {
      this.isSelectAll = false;
      this.selectedIds = [];
      this.selectedWageGroup = [];
      this.selectedPayElement = [];
      this.body.searchTerm = '';
      this.updateParamsAndFilterPayElementList('All');
    }
  }

  handleConfirmation(ev: boolean) {
    if (this.isConfirmPopupForEdit) {
      if (ev) {
        this.resetEditRow(ev);
      }
      this.isConfirmPopupForEdit = false;
    } else {
      if (ev) {
        this.deletePayElements();
      } else { this.inlineDeletePayElement = null; }
      this.isSelectAll = false;
      this.selectedIds = [];
      this.isConfirmPopupForDelete = false;
    }
  }

  resetEditRow(ev: boolean) {
    this.cancelConfirmationValue = ev;
    this.payElements[this.editableRowIndex].localName = this.selectedRow.localName;
    this.payElements[this.editableRowIndex].payElementCode = this.selectedRow.payElementCode;
    this.payElements[this.editableRowIndex].englishName = this.selectedRow.englishName;
    this.payElements[this.editableRowIndex].wageGroupName = this.selectedRow.wageGroupName;
    this.payElements[this.editableRowIndex].payElementType = this.selectedRow.payElementType;
    this.payElements[this.editableRowIndex].clientImportEnabled = this.selectedRow.clientImportEnabled;
    this.payElementCodeDuplicate = false;
    this.localNameDuplicate = false;
    this.englishNameDuplicate = false;
    this.payElementCodeField('payElementCode', this.editableRowIndex);
    this.localNameField('localName', this.editableRowIndex);
    this.englishNameField('englishName', this.editableRowIndex);
    this.editableRowIndex = -1;
    this.isRowChanged = false;
  }

  editRow(index: number) {
    this.cancelConfirmationValue = false;
    this.isSelectAll = false;
    this.selectedIds = [];
    this.editableRowIndex = index;
    this.selectedRow = JSON.parse(JSON.stringify(this.payElements[index]));
  }

  saveUpdatedPayElement(index: number) {
    if (!this.isUpdatedPayElementValid(index)) {
      this.editableRowIndex = -1;
      this.hexLoader = true;
      let payElementObj = {
        id: this.payElements[index].entityPayElementId,
        entityId: this.selectedEntity?.entityId,
        elementCode: this.payElements[index].payElementCode?.trim(),
        localName: this.payElements[index].localName?.trim(),
        englishName: this.payElements[index].englishName?.trim(),
        wageGroup: this.getWageGroupId(this.payElements[index].wageGroupName),
        elementType: this.getPayElementTypeId(this.payElements[index].payElementType),
        isClientImport: this.payElements[index].clientImportEnabled,
        isDeleted: false
      };
      this.entitiesService.savePayElement(payElementObj).subscribe({
        next: (res) => {
          this.hexLoader = false;
          // audit track
          const enableStatus = payElementObj?.isClientImport ? 'Enabled' : 'Disabled';
          const description = ('[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.editedPayElements') + enableStatus + ' Pay element code for Import [' + payElementObj?.elementCode + ']');
          auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
            this.translate.instant('auditTrail.entities.entityDetail'),
            this.translate.instant('common.edit'),
            description)
          if (res?.statusCode === 'COBALT_002_017' || res?.statusCode === 'UNAUTHORIZED_USER') {
            this.statusCodeService.displayToastrMessage(res?.statusCode, 'error', 'entities');
          } else {
            this.statusCodeService.displayToastrMessage(res?.statusCode, 'success', 'common');
          }
          this.resetPayElements();
          this.isRowChanged = false;
        },
        error: (error) => {
          this.hexLoader = false;
          this.disableloader();
          this.isRowChanged = false;
        }
      });
    }
  }

  isUpdatedPayElementValid(index: number): boolean {
    return (this.payElementCodeField('payElementCode', index) || this.payElementCodeDuplicate ||
      this.localNameField('localName', index) || this.localNameDuplicate ||
      this.englishNameField('englishName', index) || this.englishNameDuplicate);
  }

  getWageGroupId(name: string) {
    const matchedWageGroup = this.wageGroupItems.find(obj => obj['wageGroupName'] === name);
    return matchedWageGroup ? matchedWageGroup['wageGroupId'] : null;
  }

  getPayElementTypeId(name: string) {
    const matchedPayElement = this.elementTypeItems.find(obj => obj['elementTypeCode'] === name);
    return matchedPayElement ? matchedPayElement['payElementTypeId'] : null;
  }

  cancelEdit() {
    if (this.isRowChanged) {
      this.isConfirmPopupForEdit = true;
    } else {
      this.editableRowIndex = -1;
    }
    this.ngbToolTipPayelementcode = null;
    this.ngbToolTipLocalName = null;
    this.ngbToolTipEnglishName = null;
  }

  statusToggleForImport(event: boolean, index: number) {
    this.payElements[index].clientImportEnabled = event;
    this.isRowChanged = true;
  }

  isNgSelectChanged() {
    this.isRowChanged = true;
  }

  onInputValueChanged(fieldName: string, event: any) {
    this.isRowChanged = true;
    if (this.payElementsUniqueCheckSubscription[fieldName]) {
      this.payElementsUniqueCheckSubscription[fieldName].unsubscribe();
    }
    this.payElementsUniqueCheckSubject[fieldName]?.next(event.target.value.trim());
  }

  updateToolTips(fieldName: string, data: any) {
    if (fieldName === 'payElementCode') {
      this.payElementCodeDuplicate = data.payElementCodeDuplicate;
    }
    if (fieldName === 'englishName') {
      this.englishNameDuplicate = data.englishNameDuplicate;
    }
    if (fieldName === 'localName') {
      this.localNameDuplicate = data.localNameDuplicate;
    }
    this.ngbToolTipPayelementcode = this.payElementCodeDuplicate ? this.translate.instant('entities.general.payElementCodeExist') : null;
    this.ngbToolTipLocalName = this.localNameDuplicate ? this.translate.instant('entities.general.localLanguageNameExist') : null;
    this.ngbToolTipEnglishName = this.englishNameDuplicate ? this.translate.instant('entities.general.englishLanguageNameExist') : null;
  }

  selectItemToDelete(selected: boolean, entityPayElementId: string) {
    if (selected) {
      this.selectedIds.push(entityPayElementId);
      this.isSelectAll = this.selectedIds.length === this.payElements.length;
    } else {
      this.isSelectAll = false;
      this.selectedIds = this.selectedIds.filter(id => id !== entityPayElementId);
    }
  }

  selectAll(selected: boolean) {
    this.isSelectAll = selected;
    this.selectedIds = [];
    if (this.isSelectAll) { this.selectedIds = this.payElements.map(it => { return it.entityPayElementId }); }
  }

  findpayElementsDataId(id: string) {
    return this.selectedIds?.findIndex(item => { return item === id });
  }

  payElementCodeField(fieldName: string, index: number): boolean {
    return this.validateField(fieldName, index);
  }

  localNameField(fieldName: string, index: number): boolean {
    return this.validateField(fieldName, index);
  }

  englishNameField(fieldName: string, index: number): boolean {
    return this.validateField(fieldName, index);
  }

  validateField(fieldName: string, index: number): boolean {
    if (this.cancelConfirmationValue) {
      return false;
    } else {
      const fieldValue = this.payElements[index][fieldName]?.trim();
      if (!fieldValue) {
        this.ngbToolTipMessage = this.translate.instant('common.error.required');
      }
      return !fieldValue;
    }
  }

  deletePayElements() {
    this.hexLoader = true;
    let body = {
      entityId: this.selectedEntity?.entityId, deleteAll: this.isSelectAll,
      entityPayElementIds: this.isSelectAll ? [] : this.inlineDeletePayElement ?
        [this.inlineDeletePayElement?.entityPayElementId] : this.selectedIds
    };
    this.entitiesService.deletePayElements(body).subscribe({
      next: res => {
        this.resetPayElements();
        this.hexLoader = false;
        // audit track
        const description = ('[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.deletePayElements') + (body?.entityPayElementIds[0] ? ('[' + body?.entityPayElementIds[0] + ']') : ''));
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('common.delete'),
          description)
        if (res?.statusCode === 'COBALT_002_017' || res?.statusCode === 'UNAUTHORIZED_USER') {
          this.statusCodeService.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else {
          this.statusCodeService.displayToastrMessage(res?.statusCode, 'success', 'entities');
        }
      }, error: error => {
        this.hexLoader = false;
      }
    })
  }

  inlineDelete(payElement: any) {
    this.isSelectAll = false;
    this.isConfirmPopupForDelete = true;
    this.inlineDeletePayElement = payElement;
  }

  sampleFileDownload() {
    this.hexLoader = true;
    this.entitiesService.payElementsSampleFileDownload().subscribe({
      next: (res: Blob) => {
        this.hexLoader = false;
        const fileName = this.translate.instant('entities.sampleFile');
        this.sharedService.saveFile(res, fileName);
      }, error: err => {
        this.hexLoader = false;
      }
    })
  }

  exportPayElements() {
    this.hexLoader = true;
    let payload = {
      "entityId": this.selectedEntity?.entityId
    }
    this.entitiesService.exportTicket(payload).subscribe({
      next: res => {
        this.hexLoader = false;
        fileSaver.saveAs(res.body, this.translate.instant('entities.payElementsxlsx'));
      }, error: error => {
        this.hexLoader = false;
      }
    });
  }

  getActionsTasks(action: string) { return this.userPermissionsService.isActions('Entities', action); }


  ngOnDestroy() {
    Object.values(this.payElementsUniqueCheckSubscription).forEach(item => item.unsubscribe());
    this.sharedService.selectedDropdownItem.next({});
    if (this.doneSub) { this.doneSub.unsubscribe(); }
  }
}
