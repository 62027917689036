import { Component, EventEmitter, Output, Input } from '@angular/core';


@Component({
    selector: 'app-display-list-popup',
    templateUrl: './display-list-popup.component.html',
    styleUrl: './display-list-popup.component.scss',
    standalone: false
})
export class DisplayListPopupComponent {
  @Output() emitEvent = new EventEmitter();
  @Input() list: any[] = [];
  @Input() title: string = '';
}
