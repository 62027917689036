<div class="alphabet-filter-header">
  <ng-container *ngIf="selectedCharacter  && !searchText">
    <div>
      <span translate>common.showingResults</span>
      <span translate>{{ pageHeader }}</span>
      <span translate>common.namesStartingWith</span>
    </div>
    <div class="selected-alphabet">
      <span>&#8220;</span>{{ selectedCharacter }}<span>&#8221;</span>
    </div>
  </ng-container>

  <ng-container *ngIf="searchText && !selectedCharacter">
    <span translate>common.showingSearchResults</span>
    <div class="selected-alphabet textoverflow" [title]="searchText.length > 60 ? searchText: ''">
      <span>&#8220;</span>{{ searchText }}<span>&#8221;</span>
    </div>
  </ng-container>

  <ng-container *ngIf="searchText  && selectedCharacter">
    <span translate>common.showingResults</span>
    <span translate>{{ pageHeader | lowercase }}</span>
    <span translate>common.namesStartingWith</span>
    <div class="textoverflow">
      <span class="selected-alphabet">
        <span>&#8220;</span>{{ selectedCharacter }}<span>&#8221;</span>
      </span>
      <span translate>common.keyword</span>
      <span class="selected-alphabet" [title]="searchText.length > 60 ? searchText: ''">
        <span>&#8220;</span>{{ searchText }}<span>&#8221;</span>
      </span>
    </div>
  </ng-container>
</div>