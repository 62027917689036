export const Auth = {
  saml_auth_endpoint_url: 'saml/login',
  validate_token_url: 'saml/validateToken',
  saml_clearToken: 'saml/clearToken'
};

export const TmfUserLogin = {
  getUserClients: 'users/getUserClients',
  profUserCache: 'users/prof-user-cache/',
  getUserType: 'users/getLoginUserType',
  userCache: 'cache/user-acl?userId='
}

export const Clients = {
  fetchClientList: 'clients/client-list',
  saveClient: 'clients',
  getClient: 'clients/',
  filterCountriesByClient: 'clients/countries',
  exportClients: 'clients/export',
  checkUniqueName: 'clients/names/unique-check?clientName='
};

export const Entities = {
  filterEntityList: 'entities/list',
  entityServices: 'service-lines',
  fetchL2Services: 'l2-services',
  createUpdateEntity: 'entities',
  enateEntities: 'entities/enate-entities?entityName=',
  getCountries: 'clients/client-countries',
  fetchPlatformListByCountry: 'payrolls/platforms',
  fetchEntityClients: 'entities/clients',
  exportEntity: 'entities/export',
  exportPayElement: 'pay-elements/export',
  fetchCountriesList: 'countries',
  entityDetail: 'entities/info',
  filterPayElementsList: 'pay-elements/list',
  createPayElement: 'pay-elements',
  getPayElementTypes: 'pay-elements/types',
  getWageGroups: 'pay-elements/wage-groups',
  manageClientImport: 'pay-elements/manage-client-import',
  fetchExecRules: 'payrolls/payday/exec-rules',
  getPayDayFrequencyList: 'payrolls/payday/frequencies',
  getPayrollinfo: 'payrolls?entityId=',
  savePayrollinfo: 'payrolls',
  getPayrollFrequencyList: 'payrolls/frequencies',
  daysFormonth: 'payrolls/days?month=',
  getPayDayRange: 'payrolls/payday/ranges',
  entitiesClients: 'entities/client-list',
  uniqueCheck: 'entities/names/unique-check',
  deletePayElements: 'pay-elements/manage',
  payElementsUniqueCheck: 'pay-elements/unique-check',
  fetchExcelData: 'pay-elements/fetch-excel-data',
  manualTrigger: 'entities/manual-trigger',
  payElementsSampleFileDownload: 'pay-elements/download-sample-file',
  contracts: 'contracts',
  serviceList: 'hrp/service',
  allEnateEntities: 'entities/all-enate-entities?entityName=',
  enateEntitiesCheck: 'entities/entity-check',
  L2ServicesList: 'l2-services/list-service-l2s',
  enableL2Service: 'l2-services/enable-l2-service',
  deleteL2Service: 'l2-services/delete-service',
  deleteAllService: 'l2-services/delete-all-service',
  saveServiceLines: 'service-lines',
  ticketService: 'ticket-service',
  getServiceRequestContract: 'ticket-service/contract-list',
  deleteTicketService: 'ticket-service/delete',
  updateServiceContract: 'service-lines/update-enate-contract',
  updateTicketContract: 'ticket-service/update-enate-contract',
  customValues: {
    saveUpdateJobBand: "data/save-update-jobband",
    switchJobBandStatus: "data/switch-jobband-status",
    getAllJobBands: "data/all-jobbands?entityId=",
    deleteJobBand: "data/deleteJobBand",
    getPayGroup: 'data/all-paygroups',
    saveAndUpdatePayGroup: 'data/save-update-paygroup',
    switchPayGroup: 'data/switch-paygroup-status',
    deletePayGroup: 'data/delete-paygroups',
    getCostCentre: 'data/all-costcentres',
    switchCostCentre: 'data/switch-costcentre-status',
    saveUpdateCostCentre: 'data/save-update-costcentre',
    deleteCostCentre: 'data/delete-costcentres',
    saveUpdateJobTitle: 'data/save-update-job-title',
    getJobTitle: 'data/all-jobtitles',
    deleteJobTitle: 'data/delete-jobtitle',
    switchJobtitleStatus: 'data/switch-jobtitle-status',
    jobTitleDuplicateCheck: 'data/duplicate-jobtitle',
    jobBandDuplicateCheck: 'data/duplicate-check-job-band',
    getdepartment: 'data/all-departments',
    saveUpdateDepartment: 'data/save-update-department',
    deleteDepartment: 'data/delete-department',
    switchDepartment: 'data/switch-department-status',
    addWorkSchedule: 'data/save-update-workschedule',
    getWorkSchedules: 'data/all-workschedule',
    deleteWorkSchedule: 'data/delete-workschedule',
    switchWorkScheduleStatus: 'data/switch-workschedule-status',
    getTerminationReason: 'data/termination-reason',
    deleteTerminationReason: 'data/delete-termination-reason',
    saveUpdateTerminationReason: 'data/save-update-termination-reason',
    switchTerminationReason: 'data/switch-termination-reason-status',
    uniqueTerminationReasonCheck: 'data/duplicate-check-termination-reason',
    uniqueDepartmentCheck: 'data/duplicate-department',
    uniquePayGroupCheck: 'data/duplicate-paygroup',
    duplicateCheckCostCentre: 'data/duplicate-costcentre',
    WorkScheduleDuplicateCheck: 'data/duplicate-workschedule',
    getAbsenceType: 'data/absence-type',
    saveUpdateAbsenceType: 'data/save-update-absence',
    switchAbsenceType: 'data/switch-absence-status',
    deleteAbsenceType: 'data/delete-absence-type',
    absenceTypeDuplicateCheck: 'data/duplicate-check-absence-type'
  },
  schedule: {
    getActivityTypes: 'schedules/activity-types',
    getEntityServiceName: 'schedules/service-name',
    getScheduleDetails: 'schedules/getScheduleDetails',
    saveScheduleConfiguration: 'schedules/saveScheduleConfigurations',
    stageMappings: 'schedules/stage-mappings',
    checkScheduleStatus: 'schedules/checkScheduleStatus',
    scheduleTrigger: 'schedules/triggerScheduleInfo'
  },
  approvals: {
    getEntityServiceLines: 'entities/service-lines',
    getServiceLineApprovals: 'entities/service-line-approvals',
    getL2Services: 'entities/l2-services',
    getUniqueL2Approval: 'entities/l2-service-approvals',
    saveApprovals: 'entities/saveApprovals',
    getApprovalRoles: 'users/approval-roles',
    getApprovalLevel: 'entities/approval-level'
  }
};

export const Access = {
  roles: {
    deleteRole: 'roles/access-mapping/delete-role',
    roles: 'roles',
    uniqueNameCheck: 'roles/names/unique-check',
    saveRoleAccessMappings: 'roles/access-mapping',
    refreshRoleCache: 'acl/role-access'
  },
  users: {
    deleteUserAccess: 'users/delete-user-access',
    duplicateEmail: 'users/email/unique-check',
    usersList: 'users/list',
    userDetail: "users/user-details",
    userServiceLineAccess: 'users/user-service-line-access',
    updateUserDetails: 'users/update-user-details',
    clientsForClientUser: 'clients/list',
    clientsForTmfUser: 'users/service-line/clients',
    getClientServiceLines: 'users/client-service-lines',
    getUserRoles: 'users/roles',
    getUserServiceLines: 'users/user-service-lines',
    countries: 'users/countries',
    regions: 'users/regions',
    l2services: 'users/l2s',
    entities: 'users/entities',
    exportUsers: 'users/export',
    users: 'users',
    enableUser: 'users/enable-user',
    disableUser: 'users/disable-user',
    activateUser: 'users/activate-user',
    deleteUser: 'users/delete-user',
    sendWelcomeEmail: 'users/welcome-email'
  },
};

export const Employees = {
  filterEmployees: 'employee/list',
  exportEmployee: 'employee/export',
  getJobStatus: 'employee/job-status',
  getClients: 'data/client-info',
  getCountries: 'data/countries-info',
  getEntities: 'data/entities-info',
  jobType: 'data/job-type',
  getContractType: 'data/contract-type',
  getPayGroups: 'data/pay-groups',
  getJobTitle: 'data/job-title',
  getCostCenter: 'data/cost-centres',
  getWorkSchedule: 'data/work-schedule',
  getJobBands: 'data/job-band',
  getGender: 'data/gender',
  getSalutation: 'data/salutation',
  getDepartments: 'data/departments',
  getMaritalStatus: 'data/marrital-status',
  getTerminationReasons: 'data/termination-reason-for-drop-down',
  getContractDates: 'data/contract-dates',
  getRelationship: 'data/relationship',
  getAddressType: 'data/address-type',
  getNationality: 'data/nationality',
  getNationalityTypes: 'data/nationality-id-type?countryId=',
  getBanks: 'data/banks',
  effectiveDates: 'employee/effective-dates',
  countryCodes: 'data/country-codes',
  draftSave: 'employee/draft-save',
  createEmployee: 'employee/save-employee-data',
  fetchTemplate: 'employee/fetch-platform/',
  fetchBankTemplate: 'platform/fetch-bank-fields?countryId=',
  getEmployee: 'employee/fetch-employee?mail=',

  // duplicate checks
  duplicateEmployeeId: 'employee/duplicate-employeeId?',
  duplicateExternalId: 'employee/duplicate-externalId?',
  duplicateOfficialMail: 'employee/duplicate-mail?emailId=',

  masterData: 'employee/change-history',
  updatedSection: 'employee/updatedsection-info',
  updatedField: 'employee/updatedfield-info',

  absenceTypes: {
    getAbsenceTypes: 'employee/employee-absence-types?employeeId=',
    getPayruns: 'employee/employee-payruns?employeeId=',
    getAbsenceData: 'absence/change-history'
  },

  payData: {
    getWageGroups: 'employee/wagegroup-info',
    getPayElements: 'employee/payelement-info',
    getPayData: 'employee/pay-data-history',
    payDataEffectiveDates: "employee/pay-data/effective-dates"
  }
};

export const Workflow = {
  actions: {
    getAllTasks: 'task/getTasks',
    getActionTaskStatusCounts: 'task/getActionTaskStatusCounts',
    exportAllTasks: "task/exportAllTasks",
    getWorkflowCounts: "workflow/getWorkflowCounts",
    getTaskReviewInfo: "task/getTaskReviewInfo",
    getUserListToDelegateTask: "users/getUserListToDelegateTask",
    getDelegateTasks: "task/delegateTasks",
    getUserL2ServicesForActivities: "data/getUserL2ServicesForActivities",
    getTaskApprovalHistory: "task/fetchTaskApprovalHistory",
    getTaskDetails: 'task/getTaskDetails',
    getTaskComments: 'task/getTaskComments',
    submmitTask: 'task/submitTask',
    approveTask: "task/approveTask",
    getTemplateType: "template/types",
    uploadFile: "file/upload",
    getAbsencePayDataMappingFields: "template/pay-absencedata/mapping-fields",
    getMappingFields: "template/mapping-fields/",
    validatePayAbsence: "template/validate/pay-absencedata",
    validate: "template/validate",
    saveTemplate: "template/save",
    saveImportAbsenceTemplate: "file/save-importAbsencedata",
    saveImportPayTemplate: "file/save-importPaydata",
    saveImportTemplate: "file/save-import",
    truncate: "collect/truncate",
    importData: {
      templateNames: 'template/names',
      errorStatus: 'template/import-status',
      download: 'file/download',
      dynamicUploadStatus: 'import/status',
      importFileStatus: 'import/import-status',
      existingTemplate: {
        templateTypes: 'template/types',
        uploadFile: 'file/import-data',
        errorLogs: 'import/error-logs'
      },
      standardTemplate: {
        uploadFile: 'file/standard-import',
        fetchTemplate: 'template/standard',
        saveTemplate: 'collect/import/save-data'
      }
    },
    supportFile: {
      getAllSupportFile: 'manage/collect/support-file/list',
      supportFileUpload: 'manage/collect/support-file',
      deleteSupportFile: 'manage/collect/support-file/delete',
      downloadSupportFile: 'manage/collect/support-file/download'
    },
    dataSharedByMe: {
      newJoinersList: 'collect/list/new-joiners',
      EFnewJoinersList: 'collect/exclude/list/new-joiners',
      terminatedList: 'collect/list/terminate',
      EFterminatedList: 'collect/exclude/list/terminate',
      deleteNewJoinersOrTerminated: 'collect/delete/employee',
      UpdatedList: 'collect/list/updated-employee',
      EFupdatedList: 'collect/exclude/list/updated-employee',
      deleteUpdated: 'collect/delete/updated-Employee',
      addTerminate: 'collect/update/terminate',
      masterDataCount: 'collect/master-data-count',
      EFmasterDataCount: 'collect/exclude/master-data-count',
      fetchEmployee: 'employee/fetch-employees',
      getTerminationReason: 'data/termination-reason',
      addUpdate: 'collect/updated-Employee',
      updatedSection: 'employee/updatedsection-info',
      updatedFields: 'employee/updatedfield-info',
      exportNewJoiners: 'collect/export/new-joiners',
      exportNewJoinersCosmos: 'collect/new-joiners/export',
      exportUpdated: 'collect/export/updated-users',
      exportTerminated: 'collect/export/terminated-users',
      lexiconExport: 'collect/lexicon/export'
    },
    filesReceived: {
      fetchFilesReceived: 'action/review/list-files',
      downloadReceivedFile: 'action/review/download',
      downloadFinalizeFile: 'action/finalize/download'
    },
    payData: {
      getPayDataList: 'collect/list/pay-data',
      EFgetPayDataList: 'collect/exclude/list/pay-data',
      deletePayData: 'collect/delete/pay-data',
      exportPayData: 'collect/export/pay-data',
      addPayData: 'collect/add/pay-data'
    },
    absenceData: {
      absenceDataList: 'collect/list/absence-data',
      EFabsenceDataList: 'collect/exclude/list/absence-data',
      deleteAbsenceData: 'collect/delete/absence-data',
      exportAbsenceData: 'collect/export/absence-data',
      absenceTypes: 'collect/absenceTypes/',
      addAbsenceData: 'collect/save/absence-data'
    }
  },
  activity: {
    getActivities: 'activities/getActivities',
    getActivityDetails: 'activities/getActivityDetails',
    getActivityComments: 'activities/getActivityComments',
    exportActivities: 'activities/exportAllActivities',
    getAtivityStatusCount: 'activities/getActivityStatusCount',
    getTimeLineAndClientAction: 'activities/getTimeLineAndClientAction',
    saveActivityComment: 'activities/saveActivityComment',
    manageComment: "activities/manage-comment",
    finalise: {
      finaliseListFiles: 'action/finalize/list-files'
    },
    getActivityDocuments: "activities/getActivityDocuments"
  },
  requests: {
    getTickets: "tickets/manage-tickets",
    getTicketsStatus: "tickets/manage-tickets-status",
    serviceLines: "tickets/service-lines",
    entities: "tickets/entities",
    saveTicket: "tickets/saveTicket",
    export: "tickets/manage-tickets/export",
    deleteTicket: "tickets/deleteTicket",
    submitTicket: "tickets/submitTicket",
    uploadActivityDocument: "activities/saveActivityComment",
    uploadTicketDocument: "tickets/manage-documents",
    checkFileExist: "task/checkFileExist",
    downloadFile: "task/downloadFile",
    deleteDocument: "tickets/deleteDocument",
    deleteDocuments: "tickets/deleteDocuments"
  }
}

export const System = {
  fetchVersion: 'platform/fetch-platform-version-history?platformId=',
  saveVersionHistory: 'platform/field-mapping-with-version-history',
  getTemplateByPlatform: 'platform/field-mapping/',
  saveBankData: 'platform/save-bank-data',
  versionBasedTemplate: 'platform/field-mapping-version-history/',
  getCountries: 'countries/country-list',
  payrollPlatforms: 'payrolls/platform-list',
  switchPlatformStatus: 'payrolls/switch-platform-status',
  getProactiveNotificationTime: 'data/getProactiveNotificationTime',
  saveProactiveNotificationTime: 'data/saveProactiveNotificationTime',
  uploadStandardTemplate: 'platform/standard-fileupload',
  getTemplates: 'template/standard',
  deleteStandardTemplate: 'platform/standard-delete/'
}

export const loginPreference = {
  resetUserPreference: 'workspace-preference/reset-user-preference',
  getUserPreferenceStatus: 'workspace-preference/user-preference-status',
  getUserPreference: 'workspace-preference/user-preference',
  uploadPic: 'users/upload-pic',
  deletePic: 'workspace-preference/delete-user-profile-pic'
}

export const Workspace = {
  fetchWorkspace: 'workspace/fetch-configure-workspace',
  updateWorkspace: 'workspace/update-configure-workspace'
}

export const UserPreference = {
  getUserProfileInfo: 'users/profile-info',
  getAllLanguages: 'data/languages',
  getTimezones: 'data/time-zones',
  getConfigureNotificationPreferences: 'notification/preferences',
  saveUserPreferenceDetails: 'users/preferences',
  userSettings: 'users/settings'
}

export const Notification = {
  getNotifications: 'notification/getNotifications',
  checkForFlashAlert: 'notification/checkForFlashAlert',
  markNotificationsAsSeen: 'notification/markNotificationsAsSeen',
  fetchUserTask: 'common/fetchUserTask'
}
