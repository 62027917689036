<ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" [multiple]="false"
  [ngClass]="{'disabled': disable}">
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <img class="mb-2" src="./assets/images/upload (1)@2x.png" alt="" style="height:30px; width:32px;">
    <div class="infoText" translate>workflow.importData.dragAndDrop</div>

    <div class="d-flex flex-column">
      <span
        *ngIf="component !== 'pay elements' && component !== 'standard template' && component !== 'use standard template'"
        class="info text-center" [openDelay]="200" data-trigger="hover" tooltipClass="tool-tip"
        [ngbTooltip]="'common.allowedFormatsAll' | translate" translate>common.allowedFiles</span>
      <span *ngIf="component !== 'pay elements'" class="info text-center" translate>common.maxFileSize</span>
      <span *ngIf="component === 'pay elements'" class="info text-center" translate>common.allowedFileXlsx</span>
      <span *ngIf="component === 'standard template' || component === 'use standard template'" class="info text-center"
        translate>common.allowedFileXlsx_xls</span>
      <span *ngIf="component === 'pay elements'" class="info text-center" translate>common.maxFileSize10MB</span>
    </div>
    <div class="or flex-center" translate>common.or</div>

    <div class="d-flex">
      <button class="btn-outline sm" (click)="openFileSelector()" translate>workflow.importData.browse</button>
      <!-- <button class="btn-primary btn-dark ms-3 sm" (click)="uploadFiles()"
        [ngClass]="{'disabled': droppedFiles.length < 1}" translate>Done</button> -->
    </div>
    <span class="attached-file textoverflow cp" [title]="item.fileEntry.name" *ngFor="let item of droppedFiles">
      {{item.fileEntry.name}}
    </span>
  </ng-template>
</ngx-file-drop>