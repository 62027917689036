<div class="h100" [ngStyle]="{'overflow': enateEntityRestrictOverlay ? 'hidden' : 'scroll'}">
  <div class="title mb-2" translate>entities.general.general</div>

  <form class="form-wrapper d-flex mt-0" [formGroup]="generalForm" style="overflow: unset;">
    <div [ngClass]="{'disabled no-opacity' : disableEdit ||
         (!getActions('Create Entity') && selectedEntityClient)}" class="d-flex w100 h100">
      <div class="form me-3">
        <!-- entity name -->
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label for="" translate><span class="asterik">*</span> common.entityName</label>
            <div class="char-info"><strong>{{generalForm.value.entityName?.length ?? 0}}</strong> / 256
              <span translate>common.chars</span>
            </div>
          </div>
          <input type="text" maxlength="256" formControlName="entityName" (input)="onEntityNameChange($event)"
            [placeholder]="'common.pleaseEnterHere' | translate" required
            [ngClass]="{'error' : generalForm.get('entityName')?.errors && generalForm.get('entityName')?.touched}" />
          <!-- errors -->
          <div class="error" *ngIf="generalForm.get('entityName')?.hasError('required') &&
                        generalForm.controls['entityName'].touched" translate> common.error.required </div>
          <div class="error" *ngIf="generalForm.get('entityName')?.hasError('noAlphabet') && !generalForm.get('entityName')?.hasError('required') &&
                                     generalForm.get('entityName')?.touched" translate>common.error.alphabetRequired
          </div>
          <div class="error" *ngIf="generalForm.get('entityName')?.hasError('isExistWithDiffClient')" translate>
            entities.general.entityNameExistExistWithDiffClient</div>
          <div class="error" *ngIf="generalForm.get('entityName')?.hasError('isExistWithSameClient')" translate>
            entities.general.entityNameExistExistWithSameClient</div>
        </div>

        <div class="form-group">
          <label for="" translate><span class="asterik">*</span> entities.general.enateEntity</label>
          <ng-select *ngIf="(!selectedEntity || clone) && !cloneSaved" class="table-select lg"
            [items]="allEnateEntities" bindLabel="entityName" bindValue="enateGuid" [searchable]="true"
            [clearable]="false" formControlName="enateEntity" [loadingText]="'Loading...'" (change)="enateEntityCheck()"
            (open)="enateEntityRestrictOverlay=true" (close)="enateEntityRestrictOverlay=false"
            [placeholder]="'entities.enterSuggestions' | translate" [notFoundText]="'common.nodata' | translate"
            [ngClass]="{'error-select' : generalForm.get('enateEntity')?.errors && generalForm.get('enateEntity')?.touched}">
            <ng-template ng-option-tmp let-item="item">
              <table class="table table-striped" aria-describedby="enate-entities">
                <thead class="table-head d-flex" style="position: fixed;margin-top: -2px;"
                  *ngIf="item?.enateEntityId === allEnateEntities[0]?.enateEntityId">
                  <tr>
                    <th translate>common.entityName</th>
                    <th translate>entities.general.enateGuid</th>
                  </tr>
                </thead>
                <tbody class="table-body d-flex">
                  <tr [ngClass]="{'active' : item.enateGuid === generalForm.value.enateEntity}">
                    <td class="textoverflow" [title]="item.entityName">{{item.entityName}}</td>
                    <td class="textoverflow" [title]="item.enateGuid">{{item.enateGuid}}</td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </ng-select>

          <input *ngIf="selectedEntity && !clone || cloneSaved" type="text" class="disabled no-opacity"
            [value]="generalForm.value.enateEntity" />

          <div class="error" *ngIf="generalForm.get('enateEntity')?.hasError('required') &&
                        generalForm.controls['enateEntity'].touched" translate> common.error.required </div>
          <div class="error" *ngIf="generalForm.get('enateEntity')?.hasError('isExistWithDiffClient')" translate>
            entities.general.enateEntityInUse</div>
        </div>

        <div class="form-group">
          <label for="" translate><span class="asterik">*</span> common.country</label>
          <i class="icon-search"></i>
          <ng-select *ngIf="!selectedEntity && !clone" class="search-select" [items]="countries" bindLabel="countryName"
            bindValue="countryId" [searchable]="true" [clearable]="false" formControlName="country"
            (change)="changedCountry()" [placeholder]="'entities.enterSuggestions' | translate"
            [notFoundText]="'common.nodata' | translate"
            [ngClass]="{'error-select' : generalForm.get('country')?.errors && generalForm.get('country')?.touched}">
          </ng-select>

          <input *ngIf="selectedEntity || clone" type="text" class="disabled no-opacity"
            [value]="entityInfo?.countryName" />

          <div class="error"
            *ngIf="generalForm.get('country')?.hasError('required') && generalForm.controls['country'].touched"
            translate>common.error.required</div>

          <div class="error" *ngIf="generalForm.get('country')?.hasError('isExistWithSameClient')" translate>
            entities.general.countryErrorForEntityName</div>
        </div>

        <div class="form-group">
          <label for="" translate><span class="asterik">*</span> entities.payrollPlatform</label>
          <i class="icon-search"></i>
          <ng-select *ngIf="!clone || cloneSaved" class="search-select" [items]="platforms" bindLabel="platformName"
            bindValue="platformId" [searchable]="true" [clearable]="false" formControlName="platform"
            (change)="onPlatformChange()" [placeholder]="'entities.enterSuggestions' | translate"
            [notFoundText]="'common.nodata' | translate"
            [ngClass]="{'error-select' : generalForm.get('platform')?.errors && generalForm.get('platform')?.touched}">
          </ng-select>

          <input *ngIf="clone && !cloneSaved" type="text" class="disabled no-opacity"
            [value]="entityInfo?.platformName" />

          <div class="error" *ngIf="generalForm.controls['platform'].errors && generalForm.controls['platform'].touched"
            translate>common.error.required</div>
        </div>

        <div class="form-group md me-2">
          <label for="" translate><span class="asterik">*</span> common.contractStart</label>
          <input *ngIf="disableEdit" type="text"
            [value]="datePipe.transform(generalForm.value.contractStart, 'DD MMM YYYY')" />
          <!-- date picker -->
          <input *ngIf="!disableEdit" type="date" [title]="'common.showDate' | translate" onclick="this.showPicker()"
            formControlName="contractStart" (change)="onDateChange()"
            [ngClass]="{'has-value' : generalForm.value.contractStart,
                    'error' : generalForm.get('contractStart')?.errors && generalForm.get('contractStart')?.touched}" />
          <!-- error -->
          <div class="error" *ngIf="generalForm.get('contractStart')?.hasError('required') &&
                        generalForm.get('contractStart')?.touched" translate> common.error.required
          </div>
        </div>

        <div class="form-group md me-0">
          <label for="" translate>common.contractEnd</label>
          <input *ngIf="disableEdit && generalForm.value.contractEnd" type="text"
            [value]="datePipe.transform(generalForm.value.contractEnd, 'DD MMM YYYY')" />
          <!-- date picker -->
          <input *ngIf="!disableEdit || !generalForm.value.contractEnd" type="date"
            [title]="'common.showDate' | translate" onclick="this.showPicker()" formControlName="contractEnd"
            (change)="onDateChange()" [ngClass]="{'has-value' : generalForm.value.contractEnd,
                    'error' : generalForm.get('contractEnd')?.errors && generalForm.get('contractEnd')?.touched}" />
          <!-- error -->
          <div class="error" *ngIf="generalForm.get('contractEnd')?.hasError('dateComparison') &&
                        generalForm.get('contractEnd')?.touched" translate> clients.error.contractEndError
          </div>
        </div>

        <!-- logo upload -->
        <div class="form-group" style="padding-bottom: 12px;">
          <label for="" translate>entities.general.uploadLogo</label>
          <div class="upload-wrapper">
            <div class="d-flex justify-content-between">
              <app-checkbox [bg]="'dark'" [label]="'entities.general.sameAsClient' | translate"
                [checked]="generalForm.value.sameAsClientLogo === 1" (onClick)="switchClientLogo()"
                [alignBaseline]="true"></app-checkbox>
              <button class="btn-link float-end position-relative"
                [ngClass]="{'disabled' : generalForm.value.sameAsClientLogo === 1}">
                <a [ngClass]="{'logo-text-width d-flex': logoFormat}"
                  (click)="logoFormat = !logoFormat; $event.stopPropagation()"
                  *ngIf="getActions('Manage General Information')"
                  style="text-decoration: underline;color: #417fa8;font-size: 11px;">
                  <span class="textoverflow " [title]="'clients.logoFormat' | translate"
                    translate>clients.logoFormat</span></a>
                <app-logo-format *ngIf="logoFormat" [alignMiddle]="true"></app-logo-format>
              </button>
            </div>
            <div class="upload-section flex-center"
              [ngClass]=" {'disabled' : generalForm.value.sameAsClientLogo === 1}">
              <button
                *ngIf="(!generalForm.value.logo || generalForm.value.sameAsClientLogo === 1 || deleteLogo || !imageUrl) && getActions('Manage General Information')"
                class="btn-link" (click)="uploadLogo = true" translate>
                <i class="icon-create"></i>clients.selectLogo</button>

              <ng-container *ngIf="imageUrl && !deleteLogo && generalForm.value.sameAsClientLogo === 0">
                <img style="max-width:78%;margin-right: 10px;" [src]="imageUrl?.objectUrl" alt="entity-logo" />

                <div class="image-actions">
                  <img class="cp" (click)="uploadLogo = true" style="height: 12px; vertical-align: text-top"
                  src="./assets/images/refresh.png" alt="refresh" [ngbTooltip]="'common.replace' | translate" />

                <i class="icon-bin cp" (click)="deleteClientLogo()" [ngbTooltip]="'common.delete' | translate"></i>
                </div>
              </ng-container>
            </div>
            <p class="note mt-1 d-flex justify-content-end">
              <span class="textoverflow text-end" [title]="'common.fileFormatInfo' | translate"
                translate>common.fileFormatInfo</span>
            </p>
          </div>
        </div>
      </div>

      <!-- third party quick links - section -->
      <div class="form third-party-links">
        <div class="form-group full-border">
          <label for="" translate>entities.general.quickLinks</label>
          <div class="d-flex enter-text-area gray-bg mb-2 pb-2 pt-2 ps-2">
            <!-- url -->
            <div style="width: 50%;">
              <ul class="form-group mb-0 w100">
                <div class="d-flex justify-content-between">
                  <label for="" class="list-header" translate>entities.general.url</label>
                  <div class="char-info">
                    <strong>{{generalForm.value.links ? generalForm.value.links.length :
                      0}}</strong>
                    / 2083 <span translate>common.chars</span>
                  </div>
                </div>
                <textarea maxlength="2083" formControlName="links" rows="3"
                  (input)="generalForm.patchValue({links : generalForm.value.links?.trim()})"
                  [placeholder]="(generalForm.value.links || getActions('Manage General Information')) ? ('entities.general.enterHere' | translate) : ''"
                  [ngClass]="{'error' : generalForm.get('links')?.errors && generalForm.get('links')?.touched}"></textarea>
              </ul>
              <div class="error" *ngIf="generalForm.get('links')?.hasError('required')" translate>
                common.error.required
              </div>
              <div class="error" *ngIf="generalForm.get('links')?.hasError('pattern')" translate>
                entities.general.invalidLink
              </div>
            </div>

            <!-- description -->
            <ul class="form-group ms-3 mb-0" style="width: 37%;">
              <div class="d-flex justify-content-between">
                <label for="" class="list-header" translate>entities.general.description</label>
                <div class="char-info">
                  <strong>{{generalForm.value.description ? generalForm.value.description.length :
                    0}}</strong> / 1500
                  <span translate>common.chars</span>
                </div>
              </div>
              <textarea maxlength="1500" formControlName="description" rows="3"
                [placeholder]="(generalForm.value.links || getActions('Manage General Information')) ? ('entities.general.enterHere' | translate) : ''"></textarea>
            </ul>
            <!-- add link -->
            <button class="btn-link" style="margin: auto;" *ngIf="getActions('Manage General Information')"
              [ngClass]="{'disabled' : !generalForm.value.links || !generalForm.get('links')?.valid}"
              (click)="addThirdPartyLinks()" translate> <i class="icon-create"></i>common.add</button>
          </div>

          <!-- links -->
          <ng-container *ngIf="thirdPartyLinks.length > 0">
            <span class="added-link-info"><span translate>clients.added</span>
              (<strong class="fnt-md">{{thirdPartyLinks.length}}</strong>)</span>
            <button class="btn-link" style="font-size: 11px;"
              (click)="thirdPartyLinks = []; generalForm.markAsDirty(); updateFormValidation();"
              translate>clients.removeAll</button>

            <ul class="quick-links">
              <li class="pill light me-2 lg mb-1" style="word-break: break-all;" *ngFor="let link of thirdPartyLinks"
                container="body" data-trigger="hover" [ngbTooltip]="link['desc']" placement="left">
                <a [href]="link.urlLink" target="_blank"> {{link.urlLink}}</a>
                <i class="icon-close" (click)="removeLink(link)"></i>
              </li>
            </ul>
          </ng-container>
        </div>
        <!-- Toggle -->
        <div class="form-group">
          <app-toggle [label]="'common.showAll' | translate"
            (emitToggle)="generalForm.patchValue({ fetchAll: +(!generalForm.value.fetchAll) }); generalForm.markAsDirty(); formValid.emit(true);"
            [checked]="generalForm.value.fetchAll === 1"></app-toggle>
        </div>
      </div>

    </div>
  </form>
</div>

<div class="restrict-overlay" *ngIf="enateEntityRestrictOverlay"></div>

<app-pic-upload *ngIf="uploadLogo" [entityLogo]="true" (emitImage)="receivedImage($event)"></app-pic-upload>

<app-hex-loader *ngIf="hexLoader"></app-hex-loader>

<app-confirmation-popup *ngIf="isConfirmPopup" (emitEvent)="handleConfirmation($event)"
  [description]="'entities.general.fieldChange'"></app-confirmation-popup>