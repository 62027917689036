import { UserPermissionsService } from './../../../services/user-permissons/user-permissions.service';
import { StatusCodeService } from './../../../services/status-code/status-code.service';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { EntitiesService } from '../../../../modules/entities/services/entities.service';
import { ToastrService } from 'ngx-toastr';
import { auditTracking } from '../../../../utils/audit-tracking';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrl: './schedule.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ScheduleComponent implements OnInit {

  activityTypeList: any[] = [];
  serviceNameList: any[] = [];
  activityTypeDetails: any = [];
  serviceNameDetails: any;
  scheduleData: any = [];
  initialScheduleData: any = [];
  l2Services: any[] = [];
  stageMappingTypeData: any[] = [];
  stageMappingList: any[] = [];
  stageMappingActualList: any;
  selectedStageMappingIds: any;
  hexLoader: boolean = false;
  isLoader: boolean = true;
  modifiedData: any = { scheduleHeaderDetails: [], entityId: null, l2ServiceId: null, enateServiceGuid: null };
  message: boolean = true;
  saveSchedule: boolean = false;
  save: boolean = false;
  @Input() disableEdit: boolean = false;
  @Input() selectedEntity: any;
  @Input() switchingTab: string = '';
  @Output() valueChange = new EventEmitter();
  @Input() saveForm: boolean = false;
  @Output() formSaved = new EventEmitter();

  activityTypeSettings: IDropdownSettings = {
    singleSelection: true, idField: 'activityTypeId', textField: 'activityTypeName', maxHeight: 100, noDataAvailablePlaceholderText: this.translate.instant('common.nodata'), noFilteredDataAvailablePlaceholderText: this.translate.instant('common.nodata'), closeDropDownOnSelection: true, allowSearchFilter: true, searchPlaceholderText: this.translate.instant('common.search'),
  };

  serviceNameSettings: IDropdownSettings = {
    singleSelection: true, idField: 'enateServiceGuid', textField: 'serviceName', maxHeight: 100, noDataAvailablePlaceholderText: this.translate.instant('common.nodata'), noFilteredDataAvailablePlaceholderText: this.translate.instant('common.nodata'), closeDropDownOnSelection: true, allowSearchFilter: true, searchPlaceholderText: this.translate.instant('common.search'),
  };

  stageMappingListSettings: IDropdownSettings = {
    singleSelection: true, idField: 'stageMappingId', textField: 'stageMappingName', maxHeight: 100, noDataAvailablePlaceholderText: this.translate.instant('common.nodata'), noFilteredDataAvailablePlaceholderText: this.translate.instant('common.nodata'), allowSearchFilter: true, searchPlaceholderText: this.translate.instant('common.search'),
  };

  constructor(private translate: TranslateService, private entitiesService: EntitiesService, private statusCodeService: StatusCodeService, private toastr: ToastrService, private userPermissionsService: UserPermissionsService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['disableEdit']?.firstChange && this.disableEdit) {
      this.resetScheduleData();
    }

    if ((!changes['switchingTab']?.firstChange && this.switchingTab) || this.saveForm) {
      this.saveScheduleConfigurations();
    }
  }

  ngOnInit() {
    this.activityTypeSelect();
    this.getStageMappingList();
  }

  getActions(action: string) { return this.userPermissionsService.isActions('Entities', action); }

  resetScheduleData() {
    this.scheduleData = JSON.parse(JSON.stringify(this.initialScheduleData));
    this.mapStageMappingData(this.scheduleData);
  }

  activityTypeSelect() {
    this.entitiesService.getActivityTypes(this.selectedEntity?.entityId).subscribe({
      next: res => {
        this.activityTypeList = res.data ? res.data : [];
        this.activityTypeList.filter(item => {
          if (item.activityTypeId === this.activityTypeDetails[0]?.activityTypeId) {
            this.activityTypeDetails = [item];
            this.fetchServiceName();
          }
        });
      }, error: error => { }
    })
  }

  fetchServiceName() {
    let body = {
      "entityId": this.selectedEntity?.entityId,
      "l2ServiceId": this.activityTypeDetails[0]?.activityTypeId,
    };
    this.entitiesService.getEntityServiceName(body).subscribe({
      next: res => {
        if (res.data[0] != null) {
          this.serviceNameList = res.data;
          this.serviceNameDetails = [res.data[0]];
          this.valueChange.emit(false);
          this.saveSchedule = false;
          this.serviceNameList.filter(item => {
            if (item.enateServiceGuid === this.serviceNameDetails[0]?.enateServiceGuid) {
              this.serviceNameDetails = [item];
              this.message = false;
              this.getScheduleDetails();
            }
          });
          this.modifiedData.scheduleHeaderDetails = [];
        }
      }, error: error => { }
    })
  }

  getStageMappingList() {
    this.entitiesService.getCobaltStageMappings().subscribe({
      next: res => {
        this.stageMappingActualList = res.data ? res.data : [];
      }
    })
  }

  getScheduleDetails() {
    this.isLoader = true;
    let body = {
      "entityId": this.selectedEntity?.entityId,
      "l2ServiceId": this.activityTypeDetails[0]?.activityTypeId,
      "enateServiceGuid": this.serviceNameDetails[0]?.enateServiceGuid
    };
    this.entitiesService.getScheduleDetails(body).subscribe({
      next: res => {
        this.scheduleData = res.data?.scheduleDetails ? res.data?.scheduleDetails : [];
        this.initialScheduleData = JSON.parse(JSON.stringify(this.scheduleData));

        if (res?.data?.length > 0) {
          this.modifiedData.entityId = this.selectedEntity?.entityId;
          this.modifiedData.l2ServiceId = this.activityTypeDetails[0]?.activityTypeId;
          this.modifiedData.enateServiceGuid = this.serviceNameDetails[0]?.enateServiceGuid;
        }

        this.selectedStageMappingIds = this.scheduleData.map((data: any) => { return data.stageMappingId });
        this.selectedStageMappingIds = this.selectedStageMappingIds.filter((el: any, i: number, a: any) => i === a.indexOf(el));
        this.mapStageMappingData(this.scheduleData);
        this.modifiedData.scheduleHeaderDetails = [];
        // audit track
        const desc = '[' + this.selectedEntity?.entityName + '] ' + this.translate.instant('auditTrail.entities.viewSchedule');
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('auditTrail.auditTypeView'),
          desc)
        this.disableLoader();
      }
    })
  }

  mapStageMappingData(scheduleData: any) {
    scheduleData.forEach((dataitem: any, dataindex: number) => {
      this.stageMappingList[dataindex] = [];

      let selectedStageMappingData = this.stageMappingActualList?.filter((x: any) => x.stageMappingId == dataitem.stageMappingId);
      if (selectedStageMappingData != null && selectedStageMappingData.length > 0) {
        this.stageMappingTypeData[dataindex] = [{ stageMappingName: selectedStageMappingData[0].stageMappingName, stageMappingId: selectedStageMappingData[0].stageMappingId }];
      }
      else {
        this.stageMappingTypeData[dataindex] = [];
      }

      this.stageMappingList[dataindex] = [...this.stageMappingActualList];

      this.selectedStageMappingIds.forEach((item: any) => {
        this.stageMappingList[dataindex].forEach((it: any, index: number) => {
          if (item === it.stageMappingId) this.stageMappingList[dataindex].splice(index, 1);
        });
      })

      let mappingItem = this.stageMappingActualList.filter((x: any) => x.stageMappingId == dataitem.stageMappingId);

      if (mappingItem != null && mappingItem.length > 0)
        this.stageMappingList[dataindex].push({ stageMappingName: mappingItem[0].stageMappingName, stageMappingId: mappingItem[0].stageMappingId })

      delete dataitem.scheduleId;
    });
  }

  onDeselectActivityType() {
    this.serviceNameDetails = [];
    this.scheduleData = [];
    this.activityTypeDetails = [];
    this.serviceNameList = [];
    this.message = true;
    this.valueChange.emit(false);
    this.saveSchedule = false;
    this.modifiedData.scheduleHeaderDetails = [];
  }

  onDeselectServiceName() {
    this.scheduleData = [];
    this.serviceNameDetails = [this.serviceNameList[0]];
    this.getScheduleDetails();
    this.modifiedData.scheduleHeaderDetails = [];
  }

  onSelectStageMapping(event: any, index: number) {
    let selectArray: any[] = [];

    this.scheduleData.forEach((it: any, i: number) => {
      selectArray[i] = [...this.stageMappingList[i]];
      if (index != i) {
        selectArray[i].forEach((it: any, index: number) => {
          if (event.stageMappingId === it.stageMappingId)
            selectArray[i].splice(index, 1);
        });
      }
    });

    let ids: any[] = [];
    selectArray.forEach((arr, i) => {
      if (i != index) {
        arr.forEach((item: any) => {
          ids.push(item.stageMappingId);
        })
      }
    })

    ids = ids.filter((el: any, i: number, a: any) => i === a.indexOf(el))
    let uniqueIds: any = [];
    this.stageMappingActualList.map((it: any) => {
      uniqueIds.push(it.stageMappingId);
    });

    let unique1 = ids.filter((o: any) => uniqueIds.indexOf(o) === -1);
    let unique2 = uniqueIds.filter((o: any) => ids.indexOf(o) === -1);

    const unique = unique1.concat(unique2);

    this.scheduleData.forEach((it: any, i: number) => {
      unique.forEach((id) => {
        let data = this.stageMappingActualList.filter((x: any) => x.stageMappingId == id);
        if (data != null && data.length > 0) {
          if (i != index) {
            if (this.stageMappingTypeData[index][0].stageMappingId != id) {
              selectArray[i].push({ stageMappingName: data[0].stageMappingName, stageMappingId: data[0].stageMappingId })
              selectArray[i].sort((a: any, b: any) => a.stageMappingId - b.stageMappingId)
            }
          }
        }
      })
    });

    this.stageMappingList = [];
    this.stageMappingList = selectArray;
  }

  onDeselectStageMapping(event: any, index: number) {
    let deSelectArray: any[] = [];
    this.scheduleData.forEach((it: any, i: number) => {
      deSelectArray[i] = [...this.stageMappingList[i]];
      if (index != i) {
        deSelectArray[i].push({ stageMappingId: event.stageMappingId, stageMappingName: event.stageMappingName })
        deSelectArray[i].sort((a: any, b: any) => a.stageMappingId - b.stageMappingId)
      }
    });

    this.stageMappingList = [];
    this.stageMappingList = deSelectArray;
  }

  saveScheduleConfigurations() {
    this.hexLoader = true;
    this.toastr.clear();
    this.modifiedData.entityId = this.selectedEntity?.entityId;
    this.modifiedData.l2ServiceId = this.activityTypeDetails[0]?.activityTypeId;
    this.modifiedData.enateServiceGuid = this.serviceNameDetails[0]?.enateServiceGuid;
    this.modifiedData?.scheduleHeaderDetails.forEach(function (val: any, key: any) {
      val.isStageMappingChanged = val?.isStageMappingChanged ? val.isStageMappingChanged : 'false';
      val.stageMappingId = val.stageMappingId == 0 ? null : val.stageMappingId;
    });

    this.entitiesService.saveSchedule(this.modifiedData).subscribe({
      next: res => {
        this.modifiedData.scheduleHeaderDetails = [];
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.statusCodeService.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else {
          this.statusCodeService.displayToastrMessage(res?.statusCode, 'success', 'common');
          if (this.saveSchedule && this.save) {
            this.formSaved.emit(false);
            this.getScheduleDetails();
            this.saveSchedule = false;
          } else {
            this.formSaved.emit(true);
          }
        }
        this.hexLoader = false;
        // audit track
        const desc = '[' + this.selectedEntity?.entityName + '] ' + this.translate.instant('auditTrail.entities.editSchedule');
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('common.edit'),
          desc)
      }, error: error => {
        this.formSaved.emit(false);
        this.hexLoader = false;
      }
    });
  }

  checkModules($event: any, index: number, item: any, block: any) {
    let itemFound = false;
    const filteredData = this.modifiedData.scheduleHeaderDetails.filter((data: any) => data.scheduleHeader === item.scheduleHeader);

    if (filteredData.length > 0) {
      itemFound = true;
      const matchedItem = filteredData[0];
      if ($event?.stageMappingId) {
        matchedItem.stageMappingId = $event.stageMappingId;
      }
      this.addModifiedData(index, matchedItem, block, false);
    }

    if (!itemFound) {
      if ($event?.stageMappingId) {
        item.stageMappingId = $event.stageMappingId;
      }
      this.addModifiedData(index, item, block, true);
    }
    this.valueChange.emit(true);
    this.saveSchedule = true;
  }

  emitToggle($event: any, schedule: any) {
    const index = this.scheduleData.findIndex((item: any) => item.scheduleHeader == schedule.scheduleHeader);
    $event ? schedule.isDisplayed = 1 : schedule.isDisplayed = 0;
    this.checkModules($event, index, schedule, 'isDisplayed');
  }

  addModifiedData(index: number, data: any, block: any, isNew: boolean) {
    if (isNew) {
      this.modifiedData.scheduleHeaderDetails.push(data);
    }
    switch (block) {
      case 'isDisplayed':
        data.isDisplayed = data.isDisplayed ? 1 : 0;
        break;
      case 'aliasHeaderName':
        data.aliasHeaderName = data.aliasHeaderName;
        break;
      case 'stageMappingSelect':
        this.scheduleData[index]['stageMappingId'] = data.stageMappingId;
        this.scheduleData[index]['isStageMappingChanged'] = 'true';
        break;
      case 'stageMappingUnSelect':
        this.scheduleData[index]['isStageMappingChanged'] = 'true';
        this.scheduleData[index]['stageMappingId'] = null;
        break;
    }
  }

  disableLoader() {
    if (this.hexLoader) { this.hexLoader = false };
    if (this.isLoader) { this.isLoader = false; }
  }
}