import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import * as ExcelJS from 'exceljs';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileUploadComponent {
  @Output() closeFileUpload = new EventEmitter();
  @Output() emitFile = new EventEmitter();
  @Input() disable: boolean = false;
  @Input() component: string = '';
  @Input() allowedFormats: string = 'ppt,pptx,xls,xlsx,ods,doc,docx,pdf,txt,rtf,odt,msg,jpg,jpeg,png,bmp,zip,7z,csv';
  droppedFiles: any = [];
  selectedFile: any;
  @Input() maxFileSizeMB = 100;
  @Input() emptyFileCheck: boolean = false;
  multiple: boolean = false;

  constructor(private toastr: ToastrService, private translate: TranslateService) { }

  dropped(files: any[]) {
    if (files.length > 1) {
      this.toastr.warning('Please choose single file');
    } else {
      const allowedFormatsArray = this.allowedFormats.split(',');
      const fileType = this.findFileType(files[0].relativePath);

      this.droppedFiles = files;
      for (const droppedFile of files) {

        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {

            // Check file format
            if (!allowedFormatsArray.includes(fileType.toLowerCase())) {
              if (fileType !== 'xlsx' && this.emptyFileCheck) {
                this.toastr.warning(this.translate.instant('common.warning.excelFormatAllowed'));
                this.resetFiles();
                return;
              } else if(!allowedFormatsArray.includes(fileType.toLowerCase())) {
                this.toastr.warning(this.translate.instant('common.warning.invalidFileFormat'));
                this.resetFiles();
                return;
              }
            }
            // check for empty file
            else if (file.size === 0) {
              this.toastr.warning(this.translate.instant('entities.error.emptyFile'));
              this.resetFiles();
              return;
            }
            // check file size
            else if (file.size > this.maxFileSizeMB * 1024 * 1024) {
              this.toastr.warning(this.translate.instant('common.warning.fileSizeExceeded', { size: this.maxFileSizeMB }));
              this.resetFiles();
              return;
            }
            this.checkEmptyFile(file);
          });
        } else {
          // It was a directory (empty directories are added, otherwise only files)
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
          // Directory handling logic could be added here if needed
        }
      }
    }
  }

  checkEmptyFile(file: File) {
    const reader = new FileReader();

    if (this.emptyFileCheck) {
      reader.onload = async (e: any) => {
        const arrayBuffer = e.target.result;

        try {
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(arrayBuffer);

          const worksheet = workbook.worksheets[0]; // Get the first sheet
          const sheetData: any[][] = [];

          worksheet.eachRow((row, rowNumber) => {
            const rowData: any = [];
            row.eachCell((cell) => {
              rowData.push(cell.text);
            });
            sheetData.push(rowData);
          });

          // Check if sheet is empty or all cells are empty
          if (sheetData.length === 0 || sheetData.every(row => row.every(cell => cell === ""))) {
            this.toastr.warning(this.translate.instant('entities.error.emptyFile'));
            this.resetFiles();
          } else {
            this.selectedFile = file;
            this.uploadFiles();
          }
        } catch (error) {
          this.toastr.error('Error reading file.');
        }
      };
    } else {
      this.selectedFile = file;
      this.uploadFiles();
    }

    reader.onerror = (error) => {
      this.toastr.error('Error reading file.');
    };
    reader.readAsArrayBuffer(file);
  }

  resetFiles() {
    this.selectedFile = null;
    this.droppedFiles = [];
  }

  uploadFiles() {
    this.emitFile.emit(this.selectedFile);
    this.closeFileUpload.emit();
    this.droppedFiles = [];
  }

  findFileType(fileName: string): string {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return '';
    }
    return fileName.substring(lastDotIndex + 1);
  }

}