import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { EntitiesService } from '../../../../modules/entities/services/entities.service';
import { StatusCodeService } from '../../../services/status-code/status-code.service';
import { UserPermissionsService } from '../../../services/user-permissons/user-permissions.service';
import { ToastrService } from 'ngx-toastr';
import { auditTracking } from '../../../../utils/audit-tracking';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-services',
  standalone: false,
  templateUrl: './services.component.html',
  styleUrl: './services.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ServicesComponent implements OnInit {
  @Input() selectedEntity: any;
  @Input() switchingTab: string = '';
  listOfServiceLine: any[] = [];
  noServiceLine: any;
  hexLoader: boolean = false;
  isLoader: boolean = false;
  l2Services: any[] = [];
  contractList: any[] = [];
  serviceList: any[] = [];
  selectedServiceLine: any;
  contractDetails: any = [];
  serviceDetails: any[] = [];
  l2ServiceDetails: any;
  contractServiceDetails: any;
  confirmPopup: string = '';
  @Input() disableEdit: boolean = false;
  @Output() formSaved = new EventEmitter();
  @Output() formValid = new EventEmitter();
  @Input() saveForm: boolean = false;
  @Output() valueChange = new EventEmitter();
  saveBody: string = '';
  addNewContract: boolean = true;
  modifyServices: boolean = false;
  selectedService: any;
  @Input() enateEntityGuid: string = '';
  serviceArray: any = [];
  save: boolean = false;

  contractSettings: IDropdownSettings = {
    singleSelection: true, idField: 'contractId', textField: 'name', maxHeight: 100,
    noDataAvailablePlaceholderText: this.translate.instant('common.nodata'), noFilteredDataAvailablePlaceholderText: this.translate.instant('common.nodata'), closeDropDownOnSelection: true
  };

  serviceSettings: IDropdownSettings = {
    singleSelection: false, idField: 'serviceGUID', textField: 'serviceName', itemsShowLimit: 2, allowSearchFilter: true, selectAllText: '', unSelectAllText: '', maxHeight: 100, closeDropDownOnSelection: true,
    noDataAvailablePlaceholderText: this.translate.instant('common.nodata'), noFilteredDataAvailablePlaceholderText: this.translate.instant('common.nodata')
  };

  constructor(private entitiesService: EntitiesService, private statusCodeService: StatusCodeService, private userPermissionsService: UserPermissionsService,
    private toastr: ToastrService, private translate: TranslateService, private cdr: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['disableEdit']?.firstChange && this.disableEdit) {
      this.listOfServiceLine = JSON.parse(this.saveBody);
      this.selectedServiceLine = this.listOfServiceLine[0];
    }

    if ((!changes['switchingTab']?.firstChange && this.switchingTab) || this.saveForm) {
      this.saveServiceLineConfig();
    }
  }

  ngOnInit(): void {
    this.fetchEntityServices();
    setTimeout(() => {
      this.getContractList();
      this.getL2ServiceList();
    }, 1000)
  }

  getActions(action: string) { return this.userPermissionsService.isActions('Entities', action); }

  fetchEntityServices() {
    this.isLoader = true;
    this.hexLoader = true;
    this.entitiesService.fetchEntityServices(this.selectedEntity?.entityId)?.subscribe({
      next: (response) => {
        if (response.data) {
          this.listOfServiceLine = [...response.data];
          this.saveBody = JSON.stringify(response.data);
        }
        this.selectedServiceLine = this.listOfServiceLine[0];
        const desc = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.viewHrServices');
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('auditTrail.auditTypeView'),
          desc);
        this.disableLoader();
      }, error: (error) => {
        this.disableLoader();
      }
    })
  }

  saveService() {
    this.save = true;
    this.addNewContract = false;
    this.modifyServices = true;
    this.saveServiceLineConfig();
  }

  saveServiceLineConfig() {
    this.hexLoader = true;
    let serviceLines = {
      serviceLineId: this.selectedServiceLine?.serviceLineId,
      isServiceEnabled: this.selectedServiceLine?.isServiceEnabled,
      contractGuid: this.contractDetails[0]?.contractGUID,
      serviceGuid: this.serviceDetails
    }

    let body: any = {
      entityId: this.selectedEntity?.entityId,
      servicelines: [serviceLines]
    };

    this.entitiesService.saveServiceLines(body).subscribe({
      next: (response) => {
        this.toastr.clear();
        this.hexLoader = false;
        if (response?.statusCode === 'UNAUTHORIZED_USER') {
          this.statusCodeService.displayToastrMessage(response?.statusCode, 'error', 'entities');
        } else {
          this.statusCodeService.displayToastrMessage(response?.statusCode, 'success', 'common');
          this.resetL2ServiceList();
          this.resetContractDetails();
          this.getContractList();
          this.valueChange.emit(true);
          if (!this.save) {
            this.formSaved.emit(true);
          }
          this.save = false;
        }
        const desc = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.editHrServices');
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('common.edit'),
          desc)
      },
      error: (error) => {
        this.hexLoader = false;
        this.formSaved.emit(false);
      }
    })
  }

  checkFormChanges(serviceName: any, index: number) {
    this.selectedServiceLine = this.listOfServiceLine[index];
    this.resetContractDetails();
    this.getContractList();
    this.resetL2ServiceList();
    this.addNewContract = true;
    this.modifyServices = false;
  }

  onDeselectContract() {
    this.serviceList = [];
    this.serviceArray = [];
    this.serviceDetails = [];
  }

  onDeselectService() {
    this.serviceDetails = this.serviceArray.map((service: any) => service.serviceGUID);
  }

  getContractList() {
    let body = {
      "enateEntityGuid": this.enateEntityGuid,
      "serviceLineId": this.selectedServiceLine?.serviceLineId
    }

    this.entitiesService.getContractAndServiceList(body).subscribe({
      next: response => {
        if (response.data) {
          this.contractList = response.data;
          this.contractList = response.data.map((contract: any) => ({
            name: contract.name,
            contractId: contract.contractId,
            service: contract.service,
            contractGUID: contract.contractGUID
          }));
          this.disableLoader();
        }
      }, error: error => {
        this.hexLoader = false;
      }
    })
  }

  resetContractDetails() {
    this.contractDetails = [];
    this.serviceArray = [];
    this.serviceDetails = [];
    this.serviceList = [];
  }

  fetchServices() {
    this.serviceArray = [];
    this.serviceDetails = [];
    let contract = this.contractList.find(
      (service) => service.contractId === this.contractDetails[0].contractId
    );
    this.serviceList = contract ? contract?.service : [];
    this.contractDetails[0].contractGUID = contract.contractGUID;
  }

  onSelectService() {
    this.serviceDetails = this.serviceArray.map((service: any) => service.serviceGUID);
  }

  resetL2ServiceList() {
    this.l2ServiceDetails = [];
    this.contractServiceDetails = [];
    this.getL2ServiceList();
  }

  getL2ServiceList() {
    this.toastr.clear();
    this.hexLoader = true;
    let body: any = {
      entityId: this.selectedEntity?.entityId,
      serviceLineId: this.selectedServiceLine?.serviceLineId
    }

    this.entitiesService.getL2ServicesList(body).subscribe({
      next: response => {
        this.hexLoader = false;
        this.l2ServiceDetails = response.data;
        this.contractServiceDetails = this.l2ServiceDetails[0]?.contractServiceList;
      }, error: error => {
        this.hexLoader = false;
      }
    })
  }

  noL2Services() {
    let l2Services: any = [];
    this.listOfServiceLine?.forEach((it: any) => {
      let index = this.l2ServiceDetails?.findIndex((item: any) => {
        return item?.serviceLineName === it?.serviceLineName
      });

      if (this.l2ServiceDetails) {
        const l2ServiceList = this.l2ServiceDetails[index]?.contractServiceList[index]?.l1ServiceList;
        if (index > -1 && l2ServiceList?.length > 0) {
          l2Services = l2ServiceList;
        }
      }
    })
    return l2Services?.length > 0;
  }

  enableDisableL2Service(event: any, l1Service: any, l2Service: any) {
    this.toastr.clear();
    this.hexLoader = true;

    let body: any = {
      entityId: this.selectedEntity?.entityId,
      serviceGUID: l1Service?.enateServiceGuid,
    };

    event ?
      body.l2ServiceId = [l2Service?.l2ServiceId] :
      body.enateProcessGuid = [l2Service?.enateProcessGuid]

    const fetchMethod = event
      ? this.entitiesService.enableL2Service
      : this.entitiesService.deleteL2Service;

    fetchMethod.call(this.entitiesService, body).subscribe({
      next: (res: any) => {
        this.hexLoader = false;
        this.resetL2ServiceList();
        if (res.statusCode === 'COBALT_002_110') {
          this.toastr.success(this.translate.instant('entities.success.serviceDisable'));
        } else if (res.statusCode === 'COBALT_002_113') {
          this.toastr.success(this.translate.instant('entities.success.serviceEnable'));
        }
        this.valueChange.emit(true);
      },
      error: () => this.disableLoader()
    });
  }

  disableLoader() {
    if (this.isLoader) this.isLoader = false;
    if (this.hexLoader) this.hexLoader = false;
  }

  deleteAllService() {
    this.toastr.clear();
    this.hexLoader = true;
    let body = {
      entityId: this.selectedEntity?.entityId,
      serviceLineId: this.selectedServiceLine?.serviceLineId
    }
    this.entitiesService.deleteAllService(body).subscribe({
      next: res => {
        this.hexLoader = false;
        if (res.statusCode === 'COBALT_002_111') {
          this.toastr.success(this.translate.instant('entities.success.allServiceDelete'));
          this.valueChange.emit(true);
          this.getContractList();
        }
        this.resetL2ServiceList();
      }, error: error => {
        this.hexLoader = false;
      }
    })
  }

  inlineDelete(serviceItem: any) {
    this.confirmPopup = 'delete';
    this.selectedService = serviceItem;
  }

  deleteL2Service() {
    this.toastr.clear();
    this.hexLoader = true;
    let body = {
      entityId: this.selectedEntity?.entityId,
      serviceGUID: this.selectedService?.enateServiceGuid,
    }
    this.entitiesService.deleteL2Service(body).subscribe({
      next: res => {
        this.hexLoader = false;
        if (res.statusCode === 'COBALT_002_110') {
          this.toastr.success(this.translate.instant('entities.success.serviceContractDelete'));
          this.valueChange.emit(true);
          this.getContractList();
        }
        this.resetL2ServiceList();
      }, error: error => {
        this.hexLoader = false;
      }
    })
  }

  handleConfirmation(ev: boolean) {
    if (ev) {
      if (this.confirmPopup === 'delete') {
        this.deleteL2Service();
      } else if (this.confirmPopup === 'deleteAll') {
        this.deleteAllService();
      }
    }
    this.confirmPopup = '';
  }
}
