import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { EntitiesService } from './../../../../modules/entities/services/entities.service';
import { Component, EventEmitter, Input, Output, HostListener, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { StatusCodeService } from '../../../services/status-code/status-code.service';
import { UserPermissionsService } from '../../../services/user-permissons/user-permissions.service';
import { auditTracking } from '../../../../utils/audit-tracking';
import { DateFormatPipe } from '../../../pipes/dateFormat/date-format.pipe';
@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent {
  datePipe = new DateFormatPipe();
  @Input() selectedEntityClient: any;
  @Input() selectedEntity: any;
  entityInfo: any;
  uploadLogo: boolean = false;
  hexLoader: boolean = false;
  generalForm = this.formBuilder.group({
    clientId: [''],
    entityName: ['', [Validators.required, this.alphabetValidator]],
    enateEntity: [null, Validators.required],
    country: [null, Validators.required],
    platform: [null, Validators.required],
    contractStart: ['', Validators.required],
    contractEnd: [''], sameAsClientLogo: [0],
    logo: [],
    fetchAll: 0,
    links: ['', [Validators.pattern(/^(https?:\/\/)?([a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,6}(\/[a-zA-Z0-9\-._~]+(\/[a-zA-Z0-9\-._~]*)*)?$/i)]],
    description: ['']
  });
  enateEntities: any[] = [];
  countries: any[] = [];
  platforms: any[] = [];
  imageUrl: any;
  deleteLogo: boolean = false;
  logoFormat: boolean = false;
  thirdPartyLinks: any[] = [];
  @Input() disableEdit: boolean = false;
  @Output() saveDisabled = new EventEmitter<boolean>();
  @Input() clone: boolean = false;
  @Input() switchingTab: string = '';
  @Output() formSaved = new EventEmitter();
  @Output() formValid = new EventEmitter();
  @Input() saveForm: boolean = false;
  @Input() cloneSaved: boolean = false;
  searchSubject = new Subject<string>();
  isConfirmPopup: string = '';
  countryChanged: boolean = false;
  platformChanged: boolean = false;
  enateEntityRestrictOverlay: boolean = false;

  @HostListener('document:click', ['$event'])
  closeLogoFormat() { if (this.logoFormat) { this.logoFormat = false; } }

  constructor(private entitiesService: EntitiesService, private formBuilder: FormBuilder, private toastr: ToastrService, private translate: TranslateService,
    private statusCodeService: StatusCodeService, private userPermissionsService: UserPermissionsService) {
    this.entityNameCheck();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['disableEdit']?.firstChange && this.disableEdit) {
      this.generalForm.reset();
      this.updateGeneralForm();
    }

    if ((!changes['switchingTab']?.firstChange && this.switchingTab) || this.saveForm) {
      this.saveEntity();
    }
  }

  ngOnInit() {
    this.generalForm.patchValue({ clientId: this.selectedEntityClient?.clientId ?? this.selectedEntity?.clientId });

    if (this.selectedEntity) {
      this.hexLoader = true;
      this.getEntityInfo();
      if (this.clone && !this.cloneSaved) { this.getEnateEntities(); }
    } else {
      this.getEnateEntities();
      if (!this.clone) { this.getCountries(); }
    }

    this.checkFormChanges();
  }

  getActions(action: string) { return this.userPermissionsService.isActions('Entities', action); }

  getEntityInfo() {
    let body;
    if (this.clone && !this.cloneSaved) {
      body = { entityId: '', cloneEntityId: this.selectedEntity.entityId };
    } else { body = { entityId: this.selectedEntity.entityId, cloneEntityId: "" }; }

    this.entitiesService.getEntityInfo(body).subscribe({
      next: res => {
        this.entityInfo = res.data;
        this.setAuditTracking('auditTrail.entities.viewEntityGeneral', 'auditTrail.entities.entityDetail', 'auditTrail.auditTypeView', this.selectedEntity?.clientName, this.selectedEntity?.entityName, this.selectedEntity?.entityName, true);
        if (this.entityInfo) {
          this.updateGeneralForm();
        } else { this.hexLoader = false; }
        if (!this.clone && this.cloneSaved) {
          this.getCountries();
        }
      }, error: error => { this.hexLoader = false; }
    })
  }

  updateGeneralForm() {
    this.generalForm.patchValue({
      clientId: (this.clone && !this.cloneSaved) ? (this.selectedEntityClient?.clientId ?? this.entityInfo?.clientId) : this.entityInfo?.clientId,
      entityName: this.entityInfo?.name, enateEntity: this.entityInfo?.enateName,
      country: this.entityInfo?.countryId, platform: this.entityInfo?.platformId,
      contractStart: this.entityInfo?.contractStartDate ? moment.utc(this.entityInfo.contractStartDate).format('YYYY-MM-DD') : null,
      contractEnd: this.entityInfo?.contractEndDate ? moment.utc(this.entityInfo.contractEndDate).format('YYYY-MM-DD') : null,
      logo: this.entityInfo?.logoUrl, sameAsClientLogo: this.entityInfo?.sameAsClientLogo, links: '', description: '',
      fetchAll: this.entityInfo?.fetchAll
    });
    this.thirdPartyLinks = this.entityInfo?.thirdPartyLinks?.length > 0 ? [...this.entityInfo?.thirdPartyLinks] : [];
    if (this.entityInfo?.logoUrl) { this.imageUrl = { objectUrl: this.entityInfo?.logoUrl }; }
    else { this.imageUrl = null; }
    this.deleteLogo = this.entityInfo?.deleteLogo;
    this.hexLoader = false;
    if (!this.clone || this.cloneSaved) { this.getPlatformByCountry(); }
    this.countryChanged = false;
    this.platformChanged = false;
  }

  getEnateEntities() {
    this.entitiesService.getEnateEntities().subscribe({
      next: res => {
        this.enateEntities = res.data ? res.data : [];
      },
      error: error => { }
    })
  }

  getCountries() {
    this.entitiesService.fetchCountryList().subscribe({
      next: res => { this.countries = res.data ? res.data : []; },
      error: error => { }
    })
  }

  getPlatformByCountry() {
    this.entitiesService.fetchPlatformListByCountry({ clientIds: [], countryIds: [this.generalForm?.value?.country], isListingPage: false }).subscribe({
      next: res => { this.platforms = res.data ? res.data : []; },
      error: error => { this.platforms = []; }
    })
  }

  changedCountry() {
    if (this.entityInfo && !this.countryChanged) { this.isConfirmPopup = 'country'; }
    else { this.resetPlatforms(); }
  }

  resetPlatforms() {
    if (this.entityInfo && (!this.clone || this.cloneSaved)) {
      this.countryChanged = true;
      this.platformChanged = true;
    }
    this.generalForm.patchValue({ platform: null });
    this.getPlatformByCountry();
    if (this.generalForm.value.country && this.generalForm.value?.entityName) {
      this.searchSubject.next(this.generalForm.value?.entityName ?? '');
    }
  }

  onPlatformChange() {
    if (this.entityInfo && (this.generalForm.value.country === this.entityInfo?.countryId) && !this.platformChanged) {
      this.isConfirmPopup = 'platform';
    }
  }

  receivedImage(imageFile: any) {
    this.uploadLogo = false;
    if (imageFile) {
      this.generalForm.patchValue({ logo: imageFile });
      this.imageUrl = imageFile;
      this.deleteLogo = false;
    }
    this.generalForm.markAsDirty();
    this.formValid.emit(this.generalForm.dirty && this.generalForm.valid);
  }

  saveEntity() {
    if (this.generalForm.valid && this.generalForm.dirty) {
      let saveBody = {
        clonedEntityId: (this.clone && !this.cloneSaved) ? this.selectedEntity?.entityId : null,
        entityId: (!this.clone || this.cloneSaved) ? this.selectedEntity?.entityId : null,
        name: this.generalForm.value.entityName?.trim(),
        enateGUID: this.entityInfo?.enateEntityGuid ?? this.generalForm.value.enateEntity,
        countryId: this.generalForm.value.country, clientId: this.generalForm.value?.clientId,
        platformId: this.generalForm.value.platform, contractStartDate: this.generalForm.value.contractStart,
        contractEndDate: this.generalForm.value.contractEnd ? this.generalForm.value.contractEnd : null,
        sameAsClientLogo: this.generalForm.value.sameAsClientLogo, thirdPartyLinks: this.thirdPartyLinks,
        deleteLogo: this.deleteLogo,
        fetchAll: this.generalForm?.value.fetchAll,
        fileName: this.imageUrl?.fileName
      };

      let formdata = new FormData();
      formdata.append('data', new Blob([JSON.stringify(saveBody)], {
        type: 'application/json'
      }));
      if (this.imageUrl) {
        formdata.append("file", this.imageUrl.blob);
      }
      if (this.generalForm.value.links) {
        this.toastr.warning(this.translate.instant('entities.general.addLink'));
        this.saveForm = false;
        setTimeout(() => { this.formSaved.emit(false); }, 100);
      } else { this.createUpdateEntity(formdata); }

    } else {
      this.generalForm.markAllAsTouched();
      setTimeout(() => { this.formSaved.emit(false); }, 100);
    }
  }

  // create new entity
  createUpdateEntity(formdata: FormData) {
    this.hexLoader = true;
    this.entitiesService.createUpdateEntity(formdata).subscribe({
      next: res => {
        if (res?.statusCode !== 'COBALT_002_006' && res?.statusCode !== 'UNAUTHORIZED_USER') {
          this.formSaved.emit({
            entityId: res.data ? res.data : this.selectedEntity?.entityId,
            entityName: this.generalForm.value.entityName
          });
          // checking condition for audit track
          if (this.selectedEntityClient || (this.clone && !this.cloneSaved)) {
            this.setAuditTracking('auditTrail.entities.createNewEntity', 'auditTrail.entities.createEntity', 'common.create', this.selectedEntityClient?.clientName ? this.selectedEntityClient?.clientName : this.selectedEntity?.clientName, this.generalForm.value.entityName, this.generalForm.value.entityName, false);
            this.statusCodeService.displayToastrMessage(res?.statusCode, 'success', 'entities');
          }
          else {
            this.setAuditTracking('auditTrail.entities.updatedEntity', 'auditTrail.entities.entityDetail', 'common.edit', this.selectedEntity?.clientName, this.generalForm.value.entityName, this.generalForm.value.entityName, true);
            this.statusCodeService.displayToastrMessage(res?.statusCode, 'success', 'common');
          }
        } else {
          this.statusCodeService.displayToastrMessage(res?.statusCode, 'error', 'entities');
        }
        this.hexLoader = false;
      },
      error: error => {
        this.formSaved.emit(false);
        this.hexLoader = false;
      }
    })
  }
  setAuditTracking(actionDesc: string, processName: string, action: string, clientName: string, entityName: any, addedValue: any, showNameFirst: boolean) {
    let description;
    if (showNameFirst) {
      description = '[' + addedValue + ']' + this.translate.instant(actionDesc);
    } else {
      if (actionDesc === 'auditTrail.entities.createNewEntity') {
        description = this.translate.instant(actionDesc) + '[' + clientName + ']' + ' -- Add New Entity [' + entityName + ']';
      } else {
        description = this.translate.instant(actionDesc) + addedValue;
      }
    }
    auditTracking(clientName, entityName,
      this.translate.instant(processName),
      this.translate.instant(action),
      description)
  }

  alphabetValidator(control: any) {
    const value = control.value;
    return /[a-zA-Z]/.test(value) ? null : { noAlphabet: true };
  }

  onEntityNameChange(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    const hasSpecialChar = /[^a-zA-Z0-9]/.test(input);
    const hasAlphaNumeric = /[a-zA-Z0-9]/.test(input);
    this.saveDisabled.emit(hasSpecialChar && !hasAlphaNumeric);
    this.searchSubject.next(this.generalForm.value.entityName ?? '');
  }

  // validations
  entityNameCheck() {
    this.searchSubject.pipe(debounceTime(500)).subscribe(keyword => {
      if (!this.generalForm.value.entityName?.trim()) {
        this.generalForm.get('entityName')?.setErrors({ required: true });
      } else {
        this.clearEntityNameErrors();
        if (this.generalForm.value.country && this.generalForm.value.entityName && (this.generalForm.value.entityName.trim() !== this.entityInfo?.name)) {
          this.uniqueEntityNameCheck();
        }
      }
    });
  }

  onDateChange() {
    const startDate = this.generalForm.value.contractStart;
    const endDate = this.generalForm.value.contractEnd;

    if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
      this.generalForm.get('contractEnd')?.setErrors({ dateComparison: true });
    } else {
      this.generalForm.get('contractEnd')?.setErrors(null);
    }
  }

  addThirdPartyLinks() {
    if (!this.generalForm.value.links && this.generalForm.value.description) { this.generalForm.get('links')?.setErrors({ required: true }); }
    else if (this.findDuplicates()) { this.toastr.error(this.translate.instant('entities.general.linkExists')); }
    else if (this.thirdPartyLinks.length === 20) { this.toastr.error(this.translate.instant('entities.general.maxLinks')); }
    else if (!this.generalForm.controls['links'].getError('pattern') && this.generalForm.value.links) {
      this.generalForm.get('links')?.setErrors(null);
      this.thirdPartyLinks.push({ urlLink: this.generalForm.value?.links, desc: this.generalForm.value.description });
      this.generalForm.patchValue({ links: '', description: '' });
    }
  }

  findDuplicates() {
    let linksArray = this.thirdPartyLinks.map(it => { return it?.urlLink?.toLocaleLowerCase() });
    return linksArray?.includes(this.generalForm?.value?.links?.toLocaleLowerCase() ?? '');
  }

  removeLink(link: any) {
    this.thirdPartyLinks = this.thirdPartyLinks.filter(it => { return it.urlLink !== link?.urlLink });
    this.generalForm.markAsDirty();
    this.updateFormValidation();
  }

  deleteClientLogo() {
    this.imageUrl = '';
    this.deleteLogo = true;
    this.generalForm.patchValue({ logo: null });
    this.generalForm.markAsDirty();
    this.formValid.emit(this.generalForm.dirty);
  }

  checkFormChanges() {
    this.generalForm.valueChanges.subscribe(value => {
      if (!this.generalForm.value.description && !this.generalForm.value.links) {
        this.generalForm.get('links')?.setErrors(null);
      }
      if (this.selectedEntityClient || (this.clone && !this.cloneSaved)) {
        this.formValid.emit(this.generalForm.valid);
      } else if (this.selectedEntity) {
        if ((this.generalForm.value.links && this.generalForm.value.description) || !this.generalForm.value.description) {
          this.formValid.emit(this.generalForm.dirty);
        } else {
          if (!this.generalForm.value.links && this.generalForm.value.description) {
            this.generalForm.get('links')?.setErrors({ required: true });
          }
        }
      }
      setTimeout(() => { this.updateFormValidation(); }, 10);
    })
  }

  switchClientLogo() {
    this.generalForm.patchValue({ sameAsClientLogo: this.generalForm.value.sameAsClientLogo === 1 ? 0 : 1 });
    this.generalForm.markAsDirty();
    this.formValid.emit(this.generalForm.dirty && this.generalForm.valid);
    if (this.generalForm.value.sameAsClientLogo === 1) { this.imageUrl = ''; }
    else { if (this.entityInfo?.logoUrl) { this.imageUrl = { objectUrl: this.entityInfo?.logoUrl }; } }
  }

  uniqueEntityNameCheck() {
    this.hexLoader = true;
    const entityNameControl = this.generalForm.get('entityName');
    const entityNameValue = entityNameControl?.value?.trim() || '';
    if (!/[a-zA-Z]/.test(entityNameValue)) {
      entityNameControl?.setErrors({ noAlphabet: true });
      this.hexLoader = false;
      return;
    } else {
      entityNameControl?.setErrors(null);
    }
  
    let body = {
      "clientId": this.generalForm.value.clientId ?? "",
      "entityName": entityNameValue,
      "countryId": this.generalForm.value.country ?? ""
    }
    this.entitiesService.uniqueEntityNameCheck(body).subscribe({
      next: res => {
        if (res.data) {
          if (res.data?.isExistWithDiffClient) { this.generalForm.get('entityName')?.setErrors({ isExistWithDiffClient: true }); this.updateFormValidation(); }
          else if (res.data?.isExistWithSameClient) {
            this.generalForm.get('entityName')?.setErrors({ isExistWithSameClient: true });
            this.generalForm.get('country')?.setErrors({ isExistWithSameClient: true });
            this.updateFormValidation();
          } else { this.clearEntityNameErrors(); }
        } else { this.clearEntityNameErrors(); }
        this.hexLoader = false;
      }, error: error => {
        this.clearEntityNameErrors();
        this.hexLoader = false;
      }
    })
  }

  clearEntityNameErrors() {
    this.generalForm.get('entityName')?.setErrors(null);
    this.updateCountryErrors();
    if (!this.generalForm.value.entityName) {
      this.generalForm.get('entityName')?.setErrors({ required: true });
    }
  }

  updateCountryErrors() {
    let errors: any = this.generalForm.get('country')?.errors;
    if (errors && errors['isExistWithSameClient']) { delete errors['isExistWithSameClient']; }
    if (errors && Object?.values(errors)?.length > 0) { this.generalForm.get('country')?.setErrors(errors); }
    else { this.generalForm.get('country')?.setErrors(null); }
  }

  handleConfirmation(event: boolean) {
    if (!event) {
      if (this.isConfirmPopup === 'country') { this.generalForm.patchValue({ country: this.entityInfo?.countryId }); }
      else if (this.isConfirmPopup === 'platform') { this.generalForm.patchValue({ platform: this.entityInfo?.platformId }); }
    } else {
      if (this.isConfirmPopup === 'country') { this.resetPlatforms(); }
      else { this.platformChanged = true; }
    }
    this.isConfirmPopup = '';
  }

  updateFormValidation() {
    if (this.generalForm.valid && this.generalForm.dirty) { this.formValid.emit(true); }
    else { this.formValid.emit(false); }
  }

  toggleFetchAll(event: any) {
    this.entityInfo.fetchAll = event ? 1 : 0;
  }
}
