import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    standalone: false
})
export class CheckboxComponent {
  @Input() tableHeader: boolean = false;
  @Input() table: boolean = false;
  @Input() size: string = '';
  @Input() label: string = '';
  @Input() checked: boolean = false;
  @Output() onClick = new EventEmitter();
  @Input() bg: string = '';
  @Input() disabled: boolean = false;
  @Input() isMappingField: boolean = false;
  @Input() alignBaseline: boolean = false;

  onChange(event: any) {
    this.onClick.emit(event.target.checked);
  }
}
