import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrl: './progress-bar.component.scss',
    standalone: false
})
export class ProgressBarComponent {
  @Input() progress: any = 0;
  @Input() status: string = 'in-progress';
  @Input() inline: boolean = false;
  @Input() currentFile: number = 0;
  @Input() totalFile: number = 0;

}
