import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-logo-format',
    templateUrl: './logo-format.component.html',
    styleUrl: './logo-format.component.scss',
    standalone: false
})
export class LogoFormatComponent {
  @Input() alignMiddle: boolean = false;
}
