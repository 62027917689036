import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { CryptoService } from '../shared/services/crypto/crypto.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private crypto: CryptoService) { }

  canActivate(route: ActivatedRouteSnapshot) {
    if (localStorage.getItem('user-details')) {
      let userDetails = JSON.parse(this.crypto.decrypt(localStorage.getItem('user-details') ?? ''));
      let currentUser = userDetails?.userType;

      if (currentUser) {
        // check if route is restricted by role
        if (route?.data?.['roles']?.indexOf(currentUser) === -1) {
          // role not authorised so redirect to home page
          this.router.navigate(['my-workspace']);
          return false;
        }

        // authorised so return true
        return true;
      }
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['login']);
    return false;
  }
}
