import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserPermissionsService } from '../../../services/user-permissons/user-permissions.service';

@Component({
  selector: 'app-entity-detail',
  templateUrl: './entity-detail.component.html',
  styleUrls: ['./entity-detail.component.scss']
})
export class EntityDetailComponent {
  @Input() selectedEntityClient: any;
  @Input() selectedEntity: any;
  @Output() closeEntityDetail = new EventEmitter();
  selectedTab: string = 'General';
  @Input() clone: boolean = false;
  switchingTab: string = '';
  disableEdit: boolean = false;
  disableSave: boolean = false;
  specialCharDisable = false;
  saveForm: boolean = false;
  cloneSaved: boolean = false;
  disableButtons: boolean = false;
  isConfirmPopup: boolean = false;
  @Input() editable: boolean = false;

  constructor(private userPermissionsService: UserPermissionsService) { }

  ngOnInit() {
    this.disableEdit = true;
    if (this.selectedEntityClient || this.clone || this.editable) {
      this.disableSave = true;
      this.disableEdit = false;
    }
    this.tabSelection();
  }

  tabSelection() {
    if (!this.selectedEntityClient && !this.clone && !this.cloneSaved) {
      if (this.getActions('Manage General Information') || this.getActions('View General Information')) { this.selectedTab = 'General'; }
      else if (this.getActions('Manage Service Configuration') || this.getActions('View Service Configuration')) { this.selectedTab = 'Services'; }
      else if (this.getActions('Manage Payroll Information') || this.getActions('View Payroll Information')) { this.selectedTab = 'Payroll Basic Info'; }
      else if (this.getActions('Manage Pay Elements') || this.getActions('View Pay Elements')) { this.selectedTab = 'Pay Elements'; }
      else if (this.getActions('Manage Reference data') || this.getActions('View Reference data')) { this.selectedTab = 'Entity Reference Data'; }
      else if (this.getActions('Manage Schedule') || this.getActions('View Schedule')) { this.selectedTab = 'Schedule'; }
      else if (this.getActions('Manage Approvals') || this.getActions('View Approvals')) { this.selectedTab = 'Approvals'; }
    }
  }

  getActions(action: string) { return this.userPermissionsService.isActions('Entities', action); }

  selectEntityTab(tab: string) {
    if (this.selectedTab === 'Pay Elements') {
      this.selectedTab = tab;
    } else {
      if ((this.disableEdit || (this.disableSave && (!this.clone || this.cloneSaved))) && this.selectedEntity) { this.selectedTab = tab; }
      else { this.switchingTab = tab; }
    }
  }

  formSubmission(ev: any) {
    if (ev) {
      if (Object?.keys(ev)?.length > 0) {
        this.selectedEntity = {
          ...this.selectedEntity, entityId: ev?.entityId, entityName: ev?.entityName,
          clientName: this.selectedEntityClient?.clientName ?? this.selectedEntity?.clientName
        };
      }
      if (this.switchingTab) { this.selectedTab = this.switchingTab; }
      else {
        switch (this.selectedTab) {
          case 'General':
            if (this.getActions('Manage Service Configuration') || this.getActions('View Service Configuration')) { this.selectedTab = 'Services'; }
            else if (this.getActions('Manage Payroll Information') || this.getActions('View Payroll Information')) { this.selectedTab = 'Payroll Basic Info'; }
            else if (this.getActions('Manage Pay Elements') || this.getActions('View Pay Elements')) { this.selectedTab = 'Pay Elements'; }
            else if (this.getActions('Manage Reference data') || this.getActions('View Reference data')) { this.selectedTab = 'Entity Reference Data'; }
            else if (this.getActions('Manage Schedule') || this.getActions('View Schedule')) { this.selectedTab = 'Schedule'; }
            else if (this.getActions('Manage Approvals') || this.getActions('View Approvals')) { this.selectedTab = 'Approvals'; }
            break;

          case 'Services':
            if (this.getActions('Manage Payroll Information') || this.getActions('View Payroll Information')) { this.selectedTab = 'Payroll Basic Info'; }
            else if (this.getActions('Manage Pay Elements') || this.getActions('View Pay Elements')) { this.selectedTab = 'Pay Elements'; }
            else if (this.getActions('Manage Reference data') || this.getActions('View Reference data')) { this.selectedTab = 'Entity Reference Data'; }
            else if (this.getActions('Manage Schedule') || this.getActions('View Schedule')) { this.selectedTab = 'Schedule'; }
            else if (this.getActions('Manage Approvals') || this.getActions('View Approvals')) { this.selectedTab = 'Approvals'; }
            break;

          case 'Payroll Basic Info':
            if (this.getActions('Manage Pay Elements') || this.getActions('View Pay Elements')) { this.selectedTab = 'Pay Elements'; }
            else if (this.getActions('Manage Reference data') || this.getActions('View Reference data')) { this.selectedTab = 'Entity Reference Data'; }
            else if (this.getActions('Manage Schedule') || this.getActions('View Schedule')) { this.selectedTab = 'Schedule'; }
            else if (this.getActions('Manage Approvals') || this.getActions('View Approvals')) { this.selectedTab = 'Approvals'; }
            break;

          case 'Pay Elements':
            if (this.getActions('Manage Reference data') || this.getActions('View Reference data')) { this.selectedTab = 'Entity Reference Data'; }
            else if (this.getActions('Manage Schedule') || this.getActions('View Schedule')) { this.selectedTab = 'Schedule'; }
            else if (this.getActions('Manage Approvals') || this.getActions('View Approvals')) { this.selectedTab = 'Approvals'; }
            break;

          case 'Entity Reference Data':
            if (this.getActions('Manage Schedule') || this.getActions('View Schedule')) { this.selectedTab = 'Schedule'; }
            else if (this.getActions('Manage Approvals') || this.getActions('View Approvals')) { this.selectedTab = 'Approvals'; }
            break;

          case 'Schedule':
            if (this.getActions('Manage Approvals') || this.getActions('View Approvals')) { this.selectedTab = 'Approvals'; }
            break;
        }
      }
      if (this.clone) { this.cloneSaved = true; }
      this.disableSave = true;
    }
    this.switchingTab = '';
    this.saveForm = false;
  }

  cancelEntityDetail() {
    if (this.disableEdit) {
      this.closeEntityDetail.emit({ cloned: this.clone, refresh: this.selectedEntity ? true : false });
    } else {
      if (this.selectedEntityClient || (this.clone && !this.cloneSaved) || (this.editable && this.disableSave)) {
        this.closeEntityDetail.emit({ cloned: this.clone, refresh: this.selectedEntity ? true : false });
      } else {
        if (!this.disableSave) { this.isConfirmPopup = true; this.saveForm = false; }
        else { this.disableEdit = true; }
      }
    }
  }

  createSaveEntity() {
    if (this.disableEdit) {
      this.disableEdit = false;
      this.disableSave = true;
    } else { this.saveForm = true; }
  }

  handleConfirmation(ev: boolean) {
    if (ev) {
      if (this.editable) { this.closeEntityDetail.emit({ cloned: this.clone, refresh: this.selectedEntity ? true : false }); }
      else { this.disableEdit = true; }
    }
    this.isConfirmPopup = false;
  }
}

