import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-alphabet-filter-header',
  templateUrl: './alphabet-filter-header.component.html',
  styleUrl: './alphabet-filter-header.component.scss'
})
export class AlphabetFilterHeaderComponent {

  @Input() selectedCharacter: string = '';
  @Input() pageHeader: string = '';
  @Input() searchText: string = '';
  
}