import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class StatusCodeService {

  constructor(private translate: TranslateService, private toastr: ToastrService) { }

  displayToastrMessage(statusCode: string, status: string, modulePath: string, paramsObj?: any) {
    let message = '';

    switch (statusCode) {
      case 'COBALT_003_036':
      case 'COBALT_003_071':
      case 'COBALT_002_055':
      case 'COBALT_003_037':
      case 'COBALT_003_055':
      case 'COBALT_003_056':
      case 'COBALT_003_060':
      case 'COBALT_003_082':
      case 'COBALT_001_004':
      case 'COBALT_003_020':
      case 'COBALT_003_021':
      case 'COBALT_003_004':
      case 'EMPLOYEE_005_070':
      case 'EMPLOYEE_005_078':
      case 'EMPLOYEE_005_077':
      case 'EMPLOYEE_005_048':
      case 'EMPLOYEE_005_053':
      case 'EMPLOYEE_005_055':
      case 'EMPLOYEE_005_052':
      case 'EMPLOYEE_005_054':
      case 'EMPLOYEE_005_044':
      case 'EMPLOYEE_005_097':
      case 'EMPLOYEE_005_087':
      case 'EMPLOYEE_005_98':
      case 'EMPLOYEE_005_094':
      case 'EMPLOYEE_005_99':
      case 'EMPLOYEE_005_084':
      case "EMPLOYEE_005_074":
      case "EMPLOYEE_005_076":
      case "EMPLOYEE_005_067":
      case "COBALT_008_035":
      case "COBALT_008_022":
      case "COBALT_008_029":
      case "COBALT_008_030":
      case "EMPLOYEE_005_199":
      case "EMPLOYEE_005_201":
      case "EMPLOYEE_005_205":
      case "COBALT_006_010":
      case "COBALT_006_015":
      case "COBALT_002_78":
      case "EMPLOYEE_005_002":
      case "COBALT_009_019":
      case "COBALT_009_017":
      case "COBALT_009_033":
      case "EMPLOYEE_005_008":
      case "COBALT_003_118":
      case "COBALT_002_093":
      case "COBALT_002_092":
      case "COBALT_002_006":
      case "COBALT_002_063":
      case "EMPLOYEE_COLLECT_014":
      case "COBALT_003_141":
      case "COBALT_003_139":
      case "COBALT_003_145":
      case "COBALT_008_015":
      case "COBALT_008_045":
      case "EMPLOYEE_005_270":
      case "EMPLOYEE_005_272":
      case "EMPLOYEE_005_266":
      case "COBALT_008_046":
      case "COBALT_008_042":
      case "COBALT_008_027":
      case "EMPLOYEE_COLLECT_002":
      case "EMPLOYEE_COLLECT_003":
      case "EMPLOYEE_COLLECT_008":
      case "UNAUTHORIZED_USER":
      case "COBALT_003_157":
      case "COBALT_003_054":
      case "COBALT_003_156":
      case "COBALT_002_017":
      case "EMPLOYEE_COLLECT_032":
      case "EMPLOYEE_COLLECT_034":
      case "EMPLOYEE_COLLECT_036":
      case "COBALT_005_007":
      case "COBALT_002_091":
      case "EMPLOYEE_COLLECT_038":
      case "EMPLOYEE_COLLECT_039":
      case "EMPLOYEE_COLLECT_043":
      case "EMPLOYEE_COLLECT_042":
      case "EMPLOYEE_COLLECT_041":
      case "EMPLOYEE_COLLECT_040":
      case "EMPLOYEE_COLLECT_014":
      case "COBALT_002_105":
        message = `${modulePath}.${status}.${statusCode}`;
        break;

      // in case of same messages to be displayed for status codes i.e. for updated successfully here
      case 'COBALT_002_012':
      case 'COBALT_002_013':
      case 'COBALT_003_014':
      case 'COBALT_002_035':
      case 'COBALT_003_043':
      case 'COBALT_001_005':
      case 'EMPLOYEE_005_072':
      case 'EMPLOYEE_005_049':
      case 'EMPLOYEE_005_045':
      case 'EMPLOYEE_005_088':
      case 'EMPLOYEE_005_086':
      case 'EMPLOYEE_005_071':
      case 'EMPLOYEE_005_238':
      case 'COBALT_009_004':
      case 'EMPLOYEE_005_271':
        message = `${modulePath}.${status}.updated`;
        break;

      case 'COBALT_002_011':
      case 'COBALT_002_018':
      case 'COBALT_003_117':
      case 'COBALT_003_107':
        if (modulePath !== 'common') {
          message = `${modulePath}.${status}.${statusCode}`;
        }
        else {
          message = `${modulePath}.${status}.updated`;
        }
        break;
    }

    if (message && status === 'error') {
      this.toastr.error(this.translate.instant(message));
    }
    else if (message && status === 'success') {
      this.toastr.success((paramsObj && paramsObj + ' ' || '') + this.translate.instant(message));
    }
    else if (message && status === 'warning') {
      this.toastr.warning(this.translate.instant(message));
    }
  }
}
