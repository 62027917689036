import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { EntitiesService } from '../../../../modules/entities/services/entities.service';
import { StatusCodeService } from '../../../services/status-code/status-code.service';
import { UserPermissionsService } from '../../../services/user-permissons/user-permissions.service';
import { ToastrService } from 'ngx-toastr';
import { auditTracking } from '../../../../utils/audit-tracking';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { SharedService } from '../../../services/shared/shared.service';

@Component({
  selector: 'app-services',
  standalone: false,
  templateUrl: './services.component.html',
  styleUrl: './services.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ServicesComponent implements OnInit {
  @Input() selectedEntity: any;
  @Input() switchingTab: string = '';
  @Input() enateEntityGuid: string = '';
  @Input() disableEdit: boolean = false;
  @Input() saveForm: boolean = false;
  @Output() formSaved = new EventEmitter();
  listOfServiceLine: any[] = [];
  hexLoader: boolean = false;
  isLoader: boolean = false;
  addNewContract: boolean = false;
  modifyServices: boolean = false;
  serviceRequest: boolean = false;
  save: boolean = false;
  entityServiceData: boolean = false;
  l2Services: any[] = [];
  contractList: any[] = [];
  serviceList: any[] = [];
  selectedServiceLine: any;
  contractDetails: any = [];
  serviceDetails: any[] = [];
  l2ServiceDetails: any;
  contractServiceDetails: any;
  confirmPopup: string = '';
  saveBody: string = '';
  selectedService: any;
  serviceArray: any = [];
  serviceLineDetails: any;
  serviceRequestDetails: any;
  btnClear: any;
  selectedSerivceRequest: any;
  serviceLineId: string = '';

  contractSettings: IDropdownSettings = {
    singleSelection: true, idField: 'contractId', textField: 'name', maxHeight: 100,
    noDataAvailablePlaceholderText: this.translate.instant('common.nodata'), noFilteredDataAvailablePlaceholderText: this.translate.instant('common.nodata'), closeDropDownOnSelection: true
  };

  serviceRequestContractSettings: IDropdownSettings = {
    singleSelection: true, idField: 'contractGUID', textField: 'name', maxHeight: 100,
    noDataAvailablePlaceholderText: this.translate.instant('common.nodata'), noFilteredDataAvailablePlaceholderText: this.translate.instant('common.nodata'), closeDropDownOnSelection: true
  };

  serviceSettings: IDropdownSettings = {
    singleSelection: false, idField: 'serviceGUID', textField: 'serviceName', itemsShowLimit: 2, allowSearchFilter: true, searchPlaceholderText: this.translate.instant('common.search'), selectAllText: '', unSelectAllText: '', maxHeight: 100, closeDropDownOnSelection: true, enableCheckAll: true,
    noDataAvailablePlaceholderText: this.translate.instant('common.nodata'), noFilteredDataAvailablePlaceholderText: this.translate.instant('common.nodata')
  };

  serviceLineSettings: IDropdownSettings = {
    singleSelection: true, idField: 'serviceLineId', textField: 'serviceLineName', maxHeight: 100,
    noDataAvailablePlaceholderText: this.translate.instant('common.nodata'), noFilteredDataAvailablePlaceholderText: this.translate.instant('common.nodata'), closeDropDownOnSelection: true
  };

  serviceRequestSettings: IDropdownSettings = {
    singleSelection: true, idField: 'serviceGUID', textField: 'serviceName', maxHeight: 100, allowSearchFilter: false, searchPlaceholderText: this.translate.instant('common.search'),
    noDataAvailablePlaceholderText: this.translate.instant('common.nodata'), noFilteredDataAvailablePlaceholderText: this.translate.instant('common.nodata'), closeDropDownOnSelection: true
  };

  constructor(private entitiesService: EntitiesService, private statusCodeService: StatusCodeService, private userPermissionsService: UserPermissionsService,
    private toastr: ToastrService, private translate: TranslateService, private cdr: ChangeDetectorRef, private sharedService: SharedService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['disableEdit']?.firstChange && this.disableEdit) {
      this.listOfServiceLine = JSON.parse(this.saveBody);
      this.selectedServiceLine = this.listOfServiceLine[0];
      this.serviceRequest = false;
      this.addNewContract = false;
      this.resetContractDetails();
      this.getContractList();
      this.resetL2ServiceList();
    }

    if ((!changes['switchingTab']?.firstChange && this.switchingTab) || this.saveForm) {
      this.saveService();
    }
  }

  ngOnInit(): void {
    this.sharedService.tracker();
    this.fetchEntityServices();
    this.updateButtonStatesBasedOnL2Services();
  }

  updateButtonStatesBasedOnL2Services() {
    if (this.noL2Services()) {
      this.addNewContract = true;
      this.modifyServices = false;
    } else {
      this.addNewContract = false;
      this.modifyServices = true;
    }
  }

  getActions(action: string) { return this.userPermissionsService.isActions('Entities', action); }

  fetchEntityServices() {
    this.isLoader = true;
    this.hexLoader = true;
    this.entityServiceData = true;
    this.entitiesService.fetchEntityServices(this.selectedEntity?.entityId)?.subscribe({
      next: (response) => {
        if (response.data) {
          this.listOfServiceLine = [...response.data];
          this.saveBody = JSON.stringify(response.data);
        }
        this.selectedServiceLine = this.listOfServiceLine[0];
        this.getContractList();
        this.getL2ServiceList();
        const desc = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.viewHrServices');
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('auditTrail.auditTypeView'),
          desc);
        this.disableLoader();
      }, error: (error) => {
        this.serviceRequest = true;
        this.modifyServices = false;
        this.addNewContract = true;
        this.disableLoader();
      }
    })
  }

  saveService() {
    this.save = true;
    if (this.serviceRequest) {
      this.saveServiceRequestConfig();
    } else {
      this.saveServiceLineConfig();
    }
    this.removeClearAll();
  }

  saveServiceLineConfig() {
    this.hexLoader = true;
    let serviceLines = {
      serviceLineId: this.selectedServiceLine?.serviceLineId,
      isServiceEnabled: this.selectedServiceLine?.isServiceEnabled,
      contractGuid: this.contractDetails[0]?.contractGUID,
      serviceGuid: this.serviceDetails
    }

    let body: any = {
      entityId: this.selectedEntity?.entityId,
      servicelines: [serviceLines]
    };

    this.entitiesService.saveServiceLines(body).subscribe({
      next: (response) => {
        this.toastr.clear();
        this.hexLoader = false;
        this.modifyServices = true;
        this.addNewContract = false;
        if (response?.statusCode === 'UNAUTHORIZED_USER') {
          this.statusCodeService.displayToastrMessage(response?.statusCode, 'error', 'entities');
        } else {
          this.statusCodeService.displayToastrMessage(response?.statusCode, 'success', 'common');
          this.handleServiceLine();
          if (!this.save) {
            this.formSaved.emit(true);
          }
          this.save = false;
        }
        const desc = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.editHrServices');
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          'Edit',
          desc)
      },
      error: (error) => {
        this.hexLoader = false;
        this.formSaved.emit(false);
      }
    })
  }

  handleServiceLine() {
    this.getContractList();
    this.resetContractDetails();
    this.resetL2ServiceList();
  }

  checkFormChanges(index: number, serviceName?: any) {
    this.serviceLineDetails = [];
    this.resetContractDetails();
    if (index !== -1) {
      this.selectedServiceLine = this.listOfServiceLine[index];
      this.serviceRequest = false;
      this.resetContractDetails();
      this.getContractList();
      this.resetL2ServiceList();
      this.updateButtonStatesBasedOnL2Services();
    } else if (serviceName === 'serviceRequest') {
      this.serviceRequest = true;
      this.addNewContract = true;
      this.selectedServiceLine = {};
      this.contractList = [];
      this.getServiceRequest();
    }
  }

  onDeselectContract() {
    this.serviceList = [];
    this.serviceArray = [];
    this.serviceDetails = [];
  }

  onDeselectServiceLine() {
    this.serviceLineDetails = [];
    this.serviceDetails = [];
    this.contractDetails = [];
    this.contractList = [];
    this.serviceArray = [];
    this.serviceList = [];
  }

  onDeselectService() {
    this.serviceDetails = this.serviceArray.map((service: any) => service.serviceGUID);
    if (this.serviceDetails?.length === 0) { this.removeClearAll() };
  }

  getContractList() {
    let body = {
      "enateEntityGuid": this.enateEntityGuid,
      "serviceLineId": this.selectedServiceLine?.serviceLineId
    }

    this.entitiesService.getContractAndServiceList(body).subscribe({
      next: response => {
        if (response.data) {
          this.contractList = response.data;
          this.contractList = response.data.map((contract: any) => ({
            name: contract?.name,
            contractId: contract?.contractId,
            service: contract?.service,
            contractGUID: contract?.contractGUID
          }));
          this.disableLoader();
        }
      }, error: error => {
        this.hexLoader = false;
      }
    })
  }

  resetContractDetails() {
    this.contractDetails = [];
    this.serviceArray = [];
    this.serviceDetails = [];
    this.serviceList = [];
  }

  fetchServices() {
    this.serviceArray = [];
    this.serviceDetails = [];
    const contractIdentifier = this.serviceRequest ? 'contractGUID' : 'contractId';

    const contract = this.contractList.find(
      (service) => service[contractIdentifier] === this.contractDetails[0][contractIdentifier]
    );

    this.serviceList = contract?.service || [];
    this.contractDetails[0].contractGUID = contract?.contractGUID || '';
  }

  onSelectService() {
    this.serviceDetails = this.serviceArray.map((service: any) => service.serviceGUID);
    if (!this.serviceRequest) { this.addClearAll(); }
  }

  onSelectAllService() {
    this.serviceDetails = this.serviceList.map((service: any) => service.serviceGUID);
    this.addClearAll()
  }

  onDeselectAllService() {
    this.serviceDetails = [];
    this.serviceArray = [];
    this.removeClearAll();
  }

  resetL2ServiceList() {
    this.l2ServiceDetails = [];
    this.contractServiceDetails = [];
    this.getL2ServiceList();
  }

  getL2ServiceList() {
    this.hexLoader = true;
    let body: any = {
      entityId: this.selectedEntity?.entityId,
      serviceLineId: this.selectedServiceLine?.serviceLineId
    }

    this.entitiesService.getL2ServicesList(body).subscribe({
      next: response => {
        this.hexLoader = false;
        this.entityServiceData = false;
        this.l2ServiceDetails = response.data;
        this.contractServiceDetails = this.l2ServiceDetails[0]?.contractServiceList;
      }, error: error => {
        this.hexLoader = false;
      }
    })
  }

  noDataCheck() {
    if (this.entityServiceData) {
      return false;
    } else {
      return !this.hexLoader && !this.noL2Services();
    }
  }

  noL2Services() {
    let l2Services: any = [];
    this.listOfServiceLine?.forEach((it: any) => {
      let index = this.l2ServiceDetails?.findIndex((item: any) => {
        return item?.serviceLineName === it?.serviceLineName
      });

      if (this.l2ServiceDetails) {
        const l2ServiceList = this.l2ServiceDetails[index]?.contractServiceList[index]?.l1ServiceList;
        if (index > -1 && l2ServiceList?.length > 0) {
          l2Services = l2ServiceList;
        }
      }
    })
    return l2Services?.length > 0;
  }

  enableDisableL2Service(event: any, l1Service: any, l2Service: any) {
    this.toastr.clear();
    this.hexLoader = true;

    let body: any = {
      entityId: this.selectedEntity?.entityId,
      serviceGUID: l1Service?.enateServiceGuid,
      ...(event ? { l2ServiceId: [l2Service?.l2ServiceId] } : { enateProcessGuid: [l2Service?.enateProcessGuid] })
    };

    const fetchMethod = event
      ? this.entitiesService.enableL2Service
      : this.entitiesService.deleteL2Service;

    fetchMethod.call(this.entitiesService, body).subscribe({
      next: (res: any) => {
        this.hexLoader = false;
        this.resetL2ServiceList();
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.statusCodeService.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else if (res.statusCode === 'COBALT_002_110') {
          this.toastr.success(this.translate.instant('entities.success.serviceDisable'));
        } else if (res.statusCode === 'COBALT_002_113') {
          this.toastr.success(this.translate.instant('entities.success.serviceEnable'));
        }
      },
      error: () => this.disableLoader()
    });
  }

  disableLoader() {
    if (this.isLoader) this.isLoader = false;
    if (this.hexLoader) this.hexLoader = false;
  }

  deleteAllService() {
    this.toastr.clear();
    this.hexLoader = true;
    let body = {
      entityId: this.selectedEntity?.entityId,
      serviceLineId: this.selectedServiceLine?.serviceLineId
    }
    this.entitiesService.deleteAllService(body).subscribe({
      next: res => {
        this.hexLoader = false;
        this.addNewContract = false;
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.statusCodeService.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else if (res.statusCode === 'COBALT_002_112') {
          this.toastr.warning(this.translate.instant('entities.warning.caseMappedWithL2'));
        } else if (res.statusCode === 'COBALT_002_111') {
          this.toastr.success(this.translate.instant('entities.success.allServiceDelete'));
          this.getContractList();
        }
        this.resetContractDetails();
        this.resetL2ServiceList();
      }, error: error => {
        this.hexLoader = false;
      }
    })
  }

  inlineDelete(serviceItem: any) {
    this.confirmPopup = 'delete';
    this.selectedService = serviceItem;
  }

  deleteL2Service() {
    this.toastr.clear();
    this.hexLoader = true;
    let body = {
      entityId: this.selectedEntity?.entityId,
      serviceGUID: this.selectedService?.enateServiceGuid,
    }
    this.entitiesService.deleteL2Service(body).subscribe({
      next: res => {
        this.hexLoader = false;
        this.addNewContract = false;
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.statusCodeService.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else if (res.statusCode === 'COBALT_002_112') {
          this.toastr.warning(this.translate.instant('entities.warning.caseMappedWithL2'));
        } else if (res.statusCode === 'COBALT_002_110') {
          this.toastr.success(this.translate.instant('entities.success.serviceContractDelete'));
          this.getContractList();
        }
        this.resetContractDetails();
        this.resetL2ServiceList();
      }, error: error => {
        this.hexLoader = false;
      }
    })
  }

  serviceLineSelect() {
    this.resetContractDetails();
    this.getServiceRequestContract();
  }

  getServiceRequestContract() {
    this.entitiesService.getServiceRequestContract(this.enateEntityGuid).subscribe({
      next: response => {
        if (response.data) {
          this.contractList = response.data;
          this.contractList = response.data.map((contract: any) => ({
            name: contract?.contractName,
            service: contract?.service,
            contractGUID: contract?.contractGUID
          }));
          this.disableLoader();
        }
      }, error: error => {
        this.hexLoader = false;
      }
    })
  }

  getServiceRequest() {
    this.hexLoader = true;
    this.entitiesService.getServiceRequest(this.selectedEntity?.entityId).subscribe({
      next: res => {
        this.serviceRequestDetails = res.data;
        this.hexLoader = false;
      }, error: error => {
        this.hexLoader = false;
      }
    })
  }

  noServicesRequest() {
    let serviceRequest: any = [];
    this.listOfServiceLine?.forEach((it: any) => {
      let index = this.serviceRequestDetails?.findIndex((item: any) => {
        return item?.serviceLineName === it?.serviceLineName
      });
      if (this.serviceRequestDetails) {
        const l2ServiceList = this.serviceRequestDetails[index]?.enateTicketContractService;
        if (index > -1 && l2ServiceList?.length > 0) {
          serviceRequest = l2ServiceList;
        }
      }
    })
    return serviceRequest?.length > 0;
  }

  saveServiceRequestConfig() {
    this.hexLoader = true;
    let body = {
      contractGuid: this.contractDetails[0]?.contractGUID,
      serviceGuid: this.serviceDetails[0],
      entityId: this.selectedEntity?.entityId,
      serviceLineId: this.serviceLineDetails[0]?.serviceLineId
    }

    this.entitiesService.saveServiceRequest(body).subscribe({
      next: res => {
        this.hexLoader = false;
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.statusCodeService.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else if (res?.statusCode === 'COBALT_002_112') {
          this.toastr.warning(this.translate.instant('entities.warning.ticketExists'));
        } else {
          this.statusCodeService.displayToastrMessage(res?.statusCode, 'success', 'common');
          this.handleServiceRequest();
          if (!this.save) {
            this.formSaved.emit(true);
          }
          this.save = false;
        }
      }, error: (error) => {
        this.hexLoader = false;
        this.formSaved.emit(false);
      }
    })
  }

  handleServiceRequest() {
    this.serviceLineDetails = [];
    this.serviceRequestDetails = [];
    this.contractList = [];
    this.resetContractDetails();
    this.getServiceRequest();
  }

  inlineDeleteTicket(serviceLineId: string, item: any) {
    this.confirmPopup = 'deleteServiceTicket';
    this.serviceLineId = serviceLineId;
    this.selectedSerivceRequest = item;
  }

  deleteTicketService() {
    this.toastr.clear();
    this.hexLoader = true;
    let body = {
      contractGuid: this.selectedSerivceRequest?.contractGuid,
      serviceGuid: this.selectedSerivceRequest?.serviceGuid,
      entityId: this.selectedEntity?.entityId,
      serviceLineId: this.serviceLineId
    }

    this.entitiesService.deleteTicketService(body).subscribe({
      next: res => {
        this.hexLoader = false;
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.statusCodeService.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else if (res?.statusCode === 'COBALT_002_114') {
          this.toastr.error(this.translate.instant('entities.error.alreadyNotInUse'));
        } else if (res?.statusCode === 'COBALT_002_113') {
          this.toastr.success(this.translate.instant('entities.success.ticketDeleted'));
          this.getServiceRequestContract();
          this.getServiceRequest();
        }
        this.serviceLineDetails = [];
        this.resetContractDetails();
      }, error: error => {
        this.hexLoader = false;
      }
    })
  }

  addClearAll() {
    let existingButton = document.querySelector('.service-select .serviceDropdown .dropdown-list');

    if (this.serviceDetails?.length > 0 && existingButton) {
      if (!existingButton.querySelector('.done-button')) {
        this.btnClear = document.createElement("button");
        this.btnClear.classList.add('done-button');
        this.btnClear.textContent = this.translate.instant('common.clearAll');
        this.btnClear.addEventListener("click", () => {
          this.serviceArray = [];
          this.serviceDetails = [];
          this.removeClearAll();
        });
        existingButton.appendChild(this.btnClear);
      }
    }
  }

  removeClearAll() {
    if (this.btnClear) {
      this.btnClear.remove();
    }
  }

  handleConfirmation(ev: boolean) {
    if (ev) {
      if (this.confirmPopup === 'delete') {
        this.deleteL2Service();
      } else if (this.confirmPopup === 'deleteAll') {
        this.deleteAllService();
      } else if (this.confirmPopup === 'deleteServiceTicket') {
        this.deleteTicketService();
      }
    }
    this.confirmPopup = '';
  }

  updateContract() {
    this.toastr.clear();
    this.hexLoader = true;
    let body = {
      enateGUID: this.enateEntityGuid,
      serviceLineID: this.selectedServiceLine?.serviceLineId
    }

    const fetchMethod = this.serviceRequest
      ? this.entitiesService.updateTicketServiceContract
      : this.entitiesService.updateServiceLinesContract;

    fetchMethod.call(this.entitiesService, this.serviceRequest ? this.enateEntityGuid : body).subscribe({
      next: (res: any) => {
        this.hexLoader = false;
        this.refreshServiceAndTicketList(res);
      },
      error: () => this.disableLoader()
    });
  }

  refreshServiceAndTicketList(res: any) {
    if (res?.statusCode === 'UNAUTHORIZED_USER') {
      this.statusCodeService.displayToastrMessage(res?.statusCode, 'error', 'entities');
    } else if (res?.statusCode === 'COBALT_002_115') {
      this.toastr.success(this.translate.instant('entities.success.contractUpdateSuccess'));
    }

    this.serviceRequest ? this.handleServiceRequest() : this.handleServiceLine();
  }

  getSectionBodyHeight() {
    if (!this.serviceRequest) {
      return this.addNewContract ? 'calc(100% - 130px)' : 'calc(100% - 77px)';
    }

    return this.disableEdit ? 'calc(100% - 40px)' : 'calc(100% - 95px)';
  }

}
