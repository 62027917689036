import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { EntitiesService } from '../../../../modules/entities/services/entities.service';
import { StatusCodeService } from '../../../services/status-code/status-code.service';
import { UserPermissionsService } from '../../../services/user-permissons/user-permissions.service';
import { ToastrService } from 'ngx-toastr';
import { auditTracking } from '../../../../utils/audit-tracking';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-services',
  standalone: false,
  templateUrl: './services.component.html',
  styleUrl: './services.component.scss'
})
export class ServicesComponent implements OnInit {
  @Input() selectedEntity: any;
  @Input() switchingTab: string = '';
  listOfServiceLine: any[] = [];
  noServiceLine: any;
  hexLoader: boolean = false;
  isLoader: boolean = false;
  l2Services: any[] = [];
  @Input() disableEdit: boolean = false;
  @Output() formSaved = new EventEmitter();
  @Output() formValid = new EventEmitter();
  @Input() saveForm: boolean = false;
  saveBody: string = '';

  constructor(private entitiesService: EntitiesService, private statusCodeService: StatusCodeService, private userPermissionsService: UserPermissionsService,
    private toastr: ToastrService, private translate: TranslateService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['disableEdit']?.firstChange && this.disableEdit) {
      this.listOfServiceLine = JSON.parse(this.saveBody);
    }

    if ((!changes['switchingTab']?.firstChange && this.switchingTab) || this.saveForm) {
      this.saveServiceLineConfig();
    }
  }

  ngOnInit(): void {
    this.fetchEntityServices();
    this.fetchL2Services();
  }

  getActions(action: string) { return this.userPermissionsService.isActions('Entities', action); }

  fetchEntityServices() {
    this.isLoader = true;
    this.hexLoader = true;
    this.entitiesService.fetchEntityServices(this.selectedEntity?.entityId)?.subscribe({
      next: (response) => {
        if (response.data) {
          this.listOfServiceLine = [...response.data];
          this.saveBody = JSON.stringify(response.data);
        }
        const desc = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.viewHrServices');
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('auditTrail.auditTypeView'),
          desc)
        this.isLoader = false;
        this.hexLoader = false;
      }, error: (error) => {
        this.hexLoader = false;
        this.isLoader = false;
      }
    })
  }

  fetchL2Services() {
    this.hexLoader = true;
    let body: any = {
      "query": [
        {
          "key": "entityId",
          "value": this.selectedEntity?.entityId
        }
      ]
    }
    this.entitiesService.fetchL2Services(body)?.subscribe({
      next: (response) => {
        this.l2Services = response?.data;
        this.hexLoader = false;
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }

  saveServiceLineConfig() {
    this.hexLoader = true;

    let body: any = {
      entityId: this.selectedEntity?.entityId,
      servicelines: this.listOfServiceLine
    };

    this.entitiesService.saveServiceLineConfig(body).subscribe({
      next: (response) => {
        this.toastr.clear();
        this.hexLoader = false;
        if (response?.statusCode === 'UNAUTHORIZED_USER') {
          this.statusCodeService.displayToastrMessage(response?.statusCode, 'error', 'entities');
        } else {
          this.statusCodeService.displayToastrMessage(response?.statusCode, 'success', 'common');
          this.formSaved.emit(true);
        }
        const desc = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.editHrServices');
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('common.edit'),
          desc)
      },
      error: (error) => {
        this.hexLoader = false;
        this.formSaved.emit(false);
      }
    })
  }

  isServiceLineSelected(serviceName: string) {
    return this.listOfServiceLine.findIndex(it => { return it.serviceLineName === serviceName && it.isServiceEnabled }) > -1;
  }

  noL2Services() {
    let l2services: any = [];
    this.listOfServiceLine?.forEach((it: any) => {
      if (it.isServiceEnabled) {
        let index = this.l2Services?.findIndex(item => { return item.serviceLineName === it.serviceLineName });
        if (index > -1 && this.l2Services[index]['l1ServiceList']?.length > 0) {
          l2services = this.l2Services[index]['l1ServiceList'];
        }
      }
    })
    return l2services?.length > 0;
  }

  checKL2Services(serviceName: any) {
    this.toastr.clear();
    setTimeout(() => {
      let index = this.l2Services?.findIndex(item => { return item.serviceLineName === serviceName.serviceLineName });
      if (!index || (index > -1 && this.l2Services[index]['l1ServiceList']?.length === 0)) {
        this.toastr.warning('Please come back or reload the page in few minutes to view the associated L2 Services');
      }
    }, 10);
  }

  checkFormChanges(serviceName: any) {
    this.checKL2Services(serviceName);

    if (JSON.stringify(this.listOfServiceLine) !== this.saveBody) {
      this.formValid.emit(true);
    } else { this.formValid.emit(false); }
  }
}
