<div class="modal" *ngIf="isShowPopup">
  <div class="modal-dialog">
    <div class="modal-content sm py-3">

      <div class="modal-header mb-2" style="align-items: center;">
        <div class="modal-title" translate>access.users.selectClient</div>
        <app-wild-card-search [searchKeyword]="searchTerm" [width]="'small-wild-card'"
          (emitSearchText)="receivedSearch($event)"></app-wild-card-search>
        <i class="icon-close cp ms-2" (click)="closeUserClients()" *ngIf="url === '/my-workspace' || url === '/workflow' ||
           userDetails?.userType === 'super_admin' || userDetails?.userType === 'tmf_admin'"></i>
      </div>

      <div class="modal-body">
        <ul class="border">
          <li class="info" *ngFor="let client of userClients" (click)="selectedClientId = client?.clientId"
            [ngClass]="{'fnt-md gray-bg cd' : client.clientId === selectedClientId}">
            <span class="textoverflow d-inline-block" [title]="client.clientName">{{client.clientName}}</span>
            <i class="icon-tick" *ngIf="client.clientId === selectedClientId"></i>
          </li>
          <li class="no-data align-center" *ngIf="userClients.length === 0" translate>common.norecord</li>
        </ul>
      </div>

      <div class="modal-footer">
        <button *ngIf="userClients.length > 0" class="btn-primary d-block mx-auto" (click)="loadProfUserCache()"
          translate>entities.proceed</button>
      </div>
    </div>
  </div>
</div>

<app-hex-loader *ngIf="hexLoader"></app-hex-loader>
